import { ChangeDetectorRef, Component, Input, ViewChild, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RecaptchaComponent } from 'ng-recaptcha';

import { ThemeHandlerMixin } from '../../mixins/theme-handler';

@Component({
    selector: 'app-recaptcha',
    templateUrl: './g-recaptcha.component.html',
    styleUrls: ['./g-recaptcha.component.scss'],
})
export class GRecaptchaComponent extends ThemeHandlerMixin() implements OnInit {
    @Input() public parentFormGroup!: FormGroup;

    @ViewChild('captcha') public captcha!: RecaptchaComponent;

    public showCaptcha = true;

    constructor(private readonly cdr: ChangeDetectorRef) {
        super();
    }

    public ngOnInit(): void {
        // ? force re init captch on theme change to make it apply new theme
        this.coreQuery.appTheme$.subscribe(() => {
            this.showCaptcha = false;
            this.cdr.detectChanges();

            this.showCaptcha = true;
            this.cdr.detectChanges();
        });
    }

    public reset(): void {
        this.captcha.reset();
    }
}
