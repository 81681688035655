import { AutoHooks, SubscriptionHandlerMixin } from '@adroit-group/ng-utils';
import {
    Directive,
    ElementRef,
    EventEmitter,
    NgZone,
    OnInit,
    Output
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { WidthObserverService } from 'app/service/width-observer/width-observer.service';

@Directive({
    selector: '[widthObserver]',
})
@AutoHooks()
export class WidthObserverDirective
    extends SubscriptionHandlerMixin()
    implements OnInit
{
    @Output() widthChange = new EventEmitter<number>();

    constructor(
        private readonly elementRef: ElementRef,
        private readonly ngZone: NgZone,
        private readonly widthObserverService: WidthObserverService
    ) {
        super();
    }

    ngOnInit() {
        this.ngZone.runOutsideAngular(() => {
            this.widthObserverService
                .observeWidth$(this.elementRef.nativeElement)
                .pipe(takeUntil(this.onDestroy$))

                .subscribe((width) => {
                    if (this.widthChange.observers.length) {
                        this.ngZone.run(() => {
                            this.widthChange.emit(width);
                        });
                    }
                });
        });
    }
}
