interface MediaQueryBoundary {
    lower: number;
    upper: number;
}

const mqs = {
    xs: {
        lower: 0,
        upper: 599
    },
    sm: {
        lower: 600,
        upper: 959
    },
    md: {
        lower: 960,
        upper: 1279
    },
    lg: {
        lower: 1280,
        upper: 1599
    },
    xl: {
        lower: 1600,
        upper: 9999999999999
    }
};

export const mediaQueries = {
    xs: getObserverFormOrMediaQuery('xs'),
    sm: getObserverFormOrMediaQuery('sm'),
    md: getObserverFormOrMediaQuery('md'),
    lg: getObserverFormOrMediaQuery('lg'),
    xl: getObserverFormOrMediaQuery('xl'),
    upXs: getObserverFormOfUpFromMediaQuery('xs'),
    upSm: getObserverFormOfUpFromMediaQuery('sm'),
    upMd: getObserverFormOfUpFromMediaQuery('md'),
    upLg: getObserverFormOfUpFromMediaQuery('lg'),
    upXl: getObserverFormOfUpFromMediaQuery('xl'),
    downXs: getObserverFormOfDownFromMediaQuery('xs'),
    downSm: getObserverFormOfDownFromMediaQuery('sm'),
    downMd: getObserverFormOfDownFromMediaQuery('md'),
    downLg: getObserverFormOfDownFromMediaQuery('lg'),
    downXl: getObserverFormOfDownFromMediaQuery('xl')
};

function getObserverFormOrMediaQuery(mqAlias: keyof typeof mqs) {
    return [minWidthStringOf(mqs[mqAlias]), maxWidthStringOf(mqs[mqAlias])];
}

function getObserverFormOfUpFromMediaQuery(mqAlias: keyof typeof mqs): string {
    switch (mqAlias) {
        case 'xs':
            return minWidthStringOf(mqs['xs']);
        case 'sm':
            return minWidthStringOf(mqs['sm']);
        case 'md':
            return minWidthStringOf(mqs['md']);
        case 'lg':
            return minWidthStringOf(mqs['lg']);
        default:
            return minWidthStringOf(mqs['xl']);
    }
}

function getObserverFormOfDownFromMediaQuery(mqAlias: keyof typeof mqs): string {
    switch (mqAlias) {
        case 'xs':
            return maxWidthStringOf(mqs['xs']);
        case 'sm':
            return maxWidthStringOf(mqs['sm']);
        case 'md':
            return maxWidthStringOf(mqs['md']);
        case 'lg':
            return maxWidthStringOf(mqs['lg']);
        default:
            return maxWidthStringOf(mqs['xl']);
    }
}

function minWidthStringOf(boundary: MediaQueryBoundary): string {
    return `(min-width: ${boundary.lower}px)`;
}

function maxWidthStringOf(boundary: MediaQueryBoundary): string {
    return `(max-width: ${boundary.upper}px)`;
}
