<div class="container impressum-box">
    <div class="col-lg-6">
        <h2 class="header-text">{{ 'impressum.name' | translate }}:</h2>

        <h3>
            {{ 'impressum.developers-and-contributors' | translate }}:
        </h3>

        <h4>
            {{ 'impressum.company' | translate }}
            <br />
            2000 Szentendre, Kőzúzó {{ 'impressum.street' | translate }} 2/A
            <br />
            {{ 'country.HUN' | translate }}
        </h4>

        <h4>
            <span class="bold"
                >{{ 'impressum.representative' | translate }}:</span
            >
            Drobinoha Ádám
        </h4>

        <h4>
            <span class="bold"
                >{{ 'impressum.contributors' | translate }}:</span
            >

            <br />

            Ádám, Ákos, Dani, Jonatán, Javier, Krisztofer, Laci, Martin, Márk,
            Norbi, Pali, Robi, Tomi, Zsolt, Zsolt, Zsolt és Zsolti.
        </h4>
    </div>
</div>
