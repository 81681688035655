<div
    *ngIf="show"
    @fadeOut
    class="container-splash d-flex flex-column align-items-center justify-content-center"
>
    <div id="appLoading">
        <img src="assets/images/shelter-ukr-logo.svg" />
        <h1 role="alert">
            {{ 'checking-for-updates' | translate }}
        </h1>
    </div>
</div>
