import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    BindQueryParamsFactory,
    BindQueryParamsManager,
} from '@ngneat/bind-query-params';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { CountryService } from '~app/service/country/country.service';
import { SpokenLanguageService } from '~app/service/spoken-language/spoken-language.service';
import { NUMBER_SELECT_OPTIONS } from '~constants';
import { TransferType } from '~enums';
import {
    IBooleanSelect,
    IStringSelect,
    TransferFilterFormValue,
} from '~interfaces';

import { ControlConfig } from '../shelter-filter/shelter-filter.component';

@Component({
    selector: 'app-transfer-filter',
    templateUrl: './transfer-filter.component.html',
    styleUrls: ['./transfer-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferFilterComponent implements OnInit {
    public controlConfigTypeToken!: ControlConfig;

    public transferFilterForm!: FormGroup;

    public bindQueryParamsManager!: BindQueryParamsManager<any>;

    private readonly onDestroy$ = new Subject<void>();
    @Input() public initialFilter = true;

    @Output() public readonly filterFormValueChange =
        new EventEmitter<TransferFilterFormValue>();

    @Output() public readonly close = new EventEmitter<void>();

    @Output() public readonly search = new EventEmitter<Record<string, any>>();

    public readonly places = NUMBER_SELECT_OPTIONS;

    public readonly rooms = NUMBER_SELECT_OPTIONS;

    public booleanSelectValues: IBooleanSelect[] = [
        { value: true, viewValue$: this.translateService.stream('yes') },
        { value: null, viewValue$: this.translateService.stream('no-filter') },
    ];

    public transferTypeOptions = Object.values(TransferType).map((value) => ({
        value,
        viewValue$: this.translateService.stream(`transfer-types.${value}`),
    }));

    public readonly countries$ = new ReplaySubject<IStringSelect[]>();
    public readonly languages$ = new ReplaySubject<IStringSelect[]>();

    public readonly loaded$ = new BehaviorSubject(false);

    public readonly controlConfigsList = [
        {
            label: 'filter.slots',
            type: 'select',
            icon: 'family_restroom',
            formControlName: 'nr_of_seats',
            options: this.places,
        },
        {
            label: 'filter.country',
            type: 'select',
            icon: 'map_border',
            formControlName: 'countries',
            isMultiSelect: true,
            options: this.countries$,
        },
        {
            label: 'filter.spoken-languages',
            type: 'select',
            icon: 'translate',
            formControlName: 'spoken_language',
            isMultiSelect: true,
            options: this.languages$,
        },
        {
            label: 'filter.transfer-type',
            type: 'select',
            icon: 'directions_car',
            formControlName: 'transfer_vehicle_type',
            options: this.transferTypeOptions,
        },
        {
            label: 'filter.is-allowed-animals',
            type: 'radio',
            icon: 'pets',
            formControlName: 'allow_pets',
            options: this.booleanSelectValues,
        },
        {
            label: 'is-accessible',
            type: 'radio',
            icon: 'accessible',
            formControlName: 'is_accessible',
            options: this.booleanSelectValues,
        },
        // ? visible in detailed mode
        {
            label: 'filter.is-allowed-babies',
            type: 'radio',
            icon: 'child_friendly',
            formControlName: 'allow_babies',
            options: this.booleanSelectValues,
        },
        {
            label: 'can-call-at-night',
            type: 'radio',
            icon: 'mode_night',
            formControlName: 'can_call_at_night',
            options: this.booleanSelectValues,
        },
    ];

    public showAllFilters = false;

    private transferFilterFormForParams!: FormGroup;

    constructor(
        private readonly translateService: TranslateService,
        private readonly queryParamBinderFactory: BindQueryParamsFactory,
        private readonly spokenLanguageService: SpokenLanguageService,
        private readonly countryService: CountryService
    ) {}

    public ngOnInit(): void {
        this.transferFilterFormForParams = new FormGroup({
            nr_of_seats: new FormControl(''),
            allow_pets: new FormControl(),
            allow_babies: new FormControl(),
            countries: new FormControl(),
            spoken_language: new FormControl(),
            languages: new FormControl(),
            can_call_at_night: new FormControl(),
            is_accessible: new FormControl(),
            transfer_vehicle_type: new FormControl(''),
        });

        this.transferFilterForm = new FormGroup({
            nr_of_seats: new FormControl(),
            allow_pets: new FormControl(),
            allow_babies: new FormControl(),
            countries: new FormControl(),
            spoken_language: new FormControl(),
            languages: new FormControl(),
            can_call_at_night: new FormControl(),
            is_accessible: new FormControl(),
            transfer_vehicle_type: new FormControl(),
        });

        this.bindQueryParamsManager = this.queryParamBinderFactory
            .create([
                { queryKey: 'allow_pets', type: 'boolean' },
                { queryKey: 'allow_babies', type: 'boolean' },
                { queryKey: 'countries', type: 'array' },
                { queryKey: 'spoken_language', type: 'array' },
                { queryKey: 'nr_of_seats', type: 'number' },
                { queryKey: 'is_accessible', type: 'boolean' },
                { queryKey: 'can_call_at_night', type: 'boolean' },
                { queryKey: 'transfer_vehicle_type', type: 'array' },
            ])
            .connect(this.transferFilterForm);

        combineLatest([this.countries$, this.languages$])
            .pipe(
                take(1),
                tap(() => {
                    this.loaded$.next(true);
                    this.bindQueryParamsManager.syncAllDefs();
                })
            )
            .subscribe();

        this.countryService
            .getCountries$()
            .pipe(
                map((countries) =>
                    countries.map((country) => ({
                        value: country.code,
                        viewValue$: this.translateService.stream(
                            'country.' + country.code
                        ),
                    }))
                ),
                tap((countryOptions) => {
                    this.countries$.next(countryOptions);
                })
            )
            .subscribe();

        this.spokenLanguageService
            .getLanguages$()
            .pipe(
                map((spokenLangs) =>
                    spokenLangs.map((lang) => ({
                        value: lang.code,
                        viewValue$: this.translateService.stream(
                            'language.' + lang.code
                        ),
                    }))
                ),
                tap((langOptions) => {
                    this.languages$.next(langOptions);
                })
            )
            .subscribe();

        this.transferFilterForm.setValue(
            this.transferFilterFormForParams.value
        );

        this.transferFilterForm.valueChanges
            .pipe(
                takeUntil(this.onDestroy$),
                startWith(this.transferFilterForm.value)
            )
            .subscribe((value) => {
                this.filterFormValueChange.emit(value);
            });

        if (this.initialFilter) {
            this.filterFormValueChange.emit(this.transferFilterForm.value);
        }
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    public onFilter(): void {
        this.transferFilterFormForParams.setValue(this.transferFilterForm.value);
        this.search.emit(this.transferFilterFormForParams.value);
    }
}
