import { Injectable } from '@angular/core';
import { GeolocationService } from '@ng-web-apis/geolocation';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { delay, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class GeolocationQueryService {
    private readonly locationSubject =
        new BehaviorSubject<GeolocationPosition | null>(null);

    private querySubscription: Subscription | null = null;

    constructor(private readonly geolocation$: GeolocationService) {}

    public attemptTracking(): void {
        if (this.querySubscription) {
            return;
        }

        this.querySubscription = this.geolocation$.pipe(take(1), delay(0)).subscribe((position) => {
            this.locationSubject.next(position);
            this.querySubscription = null;
        });
    }

    public getLocation$(): Observable<GeolocationPosition | null> {
        return this.locationSubject.asObservable();
    }
}
