<div
    class="map-container"
    [ngClass]="{ dark: (coreQuery.appTheme$ | async) === AppTheme.dark }"
    #mapContainer
>
    <ng-container *ngIf="!mapIsReady">
        <div
            class="w-100 d-flex justify-content-center align-items-center"
            style="flex-wrap: wrap; text-align: center; height: 80vh"
        >
            <mat-spinner></mat-spinner>

            <h1 class="w-100">
                {{ 'loading-map' | translate }}
            </h1>
        </div>
    </ng-container>
</div>

<ng-container *ngIf="mapIsReady && markers?.length">
    <!-- <app-marker
        *ngFor="let markerData of markers"
        [coords]="markerData.coordinate"
        (markerClick)="onMarkerClick(markerData, $event)"
    ></app-marker> -->

    <app-marker
        *ngIf="userCoords"
        [userPin]="true"
        [coords]="userCoords"
    ></app-marker>
</ng-container>

<app-positioner-overlay
    *ngIf="selectedShelterInfo"
    #positionerOverlay
    [connectedContainerElement]="markerTooltipPositionerElement"
    [connectedPositions]="connectedPositions"
    [width]="'300px'"
    [maxWidth]="'90vw'"
    (outsideClick)="closePopup(selectedShelterInfo!.shelterId)"
>
    <div class="card-container position-relative">
        <button
            class="cancel-button position-absolute"
            mat-icon-button
            type="button"
            (click)="closePopup(selectedShelterInfo!.shelterId)"
        >
            <mat-icon>cancel</mat-icon>
        </button>

        <app-shelter-card-loader
            *ngIf="!circles"
            [shelterId]="selectedShelterInfo.shelterId"
            [shelter]="selectedShelterInfo.shelter"
            (cardLoad)="onCardLoad()"
        ></app-shelter-card-loader>

        <app-transfer-card-loader
            *ngIf="circles"
            [transferId]="selectedShelterInfo.shelterId"
            [transfer]="selectedShelterInfo.transfer"
            (cardLoad)="onCardLoad()"
        ></app-transfer-card-loader>

    </div>
</app-positioner-overlay>
