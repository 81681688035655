<app-splash-screen
    *ngRenderInBrowser
    (show)="showSplashScreen = $event"
></app-splash-screen>

<div *ngIf="!showSplashScreen" class="mat-app-background">
    <mat-toolbar style="background-color: var(--root-bg-color-accent); overflow: hidden">
        <button
            mat-icon-button
            (click)="sidenav.toggle()"
        >
            <mat-icon
                *ngIf="!sidenav.opened; else closeMenuIconTpl"
                class="themed-text"
                >menu</mat-icon
            >

            <ng-template #closeMenuIconTpl>
                <mat-icon>close</mat-icon>
            </ng-template>
        </button>

        <app-header></app-header>
    </mat-toolbar>

    <mat-sidenav-container
        fullscreen
        style="background: var(--root-bg-color-main)"
    >
        <mat-sidenav
            #sidenav="matSidenav"
            class="mat-elevation-z8 d-flex p-2 menu-container"
        >
            <div class="d-flex flex-column h-100">
                <div class="flex-grow-1">
                    <ng-container
                        *ngSubscribe="userQuery.isLoggedIn$ as isLoggedIn"
                    >
                        <!-- Nav List Item -->
                        <ng-container *ngFor="let navMenuElem of navMenuElems">
                            <ng-container
                                *ngIf="
                                    navMenuElem.type !== 'separator';
                                    else dividerTpl
                                "
                            >
                                <mat-nav-list
                                    *ngIf="
                                        navMenuElem.showWhen ===
                                            ELoginStateIs.Both ||
                                        (navMenuElem.showWhen ===
                                            ELoginStateIs.LoggedOut &&
                                            !isLoggedIn) ||
                                        (navMenuElem.showWhen ===
                                            ELoginStateIs.LoggedIn &&
                                            isLoggedIn)
                                    "
                                >
                                    <!-- Nav List Item Name -->
                                    <a
                                        mat-list-item
                                        [routerLink]="[navMenuElem.routerLink]"
                                        (click)="handleMenuClick(navMenuElem)"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            matrixParams: 'exact',
                                            queryParams: 'ignored',
                                            paths: 'exact',
                                            fragment: 'ignored'
                                        }"
                                        class="menu-item"
                                    >
                                        <span matLine>{{
                                            navMenuElem.langProperty || ''
                                                | translate
                                        }}</span>

                                        <button
                                            *ngIf="navMenuElem.showSubMenus"
                                            mat-icon-button
                                        >
                                            <mat-icon>{{
                                                navMenuElem.showSubMenus
                                                    ? 'keyboard_arrow_up'
                                                    : 'keyboard_arrow_down'
                                            }}</mat-icon>
                                        </button>
                                    </a>

                                    <!-- Nav List Item Sub Menu -->
                                    <ul
                                        *ngIf="navMenuElem.showSubMenus"
                                        [@fadeIn]
                                    >
                                        <li
                                            *ngFor="
                                                let subMenu of navMenuElem.subMenus
                                            "
                                            [routerLink]="[subMenu.routerLink]"
                                            matRipple
                                        >
                                            {{ subMenu.name }}
                                        </li>
                                    </ul>
                                </mat-nav-list>
                            </ng-container>

                            <ng-template #dividerTpl>
                                <mat-divider style="border-top-color: var(--root-primary)" class="mt-2"></mat-divider>
                            </ng-template>
                        </ng-container>
                        <!-- Nav List Item End -->

                        <!-- Nav List Item -->
                        <mat-nav-list *ngIf="isLoggedIn">
                            <!-- Nav List Item Name -->
                            <a mat-list-item (click)="userService.logout()">
                                <span matLine>{{ 'logout' | translate }}</span>
                            </a>
                        </mat-nav-list>
                        <!-- Nav List Item End -->
                    </ng-container>
                </div>

                <div class="d-flex justify-content-start m-1">
                    <a
                        mat-flat-button
                        style="width: 100%"
                        class="alt-menu-item privacy-policy-button"
                        [routerLink]="'/impressum'"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{
                            matrixParams: 'exact',
                            queryParams: 'ignored',
                            paths: 'exact',
                            fragment: 'ignored'
                        }"
                    >
                        {{ 'impressum.name' | translate }}
                    </a>
                </div>

                <div class="d-flex justify-content-start m-1">
                    <a
                        mat-flat-button
                        class="alt-menu-item privacy-policy-button"
                        href="mailto:shelterukr@adroitgroup.io"
                    >Email: shelterukr@adroitgroup.io
                    </a>
                </div>

                <div class="d-flex justify-content-start m-1">
                    <a
                        mat-flat-button
                        [href]="'/assets/pdfs/CSBM_szorolap_menekult_web.pdf'"
                        class="alt-menu-item privacy-policy-button"
                        download
                    >
                        {{ 'warning-about-abuse' | translate }}
                    </a>
                </div>

                <div class="d-flex justify-content-start m-1">
                    <a
                        mat-flat-button
                        [href]="
                            (languageService.activeLang$ | async) === 'hu'
                                ? '/assets/pdfs/shelterukr-com-adatkezelesi-tajekoztato.pdf'
                                : '/assets/pdfs/shelterukr-com-privay-policy.pdf'
                        "
                        class="alt-menu-item privacy-policy-button"
                        download
                    >
                        {{ 'privacy-policy' | translate }}
                    </a>
                </div>
            </div>
        </mat-sidenav>

        <mat-sidenav-content>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
