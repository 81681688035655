import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Pipe({
    name: 'countryTranslate',
})
export class CountryTranslatePipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {}

    public transform(countryCode: string): Observable<string> {
        return this.translateService.stream('country.' + countryCode);
    }
}
