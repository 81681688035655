import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SidenavComponent } from './core/layout/sidenav/sidenav.component';
import { LoggedInGuard } from './guard/logged-in/logged-in.guard';
import { NotLoggedInGuard } from './guard/logged-in/not-logged-in.guard';
import { ImpressumComponent } from './page/impressum/impressum.component';
import { SheltersPageComponent } from './page/shelters-page/shelters-page.component';

const routes: Routes = [
    {
        path: '',
        component: SidenavComponent,
        children: [
            { path: '', pathMatch: 'full', component: SheltersPageComponent },
            {
                path: 'favourites',
                loadChildren: () =>
                    import(
                        './page/favourites-page/favourites-page.module'
                    ).then((m) => m.FavouritesPageModule),
            },
            {
                path: 'favourite-transfers',
                loadChildren: () =>
                    import(
                        './page/favourite-transfers-page/favourite-transfers-page.module'
                    ).then((m) => m.FavouriteTransfersPageModule),
            },

            {
                path: 'latest-shelters',
                loadChildren: () =>
                    import(
                        './page/latest-shelters-page/latest-shelters-page.module'
                    ).then((m) => m.LatestSheltersPageModule),
            },
            {
                path: 'latest-transfers',
                loadChildren: () =>
                    import(
                        './page/latest-transfers-page/latest-transfers-page.module'
                    ).then((m) => m.LatestTransfersPageModule),
            },
            {
                path: 'shelter',
                loadChildren: () =>
                    import('./page/shelter/shelter.module').then(
                        (m) => m.ShelterPageModule
                    ),
            },
            {
                path: 'transfers',
                loadChildren: () =>
                    import('./page/transfer-list/transfer-list.module').then(
                        (m) => m.TransferListPageModule
                    ),
            },
            {
                path: 'transfer',
                loadChildren: () =>
                    import(
                        './page/transfer-detail/transfer-detail.module'
                    ).then((m) => m.TransferDetailPageModule),
            },
            {
                path: 'my-shelters',
                loadChildren: () =>
                    import(
                        './page/my-shelters-page/my-shelters-page.module'
                    ).then((m) => m.MySheltersPagePageModule),
                canLoad: [LoggedInGuard],
            },
            {
                path: 'my-transfers',
                loadChildren: () =>
                    import('./page/my-transfers/my-transfers.module').then(
                        (m) => m.MyTransferPageModule
                    ),
                canLoad: [LoggedInGuard],
            },
            {
                path: 'my-data',
                loadChildren: () =>
                    import('./page/my-data-page/my-data-page.module').then(
                        (m) => m.MyDataPagePageModule
                    ),
                canLoad: [LoggedInGuard],
            },
            {
                path: 'login',
                loadChildren: () =>
                    import('./page/login-page/login-page.module').then(
                        (m) => m.LoginPageModule
                    ),
                canLoad: [NotLoggedInGuard],
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        './page/forgot-password-page/forgot-password-page.module'
                    ).then((m) => m.ForgotPasswordPageModule),
                canLoad: [NotLoggedInGuard],
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        './page/reset-password-page/reset-password-page.module'
                    ).then((m) => m.ResetPAsswordPageModule),
                canLoad: [NotLoggedInGuard],
            },
            {
                path: 'activate-account',
                loadChildren: () =>
                    import(
                        './page/activate-account/activate-account.module'
                    ).then((m) => m.ActivateAccountPageModule),
                canLoad: [NotLoggedInGuard],
            },
            {
                path: 'register',
                loadChildren: () =>
                    import('./page/register-page/register-page.module').then(
                        (m) => m.RegisterPageModule
                    ),
                canLoad: [NotLoggedInGuard],
            },
            {
                path: 'edit-shelter',
                loadChildren: () =>
                    import('./page/edit-shelter/edit-shelter.module').then(
                        (m) => m.EditShelterPageModule
                    ),
                // canLoad: [LoggedInGuard],
            },
            {
                path: 'edit-transfer',
                loadChildren: () =>
                    import('./page/edit-transfer/edit-transfer.module').then(
                        (m) => m.EditTransferPageModule
                    ),
                // canLoad: [LoggedInGuard],
            },
            {
                path: 'impressum',
                component: ImpressumComponent,
            },
        ],
    },
    {
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
        }),
    ],
    providers: [LoggedInGuard, NotLoggedInGuard],
    exports: [RouterModule],
})
export class AppRoutingModule {}
