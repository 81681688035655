<div>
    <mat-form-field
        class="search-input-form-field background-input"
        appearance="outline"
    >
        <mat-label>{{ 'address' | translate }}</mat-label>
        <input
            type="text"
            matInput
            [formControl]="inputFormControl"
            [matAutocomplete]="auto"
            (blur)="onBlur()"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option
                *ngFor="let option of options$ | async"
                [value]="option"
            >
                {{ option?.address ?? '' }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <mat-icon class="help-icon"
              matTooltip="{{ 'shelter-info.address' | translate }}"
              matTooltipPosition="right"
    >
        help_outline
    </mat-icon>
</div>
