import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { createSelector, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CoreState, CoreStore } from './core.store';

@Injectable({
    providedIn: 'root',
})
export class CoreQuery extends Query<CoreState> {
    private selectState = (state: CoreState) => state;

    public allState$ = this.select();

    private readonly selectAppTheme = createSelector(
        this.selectState,
        (state) => state.theme
    );

    private readonly selectColorSchemePreference = createSelector(
        this.selectState,
        (state) => state.colorPreference
    );

    public readonly appTheme$ = this.ngRxSelect$(this.selectAppTheme);

    public readonly selectColorSchemePreference$ = this.ngRxSelect$(
        this.selectColorSchemePreference
    );

    public get colorSchemePreference() {
        return this.ngRxSelect(this.selectColorSchemePreference);
    }

    public get appTheme() {
        return this.ngRxSelect(this.selectAppTheme);
    }

    constructor(protected override store: CoreStore) {
        super(store);
    }

    private ngRxSelect$<CoreState, K>(
        mapFn: (state: CoreState) => K
    ): Observable<K> {
        return this.allState$.pipe(select(mapFn as any));
    }

    private ngRxSelect<CoreState, K>(mapFn: (state: CoreState) => K): K {
        return mapFn(this.getValue() as any);
    }
}
