import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'find'
})
export class FindPipe implements PipeTransform {
    public transform<T, K extends keyof T>(array?: T[], key?: K, propValue?: T[K], ): T | undefined {
        return (array ?? []).filter(Boolean).find(elem => elem[key as K] === propValue);
    }
}
