import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    AfterViewInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EWeekday } from '~enums';
import { RidesTime } from '~models';
import dayjs from 'dayjs';

@Component({
    selector: 'app-weekday-and-hour-display',
    templateUrl: './weekday-and-hour-display.component.html',
    styleUrls: ['./weekday-and-hour-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeekdayAndHourDisplayComponent implements OnInit {
    @Input() rideAvailability!: RidesTime[];
    @Input() availability!: any;

    public transformedAvailability!: any[];

    public intervalList!: number[];

    public intervalLabels!: any[];

    public intervalSize = 4;

    public currentInterval = Math.floor(dayjs().hour() / this.intervalSize);

    public currentDay = dayjs().day() - 1;

    public readonly weekdaysList = [0, 1, 2, 3, 4, 5, 6];

    public dayLabels = this.weekdaysList.map((dayNum) =>
        this.translate.stream(
            `weekdays.${Object.entries(EWeekday)
                .find(([, value]) => value === dayNum)?.[0]
                .toLowerCase()}`
        )
    );

    constructor(private readonly translate: TranslateService) {}

    public ngOnInit(): void {
        this.intervalList = this.constructIntervalList();
        this.intervalLabels = this.intervalList.map((interValId) => {
            const start = interValId * this.intervalSize;
            const end = (interValId + 1) * this.intervalSize;

            return `${start} - ${end}`;
        });
    }
    private constructIntervalList(): number[] {
        const hrsOfDay = 24;
        const intervalsPerDay = hrsOfDay / this.intervalSize;
        return Array(intervalsPerDay)
            .fill(null)
            .map((_, i) => i);
    }
}
