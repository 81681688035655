import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '~environment';
import { Country } from '~interfaces';

@Injectable({
    providedIn: 'root',
})
export class CountryService {
    private readonly countriesSubject = new ReplaySubject<Country[]>(1);

    constructor(private readonly httpClient: HttpClient) {}

    public getCountries$(): Observable<Country[]> {
        return this.countriesSubject.asObservable();
    }

    public loadCountries(): void {
        this.httpClient
            .get<Country[]>(`${environment.apiBase}/api/countries`)
            .subscribe((countries) => {
                if (
                    typeof countries === 'object' &&
                    !Array.isArray(countries)
                ) {
                    this.countriesSubject.next(Object.values(countries));
                } else {
                    this.countriesSubject.next(countries);
                }
            });
    }
}
