import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router, Scroll } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { delay, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CountryService } from '~app/service/country/country.service';
import { GeolocationQueryService } from '~app/service/geolocation-query/geolocation-query.service';
import { SheltersService } from '~app/service/shelters/shelters.service';
import { Language } from '../../shared/interfaces/models/language';
import {
    Coordinate,
    Country,
    GetListingsRequest,
    GetListingsResponse,
    GetMapListingsRequest,
    Listing,
    MapEntity,
    MarkerData,
    ShelterFilterFormValue,
} from '~interfaces';
import { SpokenLanguageService } from '~app/service/spoken-language/spoken-language.service';
import { expandAnimation, expandRevealAnimation, expandRightAnimation } from '~shared/animations';
import { BreakpointService } from '~app/service/breakpoint/breakpoint.service';

@Component({
    selector: 'app-shelters-page',
    templateUrl: './shelters-page.component.html',
    styleUrls: ['./shelters-page.component.scss'],
    animations: [expandAnimation, expandRevealAnimation, expandRightAnimation]
})
export class SheltersPageComponent implements OnInit, OnDestroy {
    @ViewChild('filterToggleContainer') filterToggleContainer!: ElementRef;
    @ViewChild('locationContainer') locationContainer!: ElementRef;

    public readonly pageSizeOptions = [1, 12, 24, 48, 64, 120];

    public shelters: Listing[] | null = null;
    public mapShelters: MapEntity[] | null = null;

    private currentPaginationSubject = new BehaviorSubject<{
        pageSize: number;
        pageIndex: number;
    }>({
        pageIndex: 0,
        pageSize: this.pageSizeOptions[3],
    });

    public showSearchBar = false;
    public isFiltersOpen = false;
    public isDesktopFiltersOpen = true;

    public currentPagination$ = this.currentPaginationSubject.asObservable();
    public resultListLength = 0;

    public locationEnabled = false;

    public geoLocationOfUser$!: Observable<Coordinate | null>;

    private revealAnimationSubject: Subject<boolean> = new Subject<boolean>();

    private loadingSubject: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    private mapListingsLoadingSubject: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    public loading$: Observable<boolean> = this.loadingSubject.asObservable();

    public revealAnimation$: Observable<boolean> = this.revealAnimationSubject.asObservable();

    public mapListingsLoading$: Observable<boolean> =
        this.mapListingsLoadingSubject.asObservable();

    public isShowFavouriteShelters = false;

    private readonly filtersValueSubject =
        new BehaviorSubject<ShelterFilterFormValue>({});
    private readonly reloadSubject = new BehaviorSubject<null>(null);
    private readonly mapListingsReloadSubject = new BehaviorSubject<null>(null);
    private onDestroy$ = new Subject<void>();

    constructor(
        private readonly sheltersService: SheltersService,
        private readonly geolocationQueryService: GeolocationQueryService,
        private readonly countryService: CountryService,
        private readonly viewPortScroller: ViewportScroller,
        private readonly spokenLanguageService: SpokenLanguageService,
        private readonly router: Router,
        public readonly breakpointService: BreakpointService
    ) {
        // ! This has to be called from here otherwise we'd miss the Router's Scroll even.
        this.observeAndHandleScrollPositionRestoration();
    }

    public ngOnInit(): void {
        const isGeoTrackingEnabled = JSON.parse(
            localStorage.getItem('geo-racking-enabled') ?? 'false'
        );

        if (isGeoTrackingEnabled) {
            this.geolocationQueryService.attemptTracking();
        }

        this.geoLocationOfUser$ = this.geolocationQueryService
            .getLocation$()
            .pipe(
                map((location) => {
                    if (!location) {
                        return null;
                    } else {
                        return {
                            lat: location.coords.latitude,
                            lon: location.coords.longitude,
                        } as Coordinate;
                    }
                })
            );

        this.getFilteredShelters();
        this.getMarkerDataForMap();
    }

    private getFilteredShelters(): void {
        combineLatest([
            this.spokenLanguageService.getLanguages$(),
            this.countryService.getCountries$(),
        ])
            .pipe(
                switchMap(([languages, countries]) => {
                    return combineLatest([
                        this.currentPagination$,
                        this.geolocationQueryService.getLocation$(),
                        this.reloadSubject.asObservable(),
                    ]).pipe(
                        switchMap(([currentPagination, location]) => {
                            const filtersValue =
                                this.filtersValueSubject.getValue();

                            this.loadingSubject.next(true);
                            const conf: GetListingsRequest = {
                                page: currentPagination.pageIndex + 1,
                                page_size: currentPagination.pageSize,
                            };

                            if (
                                location?.coords?.longitude &&
                                location?.coords?.latitude
                            ) {
                                conf.latitude = location.coords.latitude;
                                conf.longitude = location.coords.longitude;
                            }

                            this.fillFiltersAndSearchRequestConfig(
                                conf,
                                filtersValue,
                                countries,
                                languages
                            );

                            // TODO handle sort formValue for requests

                            return this.getShelters(conf);
                        })
                    );
                }),
                takeUntil(this.onDestroy$)
            )
            .subscribe((res) => {
                this.shelters = res.data;
                this.resultListLength = res.total;
                this.loadingSubject.next(false);
            });
    }

    public isShelterViewed(shelter: Listing): boolean {
        const viewedShelterIds: number[] = JSON.parse(
            localStorage.getItem('viewed-shelters') ?? '[]'
        );

        return viewedShelterIds.includes(shelter.id);
    }

    public setAnimationState(inProgress: boolean): void {
        this.revealAnimationSubject.next(inProgress);
    }

    public addToFavourite(shelter: Listing): void {
        shelter.favourite = +!shelter.favourite;

        const favoriteShelters: number[] = JSON.parse(
            localStorage.getItem('favorite-shelters') ?? '[]'
        );

        favoriteShelters.includes(shelter.id)
            ? favoriteShelters.splice(
                  favoriteShelters.findIndex((favId) => favId === shelter.id),
                  1
              )
            : favoriteShelters.push(shelter.id);

        localStorage.setItem(
            'favorite-shelters',
            JSON.stringify(favoriteShelters)
        );
    }

    public onSearch(search: string): void {
        this.filtersValueSubject.next({
            ...this.filtersValueSubject.getValue(),
            search,
        });

        this.reloadShelters();
    }

    public changePage(event: PageEvent): void {
        this.locationContainer.nativeElement.scrollIntoView();
        this.currentPaginationSubject.next({
            ...this.currentPaginationSubject.getValue(),
            pageSize: event.pageSize,
            pageIndex: event.pageIndex,
        });
    }

    private getMarkerDataForMap(): void {
        combineLatest([
            this.spokenLanguageService.getLanguages$(),
            this.countryService.getCountries$(),
        ])
            .pipe(
                switchMap(([languages, countries]) => {
                    return this.mapListingsReloadSubject.asObservable().pipe(
                        switchMap(() => {
                            const filtersValue =
                                this.filtersValueSubject.getValue();

                            this.mapListingsLoadingSubject.next(true);
                            const conf: GetMapListingsRequest = {};

                            this.fillFiltersAndSearchRequestConfig(
                                conf,
                                filtersValue,
                                countries,
                                languages
                            );
                            // TODO handle sort formValue for requests

                            return this.getMarkersForMap(conf);
                        })
                    );
                }),
                takeUntil(this.onDestroy$)
            )
            .subscribe((res) => {
                this.mapShelters = res;
                this.mapListingsLoadingSubject.next(false);
            });
    }

    private getShelters(
        reqConfig?: GetListingsRequest
    ): Observable<GetListingsResponse> {
        return this.sheltersService.getShelters(reqConfig);
    }

    private getMarkersForMap(
        reqConfig?: GetMapListingsRequest
    ): Observable<MapEntity[]> {
        return this.sheltersService.getSheltersForMap(reqConfig);
    }

    private fillFiltersAndSearchRequestConfig(
        conf: GetListingsRequest | GetMapListingsRequest,
        filtersValue: ShelterFilterFormValue,
        countries: Country[],
        languages: Language[]
    ): void {
        if (typeof filtersValue.place === 'number') {
            conf.nr_of_places = filtersValue.place;
        }
        if (typeof filtersValue.allow_babies === 'boolean') {
            conf.allow_babies = filtersValue.allow_babies ? 1 : 0;
        }
        if (typeof filtersValue.can_call_at_night === 'boolean') {
            conf.can_call_at_night = filtersValue.can_call_at_night ? 1 : 0;
        }
        if (typeof filtersValue.is_accessible === 'boolean') {
            conf.is_accessible = filtersValue.is_accessible ? 1 : 0;
        }
        if (typeof filtersValue.allow_pets === 'boolean') {
            conf.allow_pets = filtersValue.allow_pets ? 1 : 0;
        }
        if (typeof filtersValue.transport_available === 'boolean') {
            conf.transport_available = filtersValue.transport_available ? 1 : 0;
        }
        if (Array.isArray(filtersValue.type) && filtersValue.type.length ) {
            filtersValue.type.forEach((type, index) => {
                conf[`types[${index}]`] = type;
            });
        } else if (typeof filtersValue.type === 'string' && !!filtersValue.type.length) {
            conf['types[]'] = filtersValue.type;
        }

        if (
            Array.isArray(filtersValue.countries) &&
            filtersValue.countries.length
        ) {
            filtersValue.countries.forEach((countryCode, index) => {
                const countryId = countries.find(
                    (country) => country.code === countryCode
                )?.id;

                if (typeof countryId === 'number') {
                    conf[`countries[${index}]`] = countryId;
                }
            });
        }

        if (
            Array.isArray(filtersValue.spoken_language) &&
            filtersValue.spoken_language.length
        ) {
            filtersValue.spoken_language.forEach((langCode, index) => {
                const languageId = languages.find(
                    (language) => language.code === langCode
                )?.id;

                if (typeof languageId === 'number') {
                    conf[`spoken_language[${index}]`] = languageId;
                }
            });
        }

        if (typeof filtersValue.available_for === 'string' && !!filtersValue.available_for.length) {
            conf.available_for = filtersValue.available_for;
        }

        if (typeof filtersValue.city === 'string' && !!filtersValue.city.length) {
            conf.city = filtersValue.city;
        }

        if (filtersValue.search) {
            conf.search = filtersValue.search;
        }
    }

    public onLocationEnabledChange(change: MatSlideToggleChange): void {
        localStorage.setItem(
            'geo-racking-enabled',
            JSON.stringify(change.checked)
        );

        if (change.checked) {
            this.geolocationQueryService.attemptTracking();
        }
    }

    public reloadMapListings(): void {
        this.mapShelters = null;
        this.mapListingsReloadSubject.next(null);
    }

    public reloadShelters(): void {
        this.shelters = null;
        this.reloadSubject.next(null);
    }

    public ngOnDestroy(): void {
        this.reloadSubject.complete();
        this.mapListingsReloadSubject.complete();
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.filtersValueSubject.complete();
        this.loadingSubject.complete();
        this.mapListingsLoadingSubject.complete();
    }

    public onFilterFormValueChange(filterValue?: ShelterFilterFormValue): void {
        this.filtersValueSubject.next(filterValue ?? {});
        this.reloadShelters();
        this.reloadMapListings();
    }

    public getMarkersFromMapShelters(
        mapShelters: MapEntity[] | null
    ): MarkerData[] {
        if (!mapShelters?.length) {
            return [];
        }

        return mapShelters
            .map((mapShelter) => {
                if (mapShelter.lat && mapShelter.lon) {
                    return {
                        coordinate: {
                            lat: parseFloat(mapShelter.lat),
                            lon: parseFloat(mapShelter.lon),
                        },
                        listingId: mapShelter.id,
                    } as MarkerData;
                } else {
                    return null as any;
                }
            })
            .filter((marker) => !!marker);
    }

    private observeAndHandleScrollPositionRestoration(): void {
        combineLatest([
            this.router.events.pipe(
                filter((e): e is Scroll => e instanceof Scroll)
            ),
            this.loading$.pipe(
                filter((loading) => !loading && !!this.shelters)
            ),
        ])
            .pipe(
                takeUntil(this.onDestroy$),
                delay(1),
                take(1),
                tap(([e]) => {
                    if (e.position) {
                        // backward navigation
                        this.viewPortScroller.scrollToPosition(e.position);
                    } else if (e.anchor) {
                        // anchor navigation
                        this.viewPortScroller.scrollToAnchor(e.anchor);
                    } else {
                        // forward navigation
                        this.viewPortScroller.scrollToPosition([0, 0]);
                    }
                })
            )
            .subscribe();
    }
}
