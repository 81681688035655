import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { persistState, PersistStateSelectFn } from '@datorama/akita';
import { AppModule } from './app/app.module';
import { UserState } from './app/store/user/user.store';
import { environment } from './environments/environment';

const selectToken: PersistStateSelectFn<UserState> = (state) => ({
    accessToken: state.accessToken,
});
selectToken.storeName = 'user';

const storage = persistState({
    key: '__shelterukr__',
    storage: window.localStorage,
    preStoreUpdate(storeName, cachedState, initialState) {
        if (storeName === 'core') {
            return {
                ...initialState,
                ...cachedState,
                theme: cachedState.theme
            };
        }

        return cachedState;
    },
});

const providers = [{ provide: 'persistStorage', useValue: storage }];

if (environment.production) {
    enableProdMode();
    window.console.log = () => {};
}

platformBrowserDynamic(providers)
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
