<div class="d-flex flex-column w-100">
    <div *ngIf="true" class="d-flex flex-nowrap">
        <div class="d-flex flex-wrap" style="width: 20%; height: 100%">
            <div class="d-flex flex-wrap w-100" style="height: 20px"></div>
            <ul
                class="d-flex flex-column justify-content-between header-list y-header-list w-100"
                style="height: calc(100% - 20px)"
            >
                <li
                    *ngFor="let label of dayLabels"
                    class="d-flex align-items-center header-item"
                    [ngStyle]="{
                        flex: '1 1 ' + 100 / dayLabels.length + '%'
                    }"
                >
                    <span>
                        {{ label | async }}
                    </span>
                </li>
            </ul>
        </div>

        <div class="d-flex flex-wrap" style="width: 80%">
            <ul
                class="w-100 d-flex justify-content-between align-items-center header-list x-header-list"
            >
                <li
                    *ngFor="let label of intervalLabels"
                    class="header-item"
                    [ngStyle]="{
                        flex: '1 1 ' + 100 / intervalLabels.length + '%',
                        'text-align': 'center'
                    }"
                >
                    {{ label }}
                </li>
            </ul>

            <div
                *ngFor="let day of weekdaysList"
                class="w-100 d-flex justify-content-between day-row"
                style="flex: 1 1 auto"
            >
                <ng-container
                    *ngFor="let interval of intervalList">
                    <div class="interval-container py-1"
                    [class.highlight]="interval === currentInterval && day === currentDay">
                    <div
                        *ngLet="availability?.[day]?.[interval] as isAvailable"
                        #tooltip="matTooltip"
                        [matTooltip]="
                        ({ day: day, interval: interval }
                            | dayIntervalName
                            | async) + ' ' + (( isAvailable ? 'available' : 'unavailable') | translate) || ''
                    "
                        [ngClass]="isAvailable ? 'available' : 'unavailable'"
                        (click)="tooltip.toggle(); $event.stopPropagation()"
                        [ngStyle]="{
                        flex: '1 1 ' + 100 / intervalLabels.length + '%'
                    }"
                        class="interval mx-1">
                    </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
