import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private readonly _activeLang$ = new BehaviorSubject<string>("hu");

    public readonly activeLang$ = this._activeLang$.asObservable();

    constructor(private readonly translateService: TranslateService) {}

    public changeLanguage(language: string): void {
        this._activeLang$.next(language);
        document.documentElement.lang = language;
        this.translateService.use(language);

        //  this.dateAdapter.setLocale(language);
        //  this.sessionStorageService.setItem('language', language);
    }
}
