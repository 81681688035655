import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Event, NavigationStart, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreakpointService } from '~app/service/breakpoint/breakpoint.service';
import { LanguageService } from '~app/service/language/language.service';
import { ELoginStateIs } from '~enums';
import { INavMenuElem } from '~interfaces';
import { UserQuery } from '~store/user/user.query';
import { UserService } from '~store/user/user.service';


/**
 * Sidenav Layout Component.
 */
@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({
                    opacity: 0,
                }),
                animate('500ms', style({ opacity: 1 })),
            ]),
        ]),
    ],
})
export class SidenavComponent implements OnInit {
    public ELoginStateIs = ELoginStateIs;

    public resizeObservable$: Observable<Event> | any;
    public resizeSubscription$: Subscription | any;

    @ViewChild('sidenav')
    sidenav!: MatSidenav;

    private onDestroy$ = new Subject<void>();

    public showSplashScreen = true;

    /**
     * Nav menu elems.
     */
    public navMenuElems: INavMenuElem[] = [
        {
            name: 'Find Shelter',
            routerLink: '',
            showWhen: ELoginStateIs.Both,
            langProperty: 'find-shelter',
        },
        {
            name: 'Favourites',
            routerLink: '/favourites',
            showWhen: ELoginStateIs.Both,
            langProperty: 'filter.favourites',
        },
        {
            name: 'Latest Shelters',
            routerLink: '/latest-shelters',
            showWhen: ELoginStateIs.Both,
            langProperty: 'filter.last-shelters',
        },

        {
            type: 'separator',
            showWhen: ELoginStateIs.Both,
        },

        {
            name: 'Find Transfer',
            routerLink: '/transfers',
            showWhen: ELoginStateIs.Both,
            langProperty: 'find-transfer',
        },
        {
            name: 'Favourite Transfers',
            routerLink: '/favourite-transfers',
            showWhen: ELoginStateIs.Both,
            langProperty: 'filter.favourite-transfers',
        },
        {
            name: 'Latest Transfers',
            routerLink: '/latest-transfers',
            showWhen: ELoginStateIs.Both,
            langProperty: 'filter.last-transfers',
        },

        {
            type: 'separator',
            showWhen: ELoginStateIs.Both,
        },

        {
            name: 'Add Shelter',
            routerLink: '/register',
            showWhen: ELoginStateIs.LoggedOut,
            langProperty: 'add-shelter',
        },
        {
            name: 'Login',
            routerLink: '/login',
            showWhen: ELoginStateIs.LoggedOut,
            langProperty: 'login',
        },
        {
            name: 'My Shelters',
            routerLink: '/my-shelters',
            showWhen: ELoginStateIs.LoggedIn,
            langProperty: 'my-shelters',
        },
        {
            name: 'My Transfers',
            routerLink: '/my-transfers',
            showWhen: ELoginStateIs.LoggedIn,
            langProperty: 'my-transfers',
        },
        {
            name: 'My Data',
            routerLink: '/my-data',
            showWhen: ELoginStateIs.LoggedIn,
            langProperty: 'my-data',
        },
    ];

    public initialSidenavOpened!: boolean;

    public isMobile = false;

    constructor(
        public readonly breakpointService: BreakpointService,
        public readonly userQuery: UserQuery,
        public readonly userService: UserService,
        private observer: BreakpointObserver,
        public readonly languageService: LanguageService,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.initialSidenavOpened = this.breakpointService.upMd;
    }

    public ngAfterViewInit(): void {
        window.addEventListener(
            'resize',
            (event) => {
                window.innerWidth < 767
                    ? (this.isMobile = true)
                    : (this.isMobile = false);
            },
            true
        );

        // Currently, this observer is not needed

        // this.observer
        //     .observe(['(max-width: 767px)'])
        //     .pipe(takeUntil(this.onDestroy$))
        //     .subscribe((res) => {
        //         if (!this.sidenav) {
        //             return;
        //         }
        //
        //         if (res.matches) {
        //             this.sidenav.mode = 'over';
        //             this.sidenav.close();
        //
        //             this.router.events.subscribe((event: Event) => {
        //                 if (event instanceof NavigationStart) {
        //                     if (this.isMobile == true) {
        //                         this.sidenav.close();
        //                     }
        //                 }
        //             });
        //         } else {
        //             this.sidenav.mode = 'side';
        //             this.sidenav.open();
        //         }
        //     });
    }

    /**
     * Toggle the sub menus displaying of the currently clicked menu,
     * and close other opened sub menu.
     * @param navMenuElem The currently clicked nav menu elem.
     */
    public handleMenuClick(navMenuElem: INavMenuElem) {
        if (this.isMobile == true) {
            this.sidenav.close();
        }

        if (!navMenuElem.subMenus) {
            return;
        }

        for (const elem of this.navMenuElems) {
            if (elem !== navMenuElem) {
                elem.showSubMenus = false;
            }
        }

        navMenuElem.showSubMenus = !navMenuElem.showSubMenus;
    }
}
