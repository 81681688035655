import { Pipe, PipeTransform } from '@angular/core';
import { ContactType } from '~enums';
import { Contact } from '~interfaces';

@Pipe({
    name: 'contactLink',
})
export class ContactLinkPipe implements PipeTransform {
    private readonly contactTypePrefixMap: Record<ContactType, string> = {
        phone: 'tel:',
        email: 'mailto:',
        whatsapp: 'https://wa.me/',
        telegram: 'tg://to=',
        viber: 'viber://contact?number=%2B',
    };

    public transform(contact: Contact): string {
        return this.contactTypePrefixMap[contact.type] + contact.value;
    }
}
