<mat-card
    class="d-flex flex-column"
>
    <div class="mt-2 mb-2">
        <mat-card-title
            class="mb-0 d-flex align-items-end"
            [routerLink]="['/shelter']"
            [queryParams]="{
            id: shelter.id
        }">
            <span class="name">
                {{ shelter.name }}
            </span>

            <div class="badge-container">
                <div
                    class="shelter-status"
                    [ngClass]="{ 'is-active': !!shelter.is_active }"
                >
                    <span>
                        {{
                        !!shelter.is_active
                            ? ('available' | translate)
                            : ('taken' | translate)
                        }}
                    </span>
                </div>

                <div
                    *ngIf="userQuery.user?.id === shelter.user.id"
                    class="shelter-status own"
                >
                    <span>{{ 'own' | translate }}</span>
                </div>
            </div>
        </mat-card-title>
    </div>

    <mat-card-content
        [routerLink]="['/shelter']"
        [queryParams]="{
            id: shelter.id
        }"
        class="flex-grow-1 d-flex flex-wrap align-items-start align-content-start"
    >
        <div class="w-100 d-flex align-items-center mb-2 me-3">

            <div
                *ngIf="
                !!shelter.country_id && !!shelter.city && !!shelter.street;
                else addressTpl
            "
                class="text-secondary address"
            >
                {{ shelter.country.code | countryTranslate | async }} {{ shelter.city }}
                {{ shelter.street }} {{ shelter.ps_num || '' }}
            </div>
        </div>
        <ng-template #addressTpl>
            <div class="text-secondary address">
                {{ shelter.address }}
            </div>
        </ng-template>
        <div class="w-100 mb-1 d-flex align-items-center">
            <mat-icon class="me-1">date_range</mat-icon>

            <ng-container
                *ngIf="!shelter.available_for; else availablePeriodTpl"
            >
                <span>{{ 'available-through' | translate }}</span>

                <span *ngIf="shelter.from; else alwaysAvail">
                    {{
                    shelter.from?.isValid()
                        ? shelter.from?.format('YYYY-MM-DD')
                        : ''
                    }}
                    -
                    {{
                    shelter.to?.isValid()
                        ? shelter.to?.format('YYYY-MM-DD')
                        : ''
                    }}
                </span>
            </ng-container>

            <ng-template #alwaysAvail>
                <span>{{ 'available-indefinitely' | translate }}</span>
            </ng-template>

            <ng-template #availablePeriodTpl>
                <span class="me-1">{{ 'shelter-availability-period' | translate }}:</span>
                <span>
                    {{
                    'availability-period.' + shelter.available_for
                        | translate
                    }}
                </span>
            </ng-template>
        </div>

        <div class="w-100 d-flex align-items-center mb-2 me-3">
            <mat-icon
                class="me-1"
                [ngClass]="!!shelter.allow_babies ? 'icon-allow' : 'icon-deny'"
            >
                child_friendly
            </mat-icon>

            <span [ngClass]="{ 'not-allowed': !shelter.allow_babies }">
                {{ 'is-allowed-babies' | translate }}
            </span>
        </div>

        <div class="w-100 d-flex align-items-center mb-2 me-3">
            <mat-icon
                class="me-1"
                [ngClass]="!!shelter.allow_pets ? 'icon-allow' : 'icon-deny'"
            >
                pets
            </mat-icon>
            <span [ngClass]="{ 'not-allowed': !shelter.allow_pets }">
                {{ 'is-allowed-animals' | translate }}
            </span>
        </div>

        <div class="w-100 d-flex align-items-center mb-2 me-3">
            <mat-icon
                class="me-1"
                [ngClass]="!!shelter.food_provided ? 'icon-allow' : 'icon-deny'"
            >
                lunch_dining
            </mat-icon>

            <span [ngClass]="{ 'not-allowed': !shelter.food_provided }">
                {{ 'is-food-provided' | translate }}
            </span>
        </div>

        <div class="w-100 d-flex align-items-center mb-2 me-3">
            <mat-icon
                class="me-1"
                [ngClass]="
                    !!shelter.transport_available ? 'icon-allow' : 'icon-deny'
                "
            >
                directions_car
            </mat-icon>

            <span [ngClass]="{ 'not-allowed': !shelter.transport_available }">
                {{ 'is-transport-available' | translate }}
            </span>
        </div>

        <div class="w-100 d-flex align-items-center mb-2 me-3">
            <mat-icon
                class="me-1"
                [ngClass]="
                    !!shelter.can_call_at_night ? 'icon-allow' : 'icon-deny'
                "
            >
                mode_night
            </mat-icon>

            <span [ngClass]="{ 'not-allowed': !shelter.can_call_at_night }">
                {{ 'can-call-at-night' | translate }}
            </span>
        </div>

        <div class="w-100 d-flex align-items-center mb-2 me-3">
            <mat-icon
                class="me-1"
                [ngClass]="!!shelter.is_accessible ? 'icon-allow' : 'icon-deny'"
            >
                accessible
            </mat-icon>

            <span [ngClass]="{ 'not-allowed': !shelter.is_accessible }">
                {{ 'is-accessible' | translate }}
            </span>
        </div>

        <div class="w-100 d-flex align-items-center mb-2 me-3">
            <mat-icon class="me-1">family_restroom</mat-icon>
            <span>
                {{ shelter.nr_of_places }}
                {{ 'person' | translate }}
            </span>
        </div>

        <div class="d-flex align-items-center mb-2 me-3">
            <mat-icon class="me-1">translate</mat-icon>
            <span style="word-break: break-all">
                {{ shelter.user.languages | languageTranslate | async }}
            </span>
        </div>

        <div
            class="w-100 d-flex justify-content-around align-items-center mb-2 me-3"
        >
            <div class="w-100 d-flex align-items-center">
                <mat-icon class="me-1">map_border</mat-icon>
                <span>
                    {{ shelter.country.code | countryTranslate | async }}
                </span>
            </div>

            <div
                class="d-flex align-items-center"
                style="flex: 1 1 auto; white-space: nowrap"
                *ngIf="shelter.distance || shelter.distance === 0"
            >
                <mat-icon class="me-1">near_me</mat-icon>
                <span>{{ shelter.distance }} km</span>
            </div>
        </div>
    </mat-card-content>

    <hr style="margin-bottom: 10px" />

    <mat-card-actions
        *ngIf="!customFooterTpl; else customFooterTpl"
        class="d-flex justify-content-between align-items-center pt-0"
        style="cursor: default"
    >
        <mat-icon
            class="star-icon"
            style="cursor: pointer"
            [color]="(coreQuery.appTheme$ | async) === AppTheme.light ? 'accent' : 'primary'"
            (click)="favouriteClick.emit()"
        >{{ shelter.favourite ? 'star' : 'star_border' }}</mat-icon
        >

        <div class="fw-bold mt-1 d-flex align-items-center">
            <span class="text-uppercase"> {{ 'host' | translate }}: </span>
            <img [src]="shelter.user.first_name | userAvatarImage" class="user-avatar-image mx-2" />
            <span class="text-secondary">{{ shelter.user.first_name }}</span>
        </div>
    </mat-card-actions>
</mat-card>
