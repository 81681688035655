import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DateParserInterceptor implements HttpInterceptor {
    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!req.url.includes('/api/')) {
            return next.handle(req);
        }

        return next.handle(req).pipe(
            map((httpEvent) => {
                if (!(httpEvent instanceof HttpResponse)) {
                    return httpEvent;
                }

                return httpEvent.clone({
                    body: this.parseDateProps(httpEvent.body),
                });
            })
        );
    }

    private parseDateProps(body: any): Record<string, any> {
        if (typeof body === 'object') {
            if (Array.isArray(body)) {
                return body.map((innerStuff) => {
                    return this.parseDateProps(innerStuff);
                });
            } else {
                if (body) {
                    return this.parseDatePropsInObject(body);
                } else {
                    return body;
                }
            }
        } else {
            return body;
        }
    }

    private parseDatePropsInObject(
        object: Record<string, any>
    ): Record<string, any> {
        return Object.entries(object).reduce(
            (newBody, [propKey, propValue]) => {
                const isDateProp =
                    propKey.endsWith('At') ||
                    propKey === 'from' ||
                    propKey === 'to';

                isDateProp
                    ? (newBody[propKey] = dayjs(propValue))
                    : (newBody[propKey] = propValue);

                return newBody;
            },
            {} as Record<string, any>
        );
    }
}
