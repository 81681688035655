import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import { IInitializable } from '~interfaces';

@Injectable({
    providedIn: 'root',
})
export class AppInstallService implements IInitializable{
    private _installEvent!: BeforeInstallPromptEvent;

    private readonly _canInstall$ = new Subject<void>();

    public readonly canInstall$ = this._canInstall$
        .asObservable()
        .pipe(
            delay(5000), // ? add some time delay in case the browser would allow install right after the app is opened.
            tap(() => (this.canInstall = true))
        );

    public readonly installSignal$ =
        new ReplaySubject<BeforeInstallPromptEvent>();

    public canInstall = true; // TODO: = false by default

    // constructor(private readonly bottomSheetService: BottomSheetService) {}

    public init(): void {
        // this.observeAndSignalWhenAppCanBeInstalled();

        this.installSignal$.subscribe((event) => {
            console.log('can install: ', event);
            this._installEvent = event;
        });
    }

    public async install(): Promise<void> {
        this._installEvent.prompt();
        const choice = await this._installEvent.userChoice;
        console.log('install: ', choice.outcome);
    }

    // private observeAndSignalWhenAppCanBeInstalled(): void {
    //     const hasNoOpenBottomSheet$ =
    //         this.bottomSheetService.activeBottomSheet$.pipe(
    //             skip(1),
    //             filter((ref) => !ref)
    //         );

    //     combineLatest([
    //         this.installSignal$.asObservable(),
    //         hasNoOpenBottomSheet$,
    //     ]).subscribe(() => this._canInstall$.next());
    // }
}
