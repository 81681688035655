import { AutoHooks, SubscriptionHandlerMixin } from '@adroit-group/ng-utils';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { filter, map, startWith, take, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { ContactTypeOption, ContactValue } from '~app/page/register-page/register-page.component';
import { PHONE_NUMBER_PREFIX_LIST } from '~constants';
import { ContactType } from '~enums';
import { RegisterFormValue, User } from '~interfaces';
import { SpokenLanguage } from '~shared/enums/spoken-language.enum';

import { GRecaptchaComponent } from '../recaptcha/g-recaptcha.component';


@Component({
    selector: 'app-my-data-form',
    templateUrl: './my-data-form.component.html',
    styleUrls: ['./my-data-form.component.scss'],
    exportAs: 'myDataForm',
})
@AutoHooks()
export class MyDataFormComponent
    extends SubscriptionHandlerMixin()
    implements OnInit, OnChanges
{
    @Input() public isInRegisterMode = false;

    @Input() public user!: User | null;

    @Output() public readonly formSubmitted =
        new EventEmitter<RegisterFormValue>();

    @ViewChild('captcha') public captcha?: GRecaptchaComponent;

    public readonly contactTypes = ContactType;

    public allContactTypeOptions!: ContactTypeOption[];

    public selectedContactTypes: ContactType[] = [];

    public registerFormGroup!: FormGroup;

    public contactsFormArray!: FormArray;

    public isPasswordMode = true;

    public isEmailContactSameAsRegisterEmail = false;

    public formError: any = null;

    public formError$: any = null;

    public filteredPhoneNumberPrefixList$!: Observable<any[]>;

    public emailForm = this.formBuilder.group({
        type: ['email', Validators.required],
        value: ['', [Validators.required, Validators.email]],
        prefix: [''],
    });

    public mobileForm = this.formBuilder.group({
        type: ['phone', Validators.required],
        value: ['', Validators.required],
        prefix: [''],
    });

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.initializeForm();
        this.initializeOptions();
        this.continuouslySetSelectedContactTypes();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.user?.currentValue && this.registerFormGroup) {
            this.syncForm();
        }
    }

    public onFormSubmit(): void {
        if (this.registerFormGroup.invalid) {
            this.registerFormGroup.markAllAsTouched();
            this.captcha?.reset();
            return;
        }

        if (this.registerSubscription) {
            return;
        }

        this.registerError = null;
        this.registerError$ = of();
        this.registerFormGroup.disable();

        this.formSubmitted.emit(this.processRegisterFormValueBeforeSubmit());
    }

    public trackPhoneNumberPrefixes(i: number, prefixData: any): string {
        return prefixData?.isoCode ?? i.toString();
    }

    public notEmptyArrayValidator: ValidatorFn = (control) => {
        if (!Array.isArray(control.value) || !control.value.length) {
            return {
                required: true,
            };
        }

        return null;
    };

    public getSelectedOptionName$ = (
        selectedContactType: ContactType
    ): Observable<string> => {
        return this.allContactTypeOptions.find(
            (option) => option.type === selectedContactType
        )!.name$;
    };

    public removeContactGroup(index: number) {
        this.contactsFormArray.removeAt(index);
    }

    public addContactFormGroup(): void {
        this.contactsFormArray.push(this.getEmptyContactFormGroup());
        this.attemptToRegisterPhoneNumberPrefixTypeAheadStream();
    }

    public resetFormControlWithType(
        formControl: FormControl,
        contactType: ContactType
    ): void {
        switch (contactType) {
            case ContactType.Email:
                formControl.setValidators([
                    Validators.required,
                    Validators.email,
                ]);
                break;
            default:
                formControl.setValidators([Validators.required]);
                break;
        }
        formControl.updateValueAndValidity();
    }

    public getAvailableContactTypeOptions = (
        ownContactType: ContactType,
        selectedContactTypes: ContactType[]
    ): ContactTypeOption[] => {
        const otherUsedContactTypes = selectedContactTypes.filter(
            (selectedContactType) => selectedContactType !== ownContactType
        );

        return this.allContactTypeOptions.filter(
            (contactTypeOption) =>
                !otherUsedContactTypes.includes(contactTypeOption.type)
        );
    };

    public onContactEmailSameAsRegisterEmailChange(isSame: boolean): void {
        this.isEmailContactSameAsRegisterEmail = isSame;

        const emailContactInputValue = this.isEmailContactSameAsRegisterEmail
            ? this.registerFormGroup.controls.email.value
            : '';

        const emailContactValueControl = this.contactsFormArray.controls.find(
            (control) => control.value.type === ContactType.Email
        );

        emailContactValueControl?.patchValue({
            value: emailContactInputValue,
        });

        this.isEmailContactSameAsRegisterEmail
            ? emailContactValueControl?.disable()
            : emailContactValueControl?.enable();
    }

    private initializeOptions(): void {
        this.allContactTypeOptions = [
            {
                name$: this.translateService.stream('phone'),
                type: ContactType.Phone,
            },
            {
                name$: this.translateService.stream('email'),
                type: ContactType.Email,
            },
            {
                name$: this.translateService.stream('viber'),
                type: ContactType.Viber,
            },
            {
                name$: this.translateService.stream('whats-app'),
                type: ContactType.WhatsApp,
            },
            {
                name$: this.translateService.stream('telegram'),
                type: ContactType.Telegram,
            },
        ];

        this.allLanguageOptions = Object.values(SpokenLanguage).map(
            (languageCode: string) => {
                return {
                    name$: this.translateService.stream(
                        `language.${languageCode}`
                    ),
                    languageCode,
                };
            }
        );
    }

    private initializeForm(): void {
        this.registerFormGroup = this.formBuilder.group({
            registeredEmail: [
                { value: this.user?.email ?? '', disabled: true },
            ],
            firstName: [this.user?.first_name ?? '', [Validators.required]],
            lastName: [this.user?.last_name ?? '', [Validators.required]],
            bio: [this.user?.bio ?? ''],
            spokenLanguages: [
                (this.user?.languages ?? []).map((language) => language.code),
                [this.notEmptyArrayValidator],
            ],
            facebookLink: [this.user?.fb_profile ?? ''],
            contacts: this.createContactsFormArray(),
        });

        if (this.isInRegisterMode) {
            this.addRegisterInputsToForm();
        }

        this.contactsFormArray = this.registerFormGroup.get(
            'contacts'
        ) as FormArray;

        this.attemptToRegisterPhoneNumberPrefixTypeAheadStream();
    }

    private createContactsFormArray(): FormArray {
        if (this.isInRegisterMode) {
            return this.formBuilder.array([
                this.formBuilder.group({
                    type: ['email', Validators.required],
                    value: ['', [Validators.required, Validators.email]],
                    prefix: [''],
                }),
                this.formBuilder.group({
                    type: ['phone', Validators.required],
                    value: ['', Validators.required],
                    prefix: [''],
                }),
            ]);
        } else {
            return this.formBuilder.array(
                (this.user?.contacts ?? []).map((contact) => {
                    let prefix = '',
                        value = contact.value;
                    if (contact.type === ContactType.Phone) {
                        const recognizedPhoneNumberPrefix =
                            PHONE_NUMBER_PREFIX_LIST.find((prefixData) =>
                                value.startsWith(prefixData.dialCode)
                            );

                        if (recognizedPhoneNumberPrefix) {
                            prefix = recognizedPhoneNumberPrefix.dialCode;
                            value = value.replace(
                                recognizedPhoneNumberPrefix.dialCode,
                                ''
                            );
                        }
                    } else {
                        value = contact.value;
                    }

                    const formGroup = this.formBuilder.group({
                        type: [contact.type, Validators.required],
                        value: [value],
                        prefix: [prefix],
                    });

                    this.resetFormControlWithType(
                        formGroup.get('value') as FormControl,
                        contact.type
                    );

                    return formGroup;
                })
            );
        }
    }

    private addRegisterInputsToForm(): void {
        this.registerFormGroup.registerControl(
            'email',
            new FormControl(this.user?.email ?? '', [
                Validators.required,
                Validators.email,
            ])
        );

        this.registerFormGroup.registerControl(
            'password',
            new FormControl('', [Validators.required, Validators.minLength(6)])
        );

        this.registerFormGroup.registerControl(
            'reCaptcha',
            new FormControl('', [Validators.required])
        );
    }

    private getEmptyContactFormGroup(): FormGroup {
        return this.formBuilder.group({
            type: ['', Validators.required],
            value: ['', Validators.required],
            prefix: [''],
        });
    }

    private async attemptToRegisterPhoneNumberPrefixTypeAheadStream(): Promise<void> {
        const allContactsHaveTypesSelected$ = (
            this.registerFormGroup.get('contacts') as FormArray
        ).valueChanges
            .pipe(
                startWith(this.registerFormGroup.value.contacts),
                filter((contacts: ContactValue[]) =>
                    contacts.every((contactData) => contactData.type)
                ),
                take(1)
            )
            .toPromise();

        await allContactsHaveTypesSelected$;

        const phoneNumberContactFormGroup = (
            this.registerFormGroup.controls?.contacts as FormArray
        )?.controls?.find(
            (control) => control?.value?.type === ContactType.Phone
        ) as FormGroup | undefined;

        if (!phoneNumberContactFormGroup) {
            return;
        }

        this.filteredPhoneNumberPrefixList$ = phoneNumberContactFormGroup
            .get('prefix')!
            .valueChanges.pipe(
                takeUntil(this.onDestroy$),
                throttleTime(100),
                map((searchStr) => (searchStr + '' ?? '').toLowerCase()),
                map((searchStr) => {
                    if (!searchStr) {
                        return PHONE_NUMBER_PREFIX_LIST;
                    }

                    return PHONE_NUMBER_PREFIX_LIST.filter((prefixData) => {
                        const { isoCode, dialCode, name } = prefixData;

                        return (
                            name.toLowerCase().includes(searchStr) ||
                            dialCode.toLowerCase().includes(searchStr) ||
                            isoCode.toLowerCase().includes(searchStr)
                        );
                    });
                }),
                tap((x) => console.log(x))
            );
    }

    private processRegisterFormValueBeforeSubmit(): RegisterFormValue {
        const formValue = this.registerFormGroup.value as RegisterFormValue;

        return {
            ...formValue,
            contacts: (formValue.contacts as ContactValue[]).map(
                (contact: ContactValue) => ({
                    ...contact,
                    value: (contact?.prefix ?? '') + contact.value,
                })
            ),
        };
    }

    private continuouslySetSelectedContactTypes(): void {
        this.contactsFormArray.valueChanges
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((contacts) => {
                this.selectedContactTypes = contacts.map((contact: any) => {
                    return contact.type;
                });
            });
    }

    private syncForm(): void {
        this.registerFormGroup.patchValue({
            ...this.user,
            spokenLanguages: (this.user?.languages ?? []).map(
                (language) => language.code
            ),
            contacts: this.createContactsFormArray().value,
        });
    }
}
