import { Injectable } from '@angular/core';
import { animationFrameScheduler, Observable } from 'rxjs';
import { auditTime, map } from 'rxjs/operators';
import { ResizeObserverHelper } from 'app/shared/helper/resize-observer-helper/resize-observer-helper';

@Injectable({
    providedIn: 'root',
})
export class WidthObserverService {
    observeWidth$(element: HTMLElement): Observable<number> {
        return ResizeObserverHelper.observe$(element).pipe(
            auditTime(0, animationFrameScheduler),
            map((entries) => {
                const entry = entries[0];
                if (entry.contentBoxSize && entry.borderBoxSize) {
                    const borderBoxSize: ResizeObserverSize = Array.isArray(
                        entry.borderBoxSize
                    )
                        ? entry.borderBoxSize[0]
                        : entry.borderBoxSize;

                    const width = borderBoxSize.inlineSize;

                    return width;
                } else {
                    return 0;
                }
            })
        );
    }
}
