import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { GetListingsRequest, GetListingsResponse, GetMapListingsRequest, OwnListingsResponse } from '~api-models';
import { environment } from '~environment';
import { Country, Listing, MapEntity } from '~models';
import { ListingToSend } from '~shared/interfaces/models/listing-to-send.interface';
import { UserQuery } from '~store/user/user.query';

import { CountryService } from '../country/country.service';


@Injectable({
    providedIn: 'root',
})
export class SheltersService {
    constructor(
        private httpClient: HttpClient,
        private userQuery: UserQuery,
        private readonly countryService: CountryService
    ) {}

    public getShelters(
        reqConfig: GetListingsRequest = {}
    ): Observable<GetListingsResponse> {
        return combineLatest([
            this.countryService.getCountries$(),
            this.httpClient.get<GetListingsResponse>(
                `${environment.apiBase}/api/listings`,
                {
                    params: reqConfig,
                }
            ),
        ]).pipe(
            take(1),
            map(([countries, res]) => ({
                ...res,
                data: res.data.map((shelter) =>
                    this.fixShelter(shelter, countries)
                ),
            }))
        );
    }

    public getSheltersForMap(
        reqConfig: GetMapListingsRequest = {}
    ): Observable<MapEntity[]> {
        return this.httpClient.get<MapEntity[]>(
            `${environment.apiBase}/api/maplisting`,
            {
                params: reqConfig,
            }
        );
    }

    public getSheltersBasedOnIds(
        shelterIds: number[],
        lat?: number,
        lon?: number
    ): Observable<any> {
        if (!Array.isArray(shelterIds) || !shelterIds.length) {
            return of([]);
        }
        let params = new HttpParams();
        if (lat && lon) {
            params = params.set('latitude', lat).set('longitude', lon);
        }
        shelterIds.forEach((shelterId, index) => {
            params = params.set(`listings[${index}]`, shelterId);
        });

        return combineLatest([
            this.countryService.getCountries$(),
            this.httpClient.get<Listing[]>(
                `${environment.apiBase}/api/listings/list`,
                {
                    params: params,
                }
            ),
        ]).pipe(
            take(1),
            map(([countries, res]) =>
                res.map((shelter) => this.fixShelter(shelter, countries))
            )
        );
    }

    public getShelter(
        shelterId: number,
        position?: GeolocationPosition | null
    ): Observable<Listing> {
        let queryParams = new HttpParams();
        if (position?.coords?.latitude && position?.coords?.longitude) {
            queryParams = queryParams
                .set('latitude', position.coords.latitude)
                .set('longitude', position.coords.longitude);
        }
        return combineLatest([
            this.countryService.getCountries$(),
            this.httpClient.get<Listing>(
                `${environment.apiBase}/api/listing/${shelterId}`,
                {
                    params: queryParams,
                }
            ),
        ]).pipe(
            take(1),
            map(([countries, listing]) => this.fixShelter(listing, countries))
        );
    }

    public updateShelter(
        shelterId: number,
        shelter: ListingToSend
    ): Observable<any> {
        return this.httpClient.post(
            `${environment.apiBase}/api/listing/edit/${shelterId}`,
            {
                ...shelter,
                token: this.userQuery.accessToken,
            }
        );
    }

    public createShelter(shelter: ListingToSend): Observable<any> {
        return this.httpClient.post(
            `${environment.apiBase}/api/listing/create`,
            {
                ...shelter,
                token: this.userQuery.accessToken,
            }
        );
    }

    public deleteShelter(shelterId: number): Observable<any> {
        return this.httpClient.delete<number>(
            `${environment.apiBase}/api/listing/delete/${shelterId}`,
            {
                body: {
                    token: this.userQuery.accessToken,
                },
            }
        );
    }

    public getUserShelters(): Observable<Listing[]> {
        const params = new HttpParams().set(
            'token',
            this.userQuery.accessToken!
        );

        return combineLatest([
            this.countryService.getCountries$(),
            this.httpClient.get<OwnListingsResponse>(
                `${environment.apiBase}/api/user/listings`,
                {
                    params,
                }
            ),
        ]).pipe(
            take(1),
            map(([countries, res]) => {
                res.listings.forEach((listing) => {
                    this.fixShelter(listing, countries);
                });

                return res.listings ?? [];
            })
        );
    }

    private fixShelter(shelter: Listing, countries: Country[]): Listing {
        const favoriteShelters: number[] = JSON.parse(
            localStorage.getItem('favorite-shelters') ?? '[]'
        );
        shelter.country = countries.find(
            (country) => country.id === shelter.country_id
        )!;
        shelter.favourite = +favoriteShelters.includes(shelter.id);
        shelter.lat = shelter.lat && parseFloat(shelter.lat.toString());
        shelter.lon = shelter.lon && parseFloat(shelter.lon.toString());
        shelter.from = dayjs(shelter.from).isValid()
            ? dayjs(shelter.from)
            : null;
        shelter.created_at = dayjs(shelter.created_at).isValid()
            ? dayjs(shelter.created_at)
            : null;
        shelter.updated_at = dayjs(shelter.updated_at).isValid()
            ? dayjs(shelter.updated_at)
            : null;

        shelter.to = dayjs(shelter.to).isValid() ? dayjs(shelter.to) : null;

        return shelter;
    }
}
