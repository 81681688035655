import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    Input,
    OnInit,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormBuilder,
    FormGroup,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { EWeekday } from '~enums';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-weekday-and-hour-picker',
    templateUrl: './weekday-and-hour-picker.component.html',
    styleUrls: ['./weekday-and-hour-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => WeekdayAndHourPickerComponent),
            multi: true,
        },
    ],
})
export class WeekdayAndHourPickerComponent
    implements OnInit, ControlValueAccessor
{
    /**
     * Determines how many hours an interval consists of
     */
    @Input() public intervalSize: number = 4;

    private _allChecked!: boolean;

    public get allChecked(): boolean {
        return this._allChecked;
    }

    public set allChecked(isSelected: boolean) {
        const newFormState = this.weekdaysList
            .map((weekdayNum) => {
                const intervalsGroupVal = this.intervalList
                    .map((intervalNum) => ({
                        [intervalNum]: isSelected,
                    }))
                    .reduce(
                        (groupDef, controlDef) => ({
                            ...groupDef,
                            ...controlDef,
                        }),
                        {}
                    );

                return {
                    [weekdayNum]: intervalsGroupVal,
                };
            })
            .reduce((acc, curr) => ({ ...acc, ...curr }), {});

        this.weekdayHourForm.setValue(newFormState);
    }

    public readonly weekdaysList = [0, 1, 2, 3, 4, 5, 6];

    public dayLabels = this.weekdaysList.map((dayNum) =>
        this.translate.stream(
            `weekdays.${Object.entries(EWeekday)
                .find(([, value]) => value === dayNum)?.[0]
                .toLowerCase()}`
        )
    );

    public intervalList!: number[];

    public weekdayHourForm!: FormGroup;

    public intervalLabels!: any[];

    private onChange = (...args: any[]) => {};
    private onTouched = () => {};

    constructor(
        private readonly fb: FormBuilder,
        private readonly translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this.intervalList = this.constructIntervalList();
        this.intervalLabels = this.intervalList.map((interValId) => {
            const start = interValId * this.intervalSize;
            const end = (interValId + 1) * this.intervalSize;

            return `${start} - ${end}`;
        });

        this.buildForm();
    }

    //#region ControlValueAccessor Implementation
    public writeValue(value: any[]): void {
        this.weekdayHourForm.patchValue(this.adaptAvailabilityData(value));
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    //#endregion

    public isAnyUnChecked(): boolean {
        return this.weekdaysList.some((weekdayNum) => {
            return this.intervalList.some((intervalNum) => {
                return !this.weekdayHourForm.get(
                    `${weekdayNum}.${intervalNum}`
                )?.value;
            });
        });
    }

    private buildForm(): void {
        const formGroupDefs = this.weekdaysList
            .map((weekdayNum) => {
                const intervalsGroupDef = this.intervalList
                    .map((intervalNum) => ({
                        [intervalNum]: this.fb.control(false),
                    }))
                    .reduce(
                        (groupDef, controlDef) => ({
                            ...groupDef,
                            ...controlDef,
                        }),
                        {}
                    );

                return {
                    [weekdayNum]: this.fb.group(intervalsGroupDef),
                };
            })
            .reduce((acc, curr) => ({ ...acc, ...curr }), {});

        this.weekdayHourForm = this.fb.group(formGroupDefs);

        this.weekdayHourForm.valueChanges.subscribe((value) => {
            this.onChange(this.adaptAvailabilityDataBackward(value));
        });
    }

    private constructIntervalList(): number[] {
        const hrsOfDay = 24;
        const intervalsPerDay = hrsOfDay / this.intervalSize;

        return Array(intervalsPerDay)
            .fill(null)
            .map((_, i) => i);
    }

    private adaptAvailabilityData(availabilityData: any[]) {
        return (availabilityData ?? []).reduce((acc, curr) => ({
            ...acc,
            [curr.day]: {
                ...acc[curr.day],
                [curr.hour_interval_id]: true,
            },
        }), {});
    }

    private adaptAvailabilityDataBackward(formData: any) {
        return Object.entries(formData)
            .map(([dayNum, dayData]) =>
                Object.entries(dayData as any)
                    .filter(([_, intervalData]) => !!intervalData)
                    .map(([intervalNum]) => ({
                        day: +dayNum,
                        hour_interval_id: +intervalNum,
                    }))
            )
            .reduce((acc, curr) => [...acc, ...curr], []);
    }
}
