import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogData } from '~interfaces';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
    confirmDialogContent: ConfirmDialogData = {
        title: 'Are you sure?',
        cancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmMessage: 'Are you sure you want to do this?',
    };

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) data: ConfirmDialogData
    ) {
        this.confirmDialogContent = { ...this.confirmDialogContent, ...data };
    }
}
