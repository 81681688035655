import { Component, OnInit, Renderer2 } from '@angular/core';
import { CountryService } from './service/country/country.service';
import { DialogService } from './service/dialog/dialog.service';
import { SpokenLanguageService } from './service/spoken-language/spoken-language.service';
import { UserService } from './store/user/user.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { RendererManagerService } from './service/renderer-manager.service';

/**
 * We use Ngx-translate for the translation.
 * Read the documentation, how it works.
 * https://github.com/ngx-translate/core
 */
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    /**
     * Construction
     * @param authService The authentication service.
     * @param dialogService The dialog service.
     */
    constructor(
        protected readonly dialogService: DialogService,
        private readonly userService: UserService,
        private readonly spokenLanguageService: SpokenLanguageService,
        private readonly countryService: CountryService,
        private readonly iconRegistry: MatIconRegistry,
        private readonly sanitizer: DomSanitizer,
        private readonly translate: TranslateService,
        private readonly titleService: Title,
        private readonly renderer: Renderer2,
        private readonly rendererManager: RendererManagerService
    ) {
        this.userService.attemptAutoLogin();
        this.spokenLanguageService.loadLanguages();
        this.countryService.loadCountries();

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.translate.get('page-title').subscribe((res: string) => {
                this.titleService.setTitle('ShelterUKR - ' + res);
            });
        });

        this.iconRegistry.addSvgIcon(
            'app_logo',
            this.sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/images/shelter-ukr-logo.svg'
            )
        );

        this.iconRegistry.addSvgIcon(
            'facebook',
            this.sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/facebook.svg'
            )
        );

        this.iconRegistry.addSvgIcon(
            'viber',
            this.sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/viber.svg'
            )
        );

        this.iconRegistry.addSvgIcon(
            'whatsapp',
            this.sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/whatsapp.svg'
            )
        );
    }

    public ngOnInit(): void {
        this.rendererManager.register(this.renderer);
        // Init subscriptions.
        this.initSubscriptions();
    }

    /**
     * Init all of the core subscriptions.
     */
    protected initSubscriptions(): void {
        this.translate.get('page-title').subscribe((res: string) => {
            this.titleService.setTitle('ShelterUKR - ' + res);
        });
    }
}
