export enum CountryCodeToIso {
    HUN = 'hu',
    POL = 'pl',
    ROU = 'ro',
    DEU = 'de',
    LTU = 'lt',
    FRA = 'fr',
    AUT = 'at',
    BEL = 'be',
    BGR = 'bg',
    HRV = 'hr',
    CYP = 'cy',
    CZE = 'cz',
    DNK = 'dk',
    EST = 'ee',
    GRC = 'gr',
    IRL = 'ie',
    ITA = 'it',
    LVA = 'lv',
    LUX = 'lu',
    MLT = 'mt',
    NLD = 'nl',
    PRT = 'pt',
    SVK = 'sk',
    SVN = 'si',
    ESP = 'es',
    SWE = 'se',

    AFG = 'AF',
	ALB = 'AL',
	DZA = 'DZ',
	ASM = 'AS',
	AND = 'AD',
	AGO = 'AO',
	AIA = 'AI',
	ATA = 'AQ',
	ATG = 'AG',
	ARG = 'AR',
	ARM = 'AM',
	ABW = 'AW',
	AUS = 'AU',
	AZE = 'AZ',
	BHS = 'BS',
	BHR = 'BH',
	BGD = 'BD',
	BRB = 'BB',
	BLR = 'BY',
	BLZ = 'BZ',
	BEN = 'BJ',
	BMU = 'BM',
	BTN = 'BT',
	BOL = 'BO',
	BES = 'BQ',
	BIH = 'BA',
	BWA = 'BW',
	BVT = 'BV',
	BRA = 'BR',
	IOT = 'IO',
	BRN = 'BN',
	BFA = 'BF',
	BDI = 'BI',
	CPV = 'CV',
	KHM = 'KH',
	CMR = 'CM',
	CAN = 'CA',
	CYM = 'KY',
	CAF = 'CF',
	TCD = 'TD',
	CHL = 'CL',
	CHN = 'CN',
	CXR = 'CX',
	CCK = 'CC',
	COL = 'CO',
	COM = 'KM',
	COD = 'CD',
	COG = 'CG',
	COK = 'CK',
	CRI = 'CR',
	CUB = 'CU',
	CUW = 'CW',
	CIV = 'CI',
	DJI = 'DJ',
	DMA = 'DM',
	DOM = 'DO',
	ECU = 'EC',
	EGY = 'EG',
	SLV = 'SV',
	GNQ = 'GQ',
	ERI = 'ER',
	SWZ = 'SZ',
	ETH = 'ET',
	FLK = 'FK',
	FRO = 'FO',
	FJI = 'FJ',
	FIN = 'FI',
	GUF = 'GF',
	PYF = 'PF',
	ATF = 'TF',
	GAB = 'GA',
	GMB = 'GM',
	GEO = 'GE',
	GHA = 'GH',
	GIB = 'GI',
	GRL = 'GL',
	GRD = 'GD',
	GLP = 'GP',
	GUM = 'GU',
	GTM = 'GT',
	GGY = 'GG',
	GIN = 'GN',
	GNB = 'GW',
	GUY = 'GY',
	HTI = 'HT',
	HMD = 'HM',
	VAT = 'VA',
	HND = 'HN',
	HKG = 'HK',
	ISL = 'IS',
	IND = 'IN',
	IDN = 'ID',
	IRN = 'IR',
	IRQ = 'IQ',
	IMN = 'IM',
	ISR = 'IL',
	JAM = 'JM',
	JPN = 'JP',
	JEY = 'JE',
	JOR = 'JO',
	KAZ = 'KZ',
	KEN = 'KE',
	KIR = 'KI',
	PRK = 'KP',
	KOR = 'KR',
	KWT = 'KW',
	KGZ = 'KG',
	LAO = 'LA',
	LBN = 'LB',
	LSO = 'LS',
	LBR = 'LR',
	LBY = 'LY',
	LIE = 'LI',
	MAC = 'MO',
	MDG = 'MG',
	MWI = 'MW',
	MYS = 'MY',
	MDV = 'MV',
	MLI = 'ML',
	MHL = 'MH',
	MTQ = 'MQ',
	MRT = 'MR',
	MUS = 'MU',
	MYT = 'YT',
	MEX = 'MX',
	FSM = 'FM',
	MDA = 'MD',
	MCO = 'MC',
	MNG = 'MN',
	MNE = 'ME',
	MSR = 'MS',
	MAR = 'MA',
	MOZ = 'MZ',
	MMR = 'MM',
	NAM = 'NA',
	NRU = 'NR',
	NPL = 'NP',
	NCL = 'NC',
	NZL = 'NZ',
	NIC = 'NI',
	NER = 'NE',
	NGA = 'NG',
	NIU = 'NU',
	NFK = 'NF',
	MNP = 'MP',
	NOR = 'NO',
	OMN = 'OM',
	PAK = 'PK',
	PLW = 'PW',
	PSE = 'PS',
	PAN = 'PA',
	PNG = 'PG',
	PRY = 'PY',
	PER = 'PE',
	PHL = 'PH',
	PCN = 'PN',
	PRI = 'PR',
	QAT = 'QA',
	MKD = 'MK',
	RUS = 'RU',
	RWA = 'RW',
	REU = 'RE',
	BLM = 'BL',
	SHN = 'SH',
	KNA = 'KN',
	LCA = 'LC',
	MAF = 'MF',
	SPM = 'PM',
	VCT = 'VC',
	WSM = 'WS',
	SMR = 'SM',
	STP = 'ST',
	SAU = 'SA',
	SEN = 'SN',
	SRB = 'RS',
	SYC = 'SC',
	SLE = 'SL',
	SGP = 'SG',
	SXM = 'SX',
	SLB = 'SB',
	SOM = 'SO',
	ZAF = 'ZA',
	SGS = 'GS',
	SSD = 'SS',
	LKA = 'LK',
	SDN = 'SD',
	SUR = 'SR',
	SJM = 'SJ',
	CHE = 'CH',
	SYR = 'SY',
	TWN = 'TW',
	TJK = 'TJ',
	TZA = 'TZ',
	THA = 'TH',
	TLS = 'TL',
	TGO = 'TG',
	TKL = 'TK',
	TON = 'TO',
	TTO = 'TT',
	TUN = 'TN',
	TUR = 'TR',
	TKM = 'TM',
	TCA = 'TC',
	TUV = 'TV',
	UGA = 'UG',
	UKR = 'UA',
	ARE = 'AE',
	GBR = 'GB',
	UMI = 'UM',
	USA = 'US',
	URY = 'UY',
	UZB = 'UZ',
	VUT = 'VU',
	VEN = 'VE',
	VNM = 'VN',
	VGB = 'VG',
	VIR = 'VI',
	WLF = 'WF',
	ESH = 'EH',
	YEM = 'YE',
	ZMB = 'ZM',
	ZWE = 'ZW',
	ALA = 'AX'
}
