export const AVAILABLE_LANGS = [
    {
        code: 'hu',
        name: 'Hungarian',
        imageUrl: 'assets/icons/languages/hu.svg',
    },
    {
        code: 'en',
        name: 'English',
        imageUrl: 'assets/icons/languages/en.svg',
    },
    {
        code: 'ua',
        name: 'Ukrainian',
        imageUrl: 'assets/icons/languages/ua.svg',
    },
    {
        code: 'de',
        name: 'German',
        imageUrl: 'assets/icons/languages/de.svg',
    },
    {
        code: 'pl',
        name: 'Polish',
        imageUrl: 'assets/icons/languages/pl.svg',
    },
    {
        code: 'ro',
        name: 'Romanian',
        imageUrl: 'assets/icons/languages/ro.svg',
    },
    {
        code: 'hr',
        name: 'Croatia',
        imageUrl: 'assets/icons/languages/hr.svg',
    },
    {
        code: 'fr',
        name: 'French',
        imageUrl: 'assets/icons/languages/fr.svg',
    },
    {
        code: 'it',
        name: 'Italy',
        imageUrl: 'assets/icons/languages/it.svg',
    },
    {
        code: 'es',
        name: 'Spanish',
        imageUrl: 'assets/icons/languages/es.svg',
    },
    {
        code: 'rs',
        name: 'Serbia',
        imageUrl: 'assets/icons/languages/rs.svg',
    },
    {
        code: 'sk',
        name: 'Slovak',
        imageUrl: 'assets/icons/languages/sk.svg',
    },
    /*

    {
        code: 'ru',
        name: 'Russian',
        imageUrl: 'assets/icons/languages/ru.svg',
    },
    {
        code: 'sv',
        name: 'Swedish',
        imageUrl: 'assets/icons/languages/sv.svg',
    },
    {
        code: 'lt',
        name: 'Lithuanian',
        imageUrl: 'assets/icons/languages/lt.svg',
    },
    {
        code: 'sk',
        name: 'Slovak',
        imageUrl: 'assets/icons/languages/sk.svg',
    }, */
];
