<div [formGroup]="parentFormGroup">
    <re-captcha
        *ngIf="showCaptcha"
        #captcha
        formControlName="reCaptcha"
        siteKey="6LfKl68eAAAAAIomq8bIc1Xi-i3mpIkcDoVgtMf1"
        [theme]="$any(coreQuery.appTheme)"
    ></re-captcha>
    <div class="ms-2">
        <mat-error
            *ngIf="
                parentFormGroup.controls.reCaptcha.touched &&
                parentFormGroup.controls.reCaptcha.invalid
            "
            >{{ 'recaptcha-error' | translate }}</mat-error
        >
    </div>
</div>
