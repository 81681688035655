import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges
} from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SheltersService } from '~app/service/shelters/shelters.service';
import { Listing } from '~models';


@Component({
    selector: 'app-shelter-card-loader',
    templateUrl: './shelter-card-loader.component.html',
    styleUrls: ['./shelter-card-loader.component.scss'],
})
export class ShelterCardLoaderComponent implements OnChanges, OnDestroy {
    @Input() shelterId!: number;
    @Input() shelter?: Listing;
    @Output() cardLoad = new EventEmitter<void>();

    public loadedShelter: Listing | null = null;
    public loadError: any | null = null;
    public loadInProgress: boolean = false;

    private loadShelterSubscription?: Subscription;

    constructor(
        private readonly sheltersService: SheltersService,
        private readonly cdr: ChangeDetectorRef
    ) {}

    attemptToLoadShelter(): void {
        this.loadedShelter = null;
        this.loadError = null;
        this.loadInProgress = true;
        this.loadShelterSubscription?.unsubscribe();

        if (this.shelter) {
            this.loadedShelter = this.shelter;
            return;
        }

        this.loadShelterSubscription = this.sheltersService
            .getShelter(this.shelterId)
            .pipe(
                finalize(() => {
                    this.loadInProgress = false;
                })
            )
            .subscribe({
                next: (result) => {
                    this.loadedShelter = result;
                    this.cdr.detectChanges();
                    this.cardLoad.emit();
                },
                error: (error) => {
                    this.loadError = error;
                },
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.shelterId || changes.shelter) {
            this.attemptToLoadShelter();
        }
    }

    ngOnDestroy(): void {
        this.loadShelterSubscription?.unsubscribe();
    }

    public addToFavourite(shelter: Listing): void {
        shelter.favourite = +!shelter.favourite;

        const favoriteShelters: number[] = JSON.parse(
            localStorage.getItem('favorite-shelters') ?? '[]'
        );

        favoriteShelters.includes(shelter.id)
            ? favoriteShelters.splice(
                favoriteShelters.findIndex((favId) => favId === shelter.id),
                1
            )
            : favoriteShelters.push(shelter.id);

        localStorage.setItem(
            'favorite-shelters',
            JSON.stringify(favoriteShelters)
        );
    }
}
