export enum ShelterAvailableFor {
    DAY = 'day',
    TWOTHREEDAYS = 'twoThreeDays',
    WEEK = 'week',
    TWOWEEKS = 'twoWeeks',
    THREEWEEKS = 'threeWeeks',
    FOURWEEKS = 'fourWeeks',
    SIXWEEKS = 'sixWeeks',
    EIGHTWEEKS = 'eightWeeks',
    THREEMONTHS = 'threeMonths',
    FOURSIXMONTHS = 'fourSixMonths',
    YEAR = 'year',
    INDEFINITELY = 'indefinitely'
}
