<div class="w-100 d-flex align-items-center justify-content-start">
    <h1 class="d-flex align-items-center">
        <span class="brand-data" routerLink="">
<!--            <mat-icon svgIcon="app_logo" class="app-logo">-->
<!--            </mat-icon>-->
            <img src="assets/icons/icon-96x96.png" class="app-logo" alt="logo">
            <span class="themed-text app-name">
                Shelter UKR
            </span>
        </span>
    </h1>

    <div
        *ngIf="appInstallService.canInstall$ | async"
        class="install-button container"
    >
        <button mat-icon-button (click)="appInstallService.install()" class="themed-text">
            <mat-icon [matTooltip]="'install-app' | translate">
                install_mobile
            </mat-icon>
        </button>
    </div>

    <div>
        <button
            *ngSubscribe="coreQuery.appTheme$ as appTheme"
            type="button"
            mat-icon-button
            (click)="changeTheme(appTheme)"
        >
            <mat-icon class="themed-text">
                {{ appTheme === AppTheme.dark ? 'dark_mode' : 'light_mode' }}
            </mat-icon>
        </button>
    </div>

    <div class="header-right">
        <app-lang-selector
            (selectLang)="languageService.changeLanguage($event)"
        >
        </app-lang-selector>
    </div>
</div>
