export enum SpokenLanguage {
    Hungarian = 'hu',
    French = 'fr',
    Ukrainian = 'uk',
    Romanian = 'ro',
    English = 'en',
    German = 'de',
    Russian = 'ru',
    Polish = 'pl',
    Swedish = 'sv',
    Lithuanian = 'lt',
    Slovak = 'sk',
    Croatian = 'hr',
    Italian = 'it',
    Spanish = 'es',
}
