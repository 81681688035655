import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { User } from '~interfaces';
import { UserQuery } from '~store/user/user.query';


@Injectable({
    providedIn: 'root',
})
export class NotLoggedInGuard
    implements CanActivate, CanActivateChild, CanLoad
{
    constructor(
        private readonly router: Router,
        private readonly userQuery: UserQuery
    ) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this.getLoggedInUser$().pipe(
            map((loggedInUser) => {
                return this.getResult(loggedInUser);
            })
        );
    }

    public canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this.getLoggedInUser$().pipe(
            map((loggedInUser) => {
                return this.getResult(loggedInUser);
            })
        );
    }

    public canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean | UrlTree> {
        return this.getLoggedInUser$().pipe(
            map((loggedInUser) => {
                return this.getResult(loggedInUser);
            })
        );
    }

    private getResult(loggedInUser: User | null): boolean | UrlTree {
        if (!loggedInUser) {
            return true;
        } else {
            const urlParts: string[] = [];

            urlParts.push('my-shelters');

            return this.router.createUrlTree(urlParts);
        }
    }

    private getLoggedInUser$(): Observable<User | null> {
        return this.userQuery.initialUserHasBeenDetermined$.pipe(
            filter((userHasBeenDetermined) => userHasBeenDetermined),
            map(() => {
                return this.userQuery.user;
            })
        );
    }
}
