import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { EAppTheme } from '~enums';
import { Listing } from '~interfaces';
import { CoreQuery } from '~store/core/core.query';
import { UserQuery } from '~store/user/user.query';

@Component({
    selector: 'app-shelter-card',
    templateUrl: './shelter-card.component.html',
    styleUrls: ['./shelter-card.component.scss'],
})
export class ShelterCardComponent {
    @Input() public shelter!: Listing;

    @Output() public readonly favouriteClick = new EventEmitter<void>();

    @ContentChild('footer') public customFooterTpl!: TemplateRef<any>;

    public readonly AppTheme = EAppTheme;

    constructor(
        public readonly userQuery: UserQuery,
        public readonly coreQuery: CoreQuery
    ) {}
}
