export enum AvailabilityPeriod {
    day = 'day',
    twoThreeDays = 'twoThreeDays',
    week = 'week',
    twoWeeks = 'twoWeeks',
    threeWeeks = 'threeWeeks',
    fourWeeks = 'fourWeeks',
    sixWeeks = 'sixWeeks',
    eightWeeks = 'eightWeeks',
    threeMonths = 'threeMonths',
    fourSixMonths = 'fourSixMonths',
    year = 'year',
    indefinitely = 'indefinitely',
}
