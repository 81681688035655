import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Language } from '~interfaces';

@Pipe({
    name: 'languageTranslate',
})
export class LanguageTranslatePipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {}

    public transform(languages: Language[]): Observable<string> {
        return combineLatest(
            languages.map((language) => {
                return this.translateService.stream(
                    'language.' + language.code
                );
            })
        ).pipe(
            map((translations: string[]) => {
                return translations.join('/');
            })
        );
    }
}
