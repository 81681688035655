import { AdroitNgUtilsModule } from '@adroit-group/ng-utils';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ImpressumComponent } from './page/impressum/impressum.component';
import { SheltersPageComponent } from './page/shelters-page/shelters-page.component';
import { AppInstallService } from './service/app-install.service';
import { APP_INITIALIZERS } from './service/initializers';
import { CORE_INTERCEPTORS } from './service/interceptors';
import { SharedModule } from './shared/shared.module';
import { INITIALIZABLE } from './shared/token/initializable';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json'); // TODO increment this number when there is a new release
}

@NgModule({
    declarations: [AppComponent, SheltersPageComponent, ImpressumComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AdroitNgUtilsModule.forRoot(),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            useDefaultLang: true,
            defaultLanguage: 'hu',
        }),
        CoreModule,
        SharedModule,
        environment.production ? [] : AkitaNgDevtools.forRoot(),
        AkitaNgRouterStoreModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: true,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerImmediately',
        }),
    ],
    providers: [
        ...CORE_INTERCEPTORS,
        ...APP_INITIALIZERS,
        {
            provide: INITIALIZABLE,
            useClass: AppInstallService,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
