import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SpokenLanguageService } from 'app/service/spoken-language/spoken-language.service';
import {
    LoginResponse,
    LoginRequestBody,
    RegisterFormValue,
    GetUserResponse,
    EditFormValue,
    User,
} from '~interfaces';
import { environment } from 'environments/environment';
import { UserQuery } from './user.query';
import { UserStore } from './user.store';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(
        private readonly userStore: UserStore,
        private readonly userQuery: UserQuery,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly httpClient: HttpClient,
        private readonly spokenLanguageService: SpokenLanguageService
    ) {}

    public login(body: LoginRequestBody): Observable<{
        token: string;
        user: User;
    }> {
        const formData = new FormData();
        formData.append('email', body.email);
        formData.append('password', body.password);

        return this.httpClient
            .post<LoginResponse>(`${environment.apiBase}/api/login`, formData)
            .pipe(
                switchMap((resp) =>
                    this.getUser(resp.token).pipe(
                        map((userResponse) => ({
                            token: resp.token,
                            user: userResponse.user,
                        }))
                    )
                ),
                tap((resp) => {
                    this.userStore.update((state) => ({
                        ...state,
                        accessToken: resp.token,
                        user: resp.user,
                    }));
                })
            );
    }

    public edit(editFormValue: EditFormValue): Observable<any> {
        return this.spokenLanguageService.getLanguages$().pipe(
            switchMap((languages) => {
                const token = this.userQuery.accessToken!;
                const body: any = {
                    first_name: editFormValue.firstName,
                    last_name: editFormValue.lastName,
                };

                editFormValue.contacts.forEach((contact, index) => {
                    const contactKey: string = contact.type.toLowerCase();
                    body.contacts = body.contacts || {};
                    body.contacts[contactKey] = contact.value;
                    //body[`contacts[${contactKey}]`] = contact.value;
                });
                if (editFormValue.bio) {
                    body.bio = editFormValue.bio;
                }
                if (editFormValue.facebookLink) {
                    body.fb_profile = editFormValue.facebookLink;
                }
                editFormValue.spokenLanguages.forEach((languageCode, index) => {
                    const languageId = languages.find((languge) => {
                        return languge.code === languageCode;
                    })!.id!;
                    //body[`spoken_languages[${index}]`] = languageId.toString()
                    body.spoken_languages = body.spoken_languages || [];
                    body.spoken_languages.push(languageId.toString());
                });

                return this.httpClient
                    .put<any>(`${environment.apiBase}/api/user/edit`, body, {
                        headers: new HttpHeaders().set(
                            'Authorization',
                            `Bearer ${token}`
                        ),
                    })
                    .pipe(
                        tap((res) => {
                            if (res.success) {
                                this.userStore.update({
                                    user: res.data,
                                });
                            }
                        })
                    );
            })
        );
    }

    public register(registerFormValue: RegisterFormValue): Observable<any> {
        return this.spokenLanguageService.getLanguages$().pipe(
            switchMap((languages) => {
                const formData = new FormData();
                formData.append('first_name', registerFormValue.firstName);
                formData.append('last_name', registerFormValue.lastName);
                formData.append('email', registerFormValue.email);
                formData.append('password', registerFormValue.password);
                formData.append(
                    'password_confirmation',
                    registerFormValue.password
                );
                registerFormValue.contacts.forEach((contact, index) => {
                    const contactKey: string = contact.type.toLowerCase();
                    formData.append(`contacts[${contactKey}]`, contact.value);
                });
                if (registerFormValue.bio) {
                    formData.append('bio', registerFormValue.bio);
                }
                if (registerFormValue.facebookLink) {
                    formData.append(
                        'fb_profile',
                        registerFormValue.facebookLink
                    );
                }
                registerFormValue.spokenLanguages.forEach(
                    (languageCode, index) => {
                        const languageId = languages.find((language) => {
                            return language.code === languageCode;
                        })!.id!;
                        formData.append(
                            `spoken_languages[${index}]`,
                            languageId.toString()
                        );
                    }
                );

                return this.httpClient.post<LoginResponse>(
                    `${environment.apiBase}/api/register`,
                    formData
                );
            })
        );
    }

    public getUser(accessToken?: string): Observable<GetUserResponse> {
        let params = new HttpParams();

        if (accessToken) {
            params = params.set('token', accessToken);
        }
        return this.httpClient.get<GetUserResponse>(
            `${environment.apiBase}/api/get_user`,
            {
                params: params,
            }
        );
    }

    public logout(): void {
        const accessToken = this.userQuery.accessToken;

        if (accessToken) {
            this.httpClient
                .get<any>(`${environment.apiBase}/api/logout`, {
                    params: {
                        token: accessToken,
                    },
                })
                .subscribe(() => {});
        }

        this.userStore.update((state) => {
            return {
                ...state,
                accessToken: null,
                user: null,
            };
        });
        this.router.navigate(['/']);
    }

    public clearSession(): void {
        this.userStore.update((state) => {
            return {
                ...state,
                accessToken: null,
                user: null,
            };
        });
        this.router.navigate(['/']);
    }

    public forgotPassword(email: string): Observable<any> {
        const formData = new FormData();
        formData.append('email', email);

        return this.httpClient.post<any>(
            `${environment.apiBase}/api/forgot-password`,
            {
                email: email,
            }
        );
    }

    public resetPassword(password: string): Observable<any> {
        const token = this.route.snapshot.queryParamMap.get('token')!;
        const email = this.route.snapshot.queryParamMap.get('email')!;

        const formData = new FormData();
        formData.append('email', email ? email : '');
        formData.append('token', token ? token : '');
        formData.append('password', password);
        formData.append('password_confirmation', password);

        return this.httpClient
            .post<LoginResponse>(
                `${environment.apiBase}/api/reset-password`,
                formData
            )
            .pipe(
                switchMap((resp) => {
                    return this.getUser(resp.token).pipe(
                        map((userResponse) => {
                            return {
                                token: resp.token,
                                user: userResponse.user,
                            };
                        })
                    );
                }),
                tap((resp) => {
                    this.userStore.update((state) => {
                        return {
                            ...state,
                            accessToken: resp.token,
                            user: resp.user,
                        };
                    });
                })
            );
    }

    public activateAccount(): Observable<any> {
        const token = this.route.snapshot.queryParamMap.get('token')!;

        const formData = new FormData();
        formData.append('token', token ? token : '');

        return this.httpClient.post<LoginResponse>(
            `${environment.apiBase}/api/verify`,
            formData
        );
    }

    public attemptAutoLogin(): void {
        const token = this.userQuery.accessToken;

        if (!token) {
            this.userStore.update((state) => {
                return {
                    ...state,
                    initialUserHasBeenDetermined: true,
                };
            });
        } else {
            this.getUser(token)
                .pipe(
                    switchMap((getUserResponse) => {
                        if (!getUserResponse?.user) {
                            return throwError(getUserResponse?.status);
                        } else {
                            return of(getUserResponse);
                        }
                    })
                )
                .subscribe(
                    (getUserResponse) => {
                        if (getUserResponse.user) {
                            this.userStore.update((state) => {
                                return {
                                    ...state,
                                    initialUserHasBeenDetermined: true,
                                    user: getUserResponse.user,
                                };
                            });
                        }
                    },
                    () => {
                        this.userStore.update((state) => {
                            return {
                                ...state,
                                initialUserHasBeenDetermined: true,
                            };
                        });
                    }
                );
        }
    }
}
