import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiStatus } from '../../shared/enums/api-status';
import { DialogService } from '../dialog/dialog.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    tokenRefreshInProgress = false;
    tokenRefreshing: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private readonly dialogService: DialogService) {}

    public intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!request.url.includes('/api/')) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            catchError((err) => {
                if (err.status === 403) {
                    //TODO: We should do something
                    return throwError(err);
                }
                if (err.status !== 401) {
                    return throwError(err);
                } else {
                    if (err.error.status === ApiStatus.tokenExpired) {
                        this.dialogService.openLoginDialog();
                    }
                    return throwError(err);
                }
            })
        );
    }
}
