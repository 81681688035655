import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { ToggleButtonBarOption } from './toggle-button-bar-option.interface';

@Component({
    selector: 'app-toggle-buttons-bar',
    templateUrl: './toggle-buttons-bar.component.html',
    styleUrls: ['./toggle-buttons-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleButtonsBarComponent {
    @Input() public options!: Array<ToggleButtonBarOption>;

    @Input() public selectedOptionValue: any;

    @Output() public readonly optionSelect = new EventEmitter<any>();

    public onOptionSelect(optionValue: any): void {
        if (optionValue === this.selectedOptionValue) {
            return;
        }

        this.optionSelect.emit(optionValue);
    }

    public getSelectedOptionIndex(
        options: Array<ToggleButtonBarOption>,
        selectedOptionValue: any
    ): number {
        return options.findIndex(
            (option) => option.value === selectedOptionValue
        );
    }

    public getSlidingBackgroundWidth(
        options: Array<ToggleButtonBarOption>
    ): string {
        return `${100 / options.length}%`;
    }
}
