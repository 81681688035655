import { AutoHooks, SubscriptionHandlerMixin } from '@adroit-group/ng-utils';
import { ConnectedPosition, ScrollDispatcher } from '@angular/cdk/overlay';
import {
    AfterViewInit,
    Component,
    ElementRef,
    forwardRef,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import mapboxgl from 'mapbox-gl';
import { animationFrameScheduler, combineLatest, from, fromEvent, Observable } from 'rxjs';
import { auditTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EAppTheme } from '~enums';
import { environment } from '~environment';
import { CircleData, Coordinate, Listing, MarkerData, Transfer } from "~interfaces";
import { ThemeHandlerMixin } from '~mixins';
import { MAP_TOKEN } from '~shared/token/map.token';

import { PositionerOverlayComponent } from '../positioner-overlay/positioner-overlay.component';

import type { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import type {
    GeoJSONSource,
    LngLatBounds,
    Map,
    Marker,
} from 'mapbox-gl';
import type * as Turf from '@turf/turf';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    providers: [
        {
            provide: MAP_TOKEN,
            useExisting: forwardRef(() => MapComponent),
        },
    ],
})
@AutoHooks()
export class MapComponent
    extends SubscriptionHandlerMixin(ThemeHandlerMixin())
    implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
    @Input() public markers?: MarkerData[];

    @Input() public circles?: CircleData[];

    @Input() public userCoords?: Coordinate | null;

    @Input() public singleTransfer = false;

    @ViewChild('mapContainer')
    public mapContainer!: ElementRef<HTMLDivElement>;
    @ViewChild('positionerOverlay')
    public positionerOverlay!: PositionerOverlayComponent;

    public map!: Map;
    public mapIsReady = false;

    public Map!: typeof Map;
    public LngLatBounds!: typeof LngLatBounds;
    public Marker!: typeof Marker;

    public Circle!: typeof Turf.circle;
    public Point!: typeof Turf.point;
    public FeatureCollection!: typeof Turf.featureCollection;
    public BBox!: typeof Turf.bbox;

    private hungarySouthWest: [number, number] = [
        16.032666231851103, 45.723837526483564,
    ];
    private hungaryNorthEast: [number, number] = [
        23.085192789406182, 48.6893257559178,
    ];

    private hungaryBounds!: LngLatBounds;

    public selectedShelterInfo: {
        shelterId: number;
        shelter?: Listing;
        transfer?: Transfer;
        clientX: number;
        clientY: number;
    } | null = null;

    public readonly connectedPositions: ConnectedPosition[] = [
        {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
        },
        {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
        },
        {
            originX: 'end',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'center',
        },
        {
            originX: 'start',
            originY: 'center',
            overlayX: 'end',
            overlayY: 'center',
        },
    ];

    constructor(
        private readonly ngZone: NgZone,
        private readonly scrollDispatcher: ScrollDispatcher // TODO if window scroll event works, refactor this
    ) {
        super();
    }

    public ngOnInit(): void {
        this.closeTooltipOnWindowScroll();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ((changes.markers || changes.userCoords) && this.map) {
            this.adjustCameraForMarkers();

            if (this.mapIsReady) {
                this.patchMarkerDatasource();
            }
        }
    }

    public ngAfterViewInit(): void {
        fromEvent(this.mapContainer.nativeElement, 'touchstart')
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((event) => {
                //@ts-ignore
                if (!event['fromMarker']) {
                    this.selectedShelterInfo = null;
                }
            });

        this.initMap();
        /* this.setMarkers();
        this.performInitialJumpToWhenReady(); */
    }

    public ngOnDestroy(): void {
        this.map?.remove();
        this.markerTooltipPositionerElement?.remove();
    }

    public closePopup(shelterId: number): void {
        if (this.selectedShelterInfo?.shelterId === shelterId) {
            this.selectedShelterInfo = null;
        }
    }

    public getSelectedShelter = (
        selectedShelterInfo: {
            shelterId: number;
            markerElement: HTMLElement;
        } | null,
        markers: MarkerData[]
    ): Listing | null => {
        if (!markers.length) {
            return null;
        }

        if (typeof selectedShelterInfo?.shelterId !== 'number') {
            return null;
        }

        return (
            markers.find(
                (marker) => marker.listing?.id === selectedShelterInfo.shelterId
            )?.listing ?? null
        );
    };

    public onCardLoad(): void {
        this.positionerOverlay?.updatePosition();
    }

    private closeTooltipOnWindowScroll(): void {
        this.ngZone.runOutsideAngular(() => {
            this.scrollDispatcher
                .scrolled()
                .pipe(
                    auditTime(0, animationFrameScheduler),
                    filter(() => !!this.selectedShelterInfo),
                    takeUntil(this.onDestroy$)
                )
                .subscribe(() => {
                    this.ngZone.run(() => {
                        this.selectedShelterInfo = null;
                    });
                });
        });
    }

    private getMapStyleForTheme(theme: EAppTheme): string {
        return theme === this.AppTheme.dark
            ? 'mapbox://styles/mapbox/' + theme + '-v10'
            : 'mapbox://styles/mapbox/streets-v11';
    }

    private async initMap(): Promise<void> {
        const {
            default: mapboxgl,
            Map,
            LngLatBounds,
            Marker,
        } = await import('mapbox-gl');

        this.Marker = Marker;
        this.Map = Map;
        this.LngLatBounds = LngLatBounds;

        const { circle, point, featureCollection, bbox } = await import(
            '@turf/turf'
        );
        this.Circle = circle;
        this.Point = point;
        this.FeatureCollection = featureCollection;
        this.Bbox = bbox;

        mapboxgl.accessToken = environment.mapboxGlAccessToken;

        this.hungaryBounds = new this.LngLatBounds(
            this.hungarySouthWest,
            this.hungaryNorthEast
        );

        this.map = new Map({
            container: this.mapContainer.nativeElement, // container ID
            style: this.getMapStyleForTheme(this.coreQuery.appTheme),
            bounds: this.hungaryBounds,
        });

        this.getMapLoad$()
            .pipe(
                switchMap(() =>
                    combineLatest([
                        this.coreQuery.appTheme$.pipe(
                            tap((theme) => {
                                this.map.setStyle(
                                    this.getMapStyleForTheme(theme),
                                    { diff: false }
                                );
                            }),
                            switchMap(() => from(this.map.once('styledata')))
                        ),
                        this.loadMarkerImage$(),
                    ])
                ),
                tap(([_, markerImage]) => {
                    this.setMarkerDatasource();
                    this.setCirclesDataSource();
                    this.setClusters();

                    this.clusterCounts();

                    this.setMarkers(markerImage);
                    this.addCirclesLayer();

                    this.setupClustersClickHandler();
                    this.setupMarkerClickHandler();

                    this.adjustCameraForMarkers();
                    if (this.singleTransfer && this.markers) {
                        const id = this.markers[0].listingId;
                        const circleData = this.circles?.find(
                            (transferCircle) =>
                                transferCircle.transferId === id || transferCircle.transfer?.id === id
                        );
                        if (circleData) {
                            this.patchCircleDataSource([circleData]);
                        }
                    }
                }),
                tap(() => (this.mapIsReady = true)),
                takeUntil(this.onDestroy$)
            )
            .subscribe();
    }

    private adjustCameraForMarkers(): void {
        const allCoords: Coordinate[] = [...(this.markers ?? [])].map(
            (marker) => marker.coordinate
        );
        if (this.userCoords) {
            allCoords.push(this.userCoords);
        }
        if (allCoords.length) {
            this.map.fitBounds(this.getBoundsOfMarkers(allCoords), {
                maxZoom: 12.2,
                duration: 3000,
            });
        } else {
            this.map.fitBounds(this.hungaryBounds, {
                duration: 3000,
            });
        }
    }

    private adjustCameraForCircle(circleData: CircleData): void {
        // if (circleData.length) {
        //     this.map.fitBounds(this.getBoundsOfCircle([circleData]), {
        //         maxZoom: 12.2,
        //         duration: 3000,
        //     });
        // } else {
        //     this.map.fitBounds(this.hungaryBounds, {
        //         duration: 3000,
        //     });
        // }

        this.map.fitBounds(this.getBoundsOfCircle([circleData]), {
            maxZoom: 12.2,
            duration: 3000,
        });
    }

    private getBoundsOfCircle(circles: CircleData[]): mapboxgl.LngLatBounds {
        let minLatitude = Number.MAX_SAFE_INTEGER;
        let minLongitude = Number.MAX_SAFE_INTEGER;
        let maxLatitude = Number.MIN_SAFE_INTEGER;
        let maxLongitude = Number.MIN_SAFE_INTEGER;

        const features = circles
            .map(({ coordinate: { lat, lon }, radius }) => [
                this.Point([lat, lon]),
                this.Circle(this.Point([lat, lon]), radius),
            ])
            .reduce((acc, curr) => [...acc, ...curr], []);

        const bboxOFCircles = this.Bbox(this.FeatureCollection(features as any) as any);

        const additionalMultiplier = 0.2;

        // add + 11% for the whole bounds to avoid hiding markers
        const fractionOfLatitudeDifference =
            ((maxLatitude - minLatitude) * additionalMultiplier) / 2;
        const fractionOfLongitudeDifference =
            ((maxLongitude - minLongitude) * additionalMultiplier) / 2;

        minLatitude -= fractionOfLatitudeDifference;
        maxLatitude += fractionOfLatitudeDifference;

        minLongitude -= fractionOfLongitudeDifference;
        maxLongitude += fractionOfLongitudeDifference;

        return new this.LngLatBounds([
            minLongitude,
            minLatitude,
            maxLongitude,
            maxLatitude,
        ]);
    }

    private getBoundsOfMarkers(coords: Coordinate[]): mapboxgl.LngLatBounds {
        let minLatitude = Number.MAX_SAFE_INTEGER;
        let minLongitude = Number.MAX_SAFE_INTEGER;
        let maxLatitude = Number.MIN_SAFE_INTEGER;
        let maxLongitude = Number.MIN_SAFE_INTEGER;

        coords!.forEach((coordinate) => {
            if (coordinate.lat < minLatitude) {
                minLatitude = coordinate.lat;
            }
            if (coordinate.lat > maxLatitude) {
                maxLatitude = coordinate.lat;
            }
            if (coordinate.lon < minLongitude) {
                minLongitude = coordinate.lon;
            }
            if (coordinate.lon > maxLongitude) {
                maxLongitude = coordinate.lon;
            }
        });

        const additionalMultiplier = 0.2;

        // add + 11% for the whole bounds to avoid hiding markers
        const fractionOfLatitudeDifference =
            ((maxLatitude - minLatitude) * additionalMultiplier) / 2;
        const fractionOfLongitudeDifference =
            ((maxLongitude - minLongitude) * additionalMultiplier) / 2;

        minLatitude -= fractionOfLatitudeDifference;
        maxLatitude += fractionOfLatitudeDifference;

        minLongitude -= fractionOfLongitudeDifference;
        maxLongitude += fractionOfLongitudeDifference;

        return new this.LngLatBounds([
            minLongitude,
            minLatitude,
            maxLongitude,
            maxLatitude,
        ]);
    }

    private getMapLoad$(): Observable<void> {
        return new Observable((subscriber) => {
            this.map.on('load', () => {
                subscriber.next();
                subscriber.complete();
            });
        });
    }

    private loadMarkerImage$(): Observable<
        HTMLImageElement | ImageBitmap | undefined
    > {
        return this.coreQuery.appTheme$.pipe(
            // ? Get the opposite color of the current theme for contrast
            map((theme) =>
                theme === this.AppTheme.dark
                    ? this.AppTheme.light
                    : this.AppTheme.dark
            ),
            map((color) => `/assets/images/${color}-marker.webp`),
            switchMap(
                (markerImgUrl) =>
                    new Observable<HTMLImageElement | ImageBitmap | undefined>(
                        (subscriber) => {
                            this.map.loadImage(markerImgUrl, (error, image) => {
                                subscriber.next(image);
                            });
                        }
                    )
            )
        );
    }

    private setMarkerDatasource(): void {
        if (!!this.map.getSource('markers')) {
            return;
        }

        this.map.addSource('markers', {
            type: 'geojson',
            data: this.createMarkersData(),
            cluster: true,
            clusterMaxZoom: 14, // Max zoom to cluster points on
            clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        });
    }

    private setCirclesDataSource(): void {
        if (!!this.map.getSource('circleData')) {
            return;
        }

        this.map.addSource('circleData', {
            type: 'geojson',
            data: undefined,
        });
    }

    private addCirclesLayer() {
        if (!!this.map.getLayer('circle-fill')) {
            return;
        }

        this.map.addLayer({
            id: 'circle-fill',
            type: 'fill',
            source: 'circleData',
            paint: {
                'fill-outline-color': '#4682b4',
                'fill-color': '#46b478',
                'fill-opacity': 0.3,
            },
        });
    }

    private patchCircleDataSource(circlesData = this.circles): void {
        (this.map.getSource('circleData') as GeoJSONSource).setData(
            this.createCirclesData(circlesData)
        );
    }

    private patchMarkerDatasource(): void {
        (this.map.getSource('markers') as GeoJSONSource).setData(
            this.createMarkersData()
        );
    }

    private createCirclesData(
        circlesData = this.circles
    ): FeatureCollection<Geometry, GeoJsonProperties> {
        return {
            type: 'FeatureCollection',
            features: (circlesData ?? []).map((circle) => ({
                ...this.Circle(
                    this.Point([circle.coordinate.lon, circle.coordinate.lat]),
                    circle.radius,
                    {
                        steps: circle.steps,
                        properties: {
                            circleData: circle,
                        },
                    } as any
                ),
            })),
        };
    }

    private createMarkersData(): FeatureCollection<
        Geometry,
        GeoJsonProperties
    > {
        return {
            type: 'FeatureCollection',
            features: this.markers!.map((marker) => {
                return {
                    type: 'Feature',
                    properties: {
                        markerData: marker,
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [
                            marker.coordinate.lon,
                            marker.coordinate.lat,
                        ],
                    },
                };
            }),
        };
    }

    private setClusters(): void {
        if (!!this.map.getLayer('clusters')) {
            return;
        }

        this.map.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'markers',
            filter: ['has', 'point_count'],
            paint: {
                // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
                // with three steps to implement three types of circles:
                //   * Blue, 20px circles when point count is less than 100
                //   * Yellow, 30px circles when point count is between 100 and 750
                //   * Pink, 40px circles when point count is greater than or equal to 750
                'circle-color': [
                    'step',
                    ['get', 'point_count'],
                    '#a7c8fe',
                    100,
                    '#5fdbb7',
                    750,
                    '#ffb964',
                ],
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    20,
                    100,
                    30,
                    750,
                    40,
                ],
            },
        });
    }

    private clusterCounts(): void {
        if (!!this.map.getLayer('cluster-count')) {
            return;
        }

        this.map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'markers',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12,
            },
        });
    }

    private setMarkers(markerImage: any): void {
        this.map.hasImage('custom-marker')
            ? this.map.updateImage('custom-marker', markerImage)
            : this.map.addImage('custom-marker', markerImage);

        if (!!this.map.getLayer('unclustered-point')) {
            return;
        }

        this.map.addLayer({
            id: 'unclustered-point',
            type: 'symbol',
            source: 'markers',
            filter: ['!', ['has', 'point_count']],
            layout: {
                'icon-image': 'custom-marker',
                // get the title name from the source's "title" property
                'text-field': ['get', 'title'],
                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                'text-offset': [0, 1.25],
                'text-anchor': 'top',
            },
        });
    }

    private setupClustersClickHandler(): void {
        this.map.on('click', 'clusters', (e) => {
            const features = this.map.queryRenderedFeatures(e.point, {
                layers: ['clusters'],
            });
            const clusterId = features[0].properties!.cluster_id;
            (
                this.map.getSource('markers') as GeoJSONSource
            ).getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (err) return;

                this.map.easeTo({
                    center: (features[0].geometry as any).coordinates,
                    zoom: zoom,
                });
            });
        });
    }

    private setupMarkerClickHandler(): void {
        // When a click event occurs on a feature in
        // the unclustered-point layer, open a popup at
        // the location of the feature, with
        // description HTML from its properties.
        this.map.on('click', 'unclustered-point', (e) => {
            const markerData: MarkerData = JSON.parse(
                e.features![0].properties!.markerData
            );

            if (markerData.listingId || markerData.listing) {
                this.markerTooltipPositionerElement?.remove?.();
                const newDiv = document.createElement('div');
                newDiv.style.position = 'fixed';
                newDiv.style.top = `${e.originalEvent.clientY}px`;
                newDiv.style.left = `${e.originalEvent.clientX}px`;
                document.body.appendChild(newDiv);
                this.markerTooltipPositionerElement = newDiv;

                this.selectedShelterInfo = {
                    clientX: e.originalEvent.clientX,
                    clientY: e.originalEvent.clientY,
                    shelterId: markerData.listingId! || markerData.listing?.id!,
                    shelter: markerData.listing,
                };
            }

            if (markerData.listing || markerData.listingId) {
                const id = markerData.listingId ?? markerData.listing?.id;
                const circleData = this.circles?.find(
                    (circle) =>
                        circle.transferId === id || circle.transfer?.id === id
                );

                if (circleData) {
                    this.patchCircleDataSource([circleData]);
                    this.adjustCameraForCircle(circleData);
                }
            }
        });
    }
}
