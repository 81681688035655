import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { EColoSchemePreference } from '~enums';

import { ColorSchemePreferenceService } from '../../service/color-scheme-preference.service';
import { EAppTheme } from '~enums';

export interface CoreState {
    theme: EAppTheme;
    colorPreference: EColoSchemePreference;
}

export function createInitialState(
    colorSchemePreferenceService: ColorSchemePreferenceService
): CoreState {
    const colorPreference = colorSchemePreferenceService.preference;

    return {
        theme:
            colorPreference === EColoSchemePreference.Dark
                ? EAppTheme.dark
                : EAppTheme.light,
        colorPreference,
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'core' })
export class CoreStore extends Store<CoreState> {
    constructor(
        colorSchemePreferenceService: ColorSchemePreferenceService
    ) {
        super(createInitialState(colorSchemePreferenceService));
    }
}
