<form
    *ngIf="loaded$ | async; or: loadingTpl"
    [formGroup]="transferFilterForm"
    (ngSubmit)="onFilter()"
    class="white-background-mat-select d-flex flex-column"
>
    <div
        *ngFor="
            let controlConfig of controlConfigsList
                | slice: 0:(showAllFilters ? controlConfigsList.length : 5)
        "
        class="d-flex flex-column my-3"
    >
        <div [ngSwitch]="controlConfig.type" class="w-100">
            <ng-container *ngSwitchCase="'radio'">
                <ng-container
                    *ngTemplateOutlet="
                        radioTemplate;
                        context: { $implicit: controlConfig }
                    "
                ></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'select'">
                <ng-container
                    *ngTemplateOutlet="
                        selectTemplate;
                        context: { $implicit: controlConfig }
                    "
                ></ng-container>
            </ng-container>
        </div>
    </div>

    <button
        (click)="showAllFilters = !showAllFilters"
        mat-flat-button
        type="button"
        class="d-flex justify-content-center align-items-center primary-button"
    >
        <span>
            {{ (showAllFilters ? 'less' : 'more') | translate }}
        </span>

        <mat-icon class="ms-1">
            {{ showAllFilters ? 'arrow_drop_up' : 'arrow_drop_down' }}
        </mat-icon>
    </button>

    <div class="d-flex justify-content-end align-items-center mt-4">
        <button (click)="close.emit()" type="button" class="secondary-button">
            {{ 'cancel' | translate }}
        </button>

        <button class="primary-button ms-2">
            {{ 'filtering' | translate }}
        </button>
    </div>

    <ng-template
        #radioTemplate
        [typedNgTemplate]="controlConfigTypeToken"
        let-controlConfig
    >
        <div class="d-flex justify-content-between">
            <div class="d-flex align-items-start mb-3">
                <mat-icon *ngIf="!!controlConfig.icon">
                    {{ controlConfig.icon }}
                </mat-icon>

                <label class="control-label ms-2">{{
                    controlConfig.label | translate
                }}</label>
            </div>

            <mat-radio-group
                *ngLet="controlConfig.options | safeAsync as options"
                [formControlName]="controlConfig.formControlName"
                class="d-flex flex-column"
            >
                <mat-radio-button
                    *ngFor="let option of options; let isLast = last"
                    [value]="option.value"
                    color="primary"
                >
                    {{ option.viewValue$ | async }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </ng-template>

    <ng-template
        #selectTemplate
        [typedNgTemplate]="controlConfigTypeToken"
        let-controlConfig
    >
        <div class="d-flex flex-column">
            <div class="w-100 d-flex align-items-center mb-3">
                <mat-icon *ngIf="!!controlConfig.icon">
                    {{ controlConfig.icon }}
                </mat-icon>

                <label class="control-label ms-2">{{
                    controlConfig.label | translate
                }}</label>
            </div>

            <mat-form-field
                appearance="fill"
                class="w-100 shelters-filter-input"
                *ngLet="
                    transferFilterForm.value[
                        controlConfig.formControlName
                    ] as controlValue
                "
            >
                <mat-label
                    *ngIf="controlConfig.isMultiSelect && !controlValue?.length"
                >
                    {{ 'no-filter' | translate }}
                </mat-label>

                <mat-select
                    *ngLet="controlConfig.options | safeAsync as options"
                    [formControlName]="controlConfig.formControlName"
                    [multiple]="controlConfig.isMultiSelect"
                >
                    <mat-select-trigger *ngIf="controlConfig.isMultiSelect">
                        {{
                            controlValue
                                ? ((options | find: 'value':controlValue[0])
                                      ?.viewValue$ | async)
                                : ''
                        }}

                        <span *ngIf="controlValue?.length > 1">
                            (+{{ controlValue.length - 1 }}
                            {{
                                controlValue?.length === 2 ? 'other' : 'others'
                            }})
                        </span>
                    </mat-select-trigger>

                    <mat-option
                        *ngIf="!controlConfig.isMultiSelect"
                        selected
                        value=""
                    >
                        {{ 'no-filter' | translate }}
                    </mat-option>

                    <mat-option
                        *ngFor="let option of options"
                        [value]="option.value"
                    >
                        {{ option.viewValue$ | async }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </ng-template>
</form>

<ng-template #loadingTpl>
    <div
        class="w-100 d-flex justify-content-center align-items-center"
        style="flex-wrap: wrap; text-align: center; height: 80vh"
    >
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>

        <h1 class="w-100">
            {{ 'loading-filters' | translate }}
        </h1>
    </div>
</ng-template>
