<form
    [formGroup]="registerFormGroup"
    class="form d-flex flex-column mt-34px background-input"
    (ngSubmit)="onFormSubmit()"
>
    <ng-container *ngIf="isInRegisterMode">
        <mat-form-field
            *ngLet="registerFormGroup.controls.email.errors as errors"
            appearance="outline"
            class="me-3 ms-3"
        >
            <mat-label>Email</mat-label>
            <input
                matInput
                formControlName="email"
                required
                autocomplete="off"
            />
            <mat-error *ngIf="errors?.required">
                {{ 'email-is-required' | translate }}
            </mat-error>
            <mat-error *ngIf="errors?.email">
                {{ 'email-is-invalid' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field
            *ngLet="registerFormGroup.controls.password.errors as errors"
            appearance="outline"
            class="me-3 ms-3"
        >
            <mat-label>{{ 'password' | translate }}</mat-label>
            <input
                matInput
                formControlName="password"
                [type]="isPasswordMode ? 'password' : 'text'"
                autocomplete="off"
            />
            <button
                type="button"
                mat-icon-button
                matSuffix
                (click)="isPasswordMode = !isPasswordMode"
            >
                <mat-icon>{{
                    isPasswordMode ? 'visibility_off' : 'visibility'
                }}</mat-icon>
            </button>
            <mat-error *ngIf="errors?.required">
                {{ 'password-is-required' | translate }}
            </mat-error>
            <mat-error *ngIf="errors?.minlength">
                {{ 'min-password-length-error' | translate: { length: 6 } }}
            </mat-error>
        </mat-form-field>
    </ng-container>

    <mat-form-field
        *ngIf="!isInRegisterMode"
        appearance="outline"
        class="me-3 ms-3"
    >
        <mat-label>{{ 'registered-email' | translate }}</mat-label>
        <input
            matInput
            formControlName="registeredEmail"
            required
            autocomplete="off"
        />
    </mat-form-field>

    <mat-form-field appearance="outline" class="me-3 ms-3">
        <mat-label>{{ 'first-name' | translate }}</mat-label>
        <input matInput formControlName="firstName" type="text" />
        <mat-error *ngIf="registerFormGroup.controls.firstName.invalid">{{
            'this-input-is-required' | translate
        }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="me-3 ms-3">
        <mat-label>{{ 'last-name' | translate }}</mat-label>
        <input matInput formControlName="lastName" type="text" />
        <mat-error *ngIf="registerFormGroup.controls.lastName.invalid">{{
            'this-input-is-required' | translate
        }}</mat-error>
    </mat-form-field>

    <ng-container formArrayName="contacts">
        <ng-container
            *ngFor="
                let contactGroup of contactsFormArray.controls;
                let idx = index;
                let last = last
            "
        >
            <ng-container [formGroupName]="idx">
                <mat-form-field appearance="outline" class="me-3 ms-3">
                    <mat-label>{{ 'contact-type' | translate }}</mat-label>
                    <mat-select
                        formControlName="type"
                        (ngModelChange)="
                            resetFormControlWithType(
                                $any(contactGroup.get('value')),
                                $event
                            )
                        "
                    >
                        <mat-option
                            *ngFor="
                                let option of contactGroup.value.type
                                    | invoke
                                        : $any(getAvailableContactTypeOptions)
                                        : selectedContactTypes
                            "
                            [value]="option.type"
                        >
                            {{ option.name$ | async }}
                        </mat-option>
                    </mat-select>

                    <mat-error
                        *ngIf="
                            $any(contactGroup).controls.type.errors?.required
                        "
                        >{{ 'this-input-is-required' | translate }}</mat-error
                    >
                    <button
                        *ngIf="contactsFormArray.controls.length > 1"
                        class="delete-icon-button"
                        type="button"
                        mat-icon-button
                        (click)="removeContactGroup(idx)"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-form-field>

                <div
                    *ngIf="contactGroup.value.type"
                    class="d-flex justify-content-center me-3 ms-3"
                    style="flex-wrap: wrap"
                >
                    <div
                        *ngIf="
                            contactGroup.value.type === contactTypes.Email &&
                            isInRegisterMode
                        "
                        class="w-100 d-flex mat-slide-toggle-container"
                    >
                        <mat-slide-toggle
                            color="primary"
                            [disabled]="
                                !registerFormGroup.controls.email.value ||
                                registerFormGroup.controls.email.errors
                            "
                            [checked]="isEmailContactSameAsRegisterEmail"
                            (change)="
                                onContactEmailSameAsRegisterEmailChange(
                                    $event.checked
                                )
                            "
                        >
                        </mat-slide-toggle>

                        <span class="ms-2 align-self-center">
                            {{
                                'email-contact-same-as-register-email'
                                    | translate
                            }}
                        </span>
                    </div>

                    <div class="w-100 d-flex justify-content-center">
                        <!-- Phone number prefix input -->
                        <div
                            *ngIf="
                                contactGroup.value.type === contactTypes.Phone
                            "
                            class="me-3"
                            style="width: 25% !important"
                        >
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>
                                    {{ 'phone-number-prefix' | translate }}
                                </mat-label>

                                <input
                                    type="text"
                                    placeholder="Pick one"
                                    aria-label="Number"
                                    matInput
                                    formControlName="prefix"
                                    [matAutocomplete]="phoneNumberAutoComplete"
                                />

                                <mat-autocomplete
                                    #phoneNumberAutoComplete="matAutocomplete"
                                >
                                    <mat-option
                                        *ngFor="
                                            let option of filteredPhoneNumberPrefixList$
                                                | async;
                                            trackBy: trackPhoneNumberPrefixes
                                        "
                                        [value]="option.dialCode"
                                        [matTooltip]="option.isoCode"
                                        class="d-flex justify-content-around align-items-center"
                                    >
                                        <img
                                            [src]="option.flag"
                                            style="
                                                width: 30px;
                                                height: 30px;
                                                margin-right: 5px;
                                            "
                                        />
                                        <span>{{ option.dialCode }}</span>

                                        <hr *ngIf="!last" />
                                    </mat-option>
                                </mat-autocomplete>

                                <mat-error
                                    *ngIf="
                                        contactGroup.get('value')!.errors
                                            ?.required
                                    "
                                    >{{
                                        'this-input-is-required' | translate
                                    }}</mat-error
                                >
                            </mat-form-field>
                        </div>
                        <!-- Phone number prefix input -->

                        <!-- Contact value input -->
                        <div
                            [ngStyle]="{
                                width:
                                    contactGroup.value.type ===
                                    contactTypes.Phone
                                        ? '75%'
                                        : '100%'
                            }"
                        >
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{
                                    contactGroup.value.type
                                        | invoke: getSelectedOptionName$
                                        | async
                                }}</mat-label>
                                <input
                                    matInput
                                    formControlName="value"
                                    type="text"
                                />
                                <mat-error
                                    *ngIf="
                                        contactGroup.get('value')!.errors
                                            ?.required
                                    "
                                    >{{
                                        'this-input-is-required' | translate
                                    }}</mat-error
                                >
                                <mat-error
                                    *ngIf="
                                        contactGroup.get('value')!.errors?.email
                                    "
                                    >{{
                                        'email-is-invalid' | translate
                                    }}</mat-error
                                >
                            </mat-form-field>
                        </div>
                        <!-- Contact value input -->
                    </div>
                </div>

                <hr *ngIf="!last" />
            </ng-container>
        </ng-container>

        <h4 class="contact-text me-3 ms-3">
            {{ 'need-more-contact' | translate }}
        </h4>
    </ng-container>

    <button
        *ngIf="contactsFormArray.controls.length < allContactTypeOptions.length"
        class="d-flex justify-content-end more-field text-uppercase me-3 ms-3"
        type="button"
        (click)="addContactFormGroup()"
    >
        + {{ 'add-more-contact' | translate }}
    </button>

    <mat-form-field appearance="outline" class="me-3 ms-3">
        <mat-label>{{ 'bio-from-me' | translate }}</mat-label>
        <textarea matInput formControlName="bio" type="text"></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline" class="me-3 ms-3">
        <mat-label>{{ 'spoken-languages' | translate }}</mat-label>
        <mat-select formControlName="spokenLanguages" multiple>
            <mat-option
                *ngFor="let option of allLanguageOptions"
                [value]="option.languageCode"
                >{{ option.name$ | async }}</mat-option
            >
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="me-3 ms-3">
        <mat-label>{{ 'facebook-link' | translate }}</mat-label>
        <input matInput formControlName="facebookLink" type="text" />
    </mat-form-field>
    <span class="facebook-tooltip">{{
        'facebook-link-tooltip' | translate
    }}</span>

    <mat-error *ngIf="formError">{{ formError }}</mat-error>
    <mat-error *ngIf="formError$">{{ formError$ | async }}</mat-error>

    <div *ngIf="isInRegisterMode" class="d-flex justify-content-center mt-3">
        <app-recaptcha
            #captcha
            [parentFormGroup]="registerFormGroup"
        ></app-recaptcha>
    </div>

    <button
        type="submit"
        class="register-button d-flex align-items-center justify-content-center mt-24px"
        mat-raised-button
        color="primary"
    >
        <ng-container *ngIf="!registerSubscription; else inProgress">
            {{ (isInRegisterMode ? 'register' : 'submit') | translate }}
        </ng-container>

        <ng-template #inProgress>
            <mat-spinner [diameter]="30"></mat-spinner>
        </ng-template>
    </button>
</form>
