import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '~environment';
import {
    Country,
    GetMapListingsRequest,
    GetTransfersRequest,
    GetTransfersResponse,
    MapEntity,
    Transfer,
    TransferToSend,
    OwnTransfersResponse, Availability
} from '~interfaces';
import { UserQuery } from '~store/user/user.query';

import { CountryService } from '../country/country.service';

@Injectable({
    providedIn: 'root'
})
export class TransfersService {
    public currentInterval = Math.floor(dayjs().hour() / 4);
    public currentDay = dayjs().day() - 1;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly userQuery: UserQuery,
        private readonly countryService: CountryService
    ) {
    }

    public getTransfers(
        reqConfig: GetTransfersRequest = {}
    ): Observable<GetTransfersResponse> {
        return combineLatest([
            this.countryService.getCountries$(),
            this.httpClient.get<GetTransfersResponse>(
                `${environment.apiBase}/api/rides`,
                {
                    params: reqConfig
                }
            )
        ]).pipe(
            take(1),
            map(([countries, response]) => {
                response.data.forEach((shelter) => {
                    this.fixTransfer(shelter, countries);
                });
                return response;
            })
        );
    }

    public getTransfersBasedOnIds(
        transferIds: number[],
        lat?: number,
        lon?: number
    ): Observable<any> {
        if (!Array.isArray(transferIds) || !transferIds.length) {
            return of([]);
        }
        let params = new HttpParams();
        if (lat && lon) {
            params = params.set('latitude', lat).set('longitude', lon);
        }
        transferIds.forEach((transferId, index) => {
            params = params.set(`transfers[${index}]`, transferId);
        });

        return combineLatest([
            this.countryService.getCountries$(),
            this.httpClient.get<Transfer[]>(
                `${environment.apiBase}/api/rides/list`,
                {
                    params
                }
            )
        ]).pipe(
            take(1),
            map(([countries, res]) =>
                res.map((shelter) => this.fixTransfer(shelter, countries))
            )
        );
    }

    public getTransfersForMap(
        reqConfig: GetMapListingsRequest = {}
    ): Observable<MapEntity[]> {
        return this.httpClient.get<MapEntity[]>(
            `${environment.apiBase}/api/ridemaplisting`,
            {
                params: reqConfig
            }
        );
    }

    public getFavouriteTransfers(
        transferIds: number[],
        lat?: number,
        lon?: number
    ): Observable<Transfer[]> {
        if (!Array.isArray(transferIds) || !transferIds.length) {
            return of([]);
        }

        let params = new HttpParams();

        if (lat && lon) {
            params = params.set('latitude', lat).set('longitude', lon);
        }

        transferIds.forEach((transferId, index) => {
            params = params.set(`transfers[${index}]`, transferId);
        });

        return this.httpClient
            .get<any>(`${environment.apiBase}/api/ride/list`, {
                params
            })
            .pipe(
                map((resp) => {
                    if (Array.isArray(resp)) {
                        const favoriteTransfers: number[] = JSON.parse(
                            localStorage.getItem('favorite-transfers') ?? '[]'
                        );

                        resp.forEach((transfer) => {
                            transfer.favourite = +favoriteTransfers.includes(
                                transfer.id
                            );
                            transfer.from = dayjs(transfer.from).isValid()
                                ? dayjs(transfer.from).format('YYYY-MM-DD')
                                : undefined;
                            transfer.to = dayjs(transfer.to).isValid()
                                ? dayjs(transfer.to).format('YYYY-MM-DD')
                                : undefined;
                        });
                    }

                    return resp;
                })
            );
    }

    public getTransfer(
        transferId: number,
        position?: GeolocationPosition | null
    ): Observable<Transfer> {
        let queryParams = new HttpParams();
        if (position?.coords?.latitude && position?.coords?.longitude) {
            queryParams = queryParams
                .set('latitude', position.coords.latitude)
                .set('longitude', position.coords.longitude);
        }

        return combineLatest([
            this.countryService.getCountries$(),
            this.httpClient.get<Transfer>(
                `${environment.apiBase}/api/ride/${transferId}`,
                {
                    params: queryParams
                }
            )
        ]).pipe(
            take(1),
            map(([countries, transfer]) => {
                this.fixTransfer(transfer, countries);
                return transfer;
            })
        );
    }

    public updateTransfer(
        transferId: number,
        transfer: TransferToSend
    ): Observable<any> {
        return this.httpClient.post(
            `${environment.apiBase}/api/ride/edit/${transferId}`,
            {
                ...transfer,
                token: this.userQuery.accessToken
            }
        );
    }

    public createTransfer(transfer: TransferToSend): Observable<any> {
        return this.httpClient.post(`${environment.apiBase}/api/ride/create`, {
            ...transfer,
            token: this.userQuery.accessToken
        });
    }

    public deleteTransfer(transferId: number): Observable<any> {
        return this.httpClient.delete<number>(
            `${environment.apiBase}/api/ride/delete/${transferId}`,
            {
                body: {
                    token: this.userQuery.accessToken
                }
            }
        );
    }

    public getUserTransfers(): Observable<Transfer[]> {
        const params = new HttpParams().set(
            'token',
            this.userQuery.accessToken!
        );
        return combineLatest([
            this.countryService.getCountries$(),
            this.httpClient.get<OwnTransfersResponse>(
                `${environment.apiBase}/api/user/rides`,
                {
                    params
                }
            )
        ]).pipe(
            take(1),
            map(([countries, res]) => {
                // ((res as any).data as any[]).forEach((transfer) => {
                //     this.fixTransfer(transfer, countries);
                // });

                // return (res as any).data;

                res.transfers.forEach((transfer) => {
                    this.fixTransfer(transfer, countries);
                });

                return res.transfers ?? [];
            })
        );
    }

    private fixTransfer(transfer: Transfer, countries: Country[]): Transfer {
        const favoriteTransfers: number[] = JSON.parse(
            localStorage.getItem('favorite-transfers') ?? '[]'
        );
        transfer.country = countries.find(
            (country) => country?.id === transfer?.country_id
        )!;
        transfer.favourite = +favoriteTransfers.includes(transfer.id);
        transfer.lat = transfer.lat && parseFloat(transfer.lat.toString());
        transfer.lon = transfer.lon && parseFloat(transfer.lon.toString());
        transfer.created_at = dayjs(transfer.created_at).isValid()
            ? dayjs(transfer.created_at)
            : null;
        transfer.updated_at = dayjs(transfer.updated_at).isValid()
            ? dayjs(transfer.updated_at)
            : null;
        transfer.availability = this.adaptAvailabilityData(transfer.ridestimes);
        transfer.isAvailable = !!transfer.availability?.[this.currentDay]?.[this.currentInterval];

        return transfer;
    }

    private adaptAvailabilityData(availabilityData: any[]): Availability {
        return (availabilityData ?? []).reduce(
            (acc, curr) => ({
                ...acc,
                [curr.day]: {
                    ...acc[curr.day],
                    [curr.hour_interval_id]: true
                }
            }),
            {}
        );
    }
}
