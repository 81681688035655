<section class="d-flex flex-nowrap" [formGroup]="weekdayHourForm">
    <div class="d-flex flex-wrap" style="width: 20%; height: 100%">
        <div class="d-flex flex-wrap w-100" style="height: 20px">
            <mat-label class="me-1"> Összes </mat-label>

            <mat-checkbox
                [checked]="allChecked || !isAnyUnChecked()"
                (change)="allChecked = $event.checked"
                color="primary"
                class="interval-checkbox"
            >
            </mat-checkbox>
        </div>

        <ul
            class="d-flex flex-column justify-content-between header-list y-header-list w-100"
            style="height: calc(100% - 20px)"
        >
            <li
                *ngFor="let label of dayLabels"
                class="d-flex align-items-center header-item"
                [ngStyle]="{ flex: '1 1 ' + 100 / dayLabels.length + '%' }"
            >
                <span>
                    {{ label | async }}
                </span>
            </li>
        </ul>
    </div>

    <div class="d-flex flex-wrap" style="width: 80%">
        <ul
            class="w-100 d-flex justify-content-between header-list x-header-list"
        >
            <li
                *ngFor="let label of intervalLabels"
                class="header-item"
                [ngStyle]="{ flex: '1 1 ' + 100 / intervalLabels.length + '%' }"
            >
                {{ label }}
            </li>
        </ul>

        <div
            *ngFor="let day of weekdaysList"
            [formGroupName]="day"
            class="w-100 d-flex justify-content-between day-row"
            style="flex: 1 1 auto"
        >
            <mat-checkbox
                *ngFor="let interval of intervalList"
                [formControlName]="interval"
                [matTooltip]="
                    ({ day: day, interval: interval }
                        | dayIntervalName
                        | async) || ''
                "
                [ngStyle]="{ flex: '1 1 ' + 100 / intervalLabels.length + '%' }"
                color="primary"
                class="interval-checkbox mx-1 my-1"
            >
            </mat-checkbox>
        </div>
    </div>
</section>
