import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SplashScreenComponent } from '../shared/component/splash-screen/splash-screen.component';
import { SharedModule } from '../shared/shared.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { HeaderComponent } from './header/header.component';
import { LangSelectorComponent } from './header/lang-selector/lang-selector.component';
import { SidenavComponent } from './layout/sidenav/sidenav.component';
import { AdroitNgUtilsModule } from '@adroit-group/ng-utils';
import { MatDividerModule } from '@angular/material/divider';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReportDialogComponent } from './report-dialog/report-dialog.component';
import { MyDataFormModule } from "~shared/component/my-data-form/my-data-form.module";

@NgModule({
    declarations: [
        SidenavComponent,
        HeaderComponent,
        LangSelectorComponent,
        ConfirmDialogComponent,
        SplashScreenComponent,
        ReportDialogComponent,
    ],
    imports: [
        AdroitNgUtilsModule,
        CommonModule,
        BrowserAnimationsModule,
        RouterModule,
        TranslateModule,
        MatDialogModule,
        MatDividerModule,
        MatSidenavModule,
        MatListModule,
        MatToolbarModule,
        MatButtonModule,
        MatRippleModule,
        MatSelectModule,
        SharedModule,
        MyDataFormModule
    ],
    exports: [ConfirmDialogComponent, AdroitNgUtilsModule],
})
export class CoreModule {}
