import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AVAILABLE_LANGS } from './available-langs';
import { LangOption } from './lang-pion';

@Component({
    selector: 'app-lang-selector',
    templateUrl: './lang-selector.component.html',
    styleUrls: ['./lang-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LangSelectorComponent implements OnInit {
    @Input() public selectedLang: LangOption = AVAILABLE_LANGS[0];

    @Output() public readonly selectLang = new EventEmitter<string>();

    public availableLangs = AVAILABLE_LANGS;

    public isOpen = false;

    public ngOnInit(): void {}
}
