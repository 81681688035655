import { Observable } from 'rxjs';

export abstract class ResizeObserverHelper {
  static observe$(element: HTMLElement, options?: ResizeObserverOptions): Observable<ResizeObserverEntry[]> {
    return new Observable((subscriber) => {
      const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        subscriber.next(entries);
      });

      resizeObserver.observe(element, options);

      return () => {
        resizeObserver.disconnect();
      };
    });
  }
}
