import { PlatformObserverService } from '@adroit-group/ng-utils';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppInstallService } from '../app-install.service';

export const APP_INSTALL_PROMPT_INITIALIZER: Provider = {
    provide: APP_INITIALIZER,
    useFactory:
        (
            platformObserver: PlatformObserverService,
            appInstallService: AppInstallService
        ) =>
        (): Subscription =>
            fromEvent<BeforeInstallPromptEvent>(
                platformObserver.window,
                'beforeinstallprompt'
            )
                .pipe(
                    tap((event) => {
                        event.preventDefault();
                        appInstallService.installSignal$.next(event);
                    })
                )
                .subscribe(),
    deps: [PlatformObserverService, AppInstallService],
    multi: true,
};
