import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    BindQueryParamsFactory,
    BindQueryParamsManager,
} from '@ngneat/bind-query-params';
import { TranslateService } from '@ngx-translate/core';
import {
    BehaviorSubject,
    combineLatest,
    Observable,
    ReplaySubject,
    Subject,
} from 'rxjs';
import { map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { CountryService } from '~app/service/country/country.service';
import { SpokenLanguageService } from '~app/service/spoken-language/spoken-language.service';
import { NUMBER_SELECT_OPTIONS } from '~constants';
import { ShelterType } from '~enums';
import { IBooleanSelect, INumberSelect, IStringSelect, ShelterFilterFormValue } from "~interfaces";
import { expandAnimation } from "~shared/animations";
import { ShelterAvailableFor } from '~shared/enums/shelter-available-for';

export interface FilterFormValue {
    place?: number | null;
    allow_pets?: boolean | null;
    allow_babies?: boolean | null;
    countries?: string[] | null;
    transport_available?: boolean | null;
    can_call_at_night?: boolean | null;
    is_accessible?: boolean | null;
    type?: ShelterType[] | null;
    search?: string;
    available_for?: ShelterAvailableFor[] | null;
    city?: string | null;
}

export interface ControlConfig {
    label: string;
    type: string;
    icon: string;
    formControlName: string;
    options:
        | (INumberSelect | IStringSelect)[]
        | Observable<(INumberSelect | IStringSelect)[]>;
    isMultiSelect?: boolean;
}

@Component({
    selector: 'app-shelter-filter',
    templateUrl: './shelter-filter.component.html',
    styleUrls: ['./shelter-filter.component.scss'],
    animations: [expandAnimation]
})
export class ShelterFilterComponent implements OnInit, OnDestroy {
    public controlConfigTypeToken!: ControlConfig;

    public shelterFilterForm!: FormGroup;

    public bindQueryParamsManager!: BindQueryParamsManager<any>;

    private readonly onDestroy$ = new Subject<void>();

    @Input() public initialFilter = true;

    @Output() public readonly filterFormValueChange =
        new EventEmitter<FilterFormValue>();

    @Output() public readonly closeFilter = new EventEmitter<void>();

    @Output() public readonly search = new EventEmitter<ShelterFilterFormValue>();

    public readonly places = NUMBER_SELECT_OPTIONS;

    public readonly rooms = NUMBER_SELECT_OPTIONS;

    public booleanSelectValues: IBooleanSelect[] = [
        { value: true, viewValue$: this.translateService.stream('yes') },
        { value: null, viewValue$: this.translateService.stream('no-filter') },
    ];

    public readonly countries$ = new ReplaySubject<IStringSelect[]>();
    public readonly languages$ = new ReplaySubject<IStringSelect[]>();

    public readonly loaded$ = new BehaviorSubject(false);

    public shelterTypeOptions = Object.values(ShelterType).map((value) => ({
        value,
        viewValue$: this.translateService.stream(`shelter-types.${value}`),
    }));

    public shelterAvailableFor = Object.values(ShelterAvailableFor).map((value) => ({
        value,
        viewValue$: this.translateService.stream(`availability-period.${value}`),
    }));

    public readonly controlConfigsList = [
        {
            label: 'filter.slots',
            type: 'select',
            icon: 'family_restroom',
            formControlName: 'place',
            options: this.places,
        },
        {
            label: 'filter.country',
            type: 'select',
            icon: 'map_border',
            formControlName: 'countries',
            isMultiSelect: true,
            options: this.countries$,
        },
        {
            label: 'filter.city',
            type: 'input',
            icon: 'location_city',
            formControlName: 'city',
        },
        {
            label: 'filter.spoken-languages',
            type: 'select',
            icon: 'translate',
            formControlName: 'spoken_language',
            isMultiSelect: true,
            options: this.languages$,
        },
        {
            label: 'filter.type',
            type: 'select',
            icon: 'home',
            formControlName: 'type',
            options: this.shelterTypeOptions,
        },
        {
            label: 'filter.available_for',
            type: 'select',
            icon: 'access_time',
            formControlName: 'available_for',
            options: this.shelterAvailableFor,
        },
        {
            label: 'filter.is-allowed-animals',
            type: 'radio',
            icon: 'pets',
            formControlName: 'allow_pets',
            options: this.booleanSelectValues,
        },
        {
            label: 'is-accessible',
            type: 'radio',
            icon: 'accessible',
            formControlName: 'is_accessible',
            options: this.booleanSelectValues,
        },
        // ? visible in detailed mode
        {
            label: 'filter.is-allowed-babies',
            type: 'radio',
            icon: 'child_friendly',
            formControlName: 'allow_babies',
            options: this.booleanSelectValues,
        },
        {
            label: 'filter.transport-available',
            type: 'radio',
            icon: 'directions_car',
            formControlName: 'transport_available',
            options: this.booleanSelectValues,
        },
        {
            label: 'can-call-at-night',
            type: 'radio',
            icon: 'mode_night',
            formControlName: 'can_call_at_night',
            options: this.booleanSelectValues,
        },
    ];

    public showAllFilters = false;

    private shelterFilterFormForParams!: FormGroup;

    constructor(
        private readonly translateService: TranslateService,
        private readonly queryParamBinderFactory: BindQueryParamsFactory,
        private readonly spokenLanguageService: SpokenLanguageService,
        private readonly countryService: CountryService
    ) {}

    public ngOnInit(): void {
        this.shelterFilterFormForParams = new FormGroup({
            place: new FormControl(''),
            allow_pets: new FormControl(),
            allow_babies: new FormControl(),
            countries: new FormControl(),
            spoken_language: new FormControl(),
            transport_available: new FormControl(),
            can_call_at_night: new FormControl(),
            is_accessible: new FormControl(),
            type: new FormControl(''),
            available_for: new FormControl(''),
            city: new FormControl()
        });

        this.shelterFilterForm = new FormGroup({
            place: new FormControl(''),
            allow_pets: new FormControl(),
            allow_babies: new FormControl(),
            countries: new FormControl(),
            spoken_language: new FormControl(),
            transport_available: new FormControl(),
            can_call_at_night: new FormControl(),
            is_accessible: new FormControl(),
            type: new FormControl(''),
            available_for: new FormControl(''),
            city: new FormControl()
        });

        this.bindQueryParamsManager = this.queryParamBinderFactory
            .create([
                { queryKey: 'allow_pets', type: 'boolean' },
                { queryKey: 'countries', type: 'array' },
                { queryKey: 'spoken_language', type: 'array' },
                { queryKey: 'place', type: 'number' },
                { queryKey: 'type', type: 'string' },
                { queryKey: 'is_accessible', type: 'boolean' },
                { queryKey: 'can_call_at_night', type: 'boolean' },
                { queryKey: 'transport_available', type: 'boolean' },
                { queryKey: 'allow_babies', type: 'boolean' },
                { queryKey: 'available_for', type: 'string' },
                { queryKey: 'city', type: 'string' },
            ])
            .connect(this.shelterFilterFormForParams);

        combineLatest([this.countries$, this.languages$])
            .pipe(
                take(1),
                tap(() => {
                    this.loaded$.next(true);
                    this.bindQueryParamsManager.syncAllDefs();
                })
            )
            .subscribe();

        this.countryService
            .getCountries$()
            .pipe(
                map((countries) =>
                    countries.map((country) => ({
                        value: country.code,
                        viewValue$: this.translateService.stream(
                            'country.' + country.code
                        ),
                    }))
                ),
                tap((countryOptions) => {
                    this.countries$.next(countryOptions);
                })
            )
            .subscribe();

        this.spokenLanguageService
            .getLanguages$()
            .pipe(
                map((spokenLangs) =>
                    spokenLangs.map((lang) => ({
                        value: lang.code,
                        viewValue$: this.translateService.stream(
                            'language.' + lang.code
                        ),
                    }))
                ),
                tap((langOptions) => {
                    this.languages$.next(langOptions);
                })
            )
            .subscribe();

        this.shelterFilterForm.setValue(this.shelterFilterFormForParams.value);

        this.shelterFilterForm.valueChanges
            .pipe(
                takeUntil(this.onDestroy$),
                startWith(this.shelterFilterForm.value)
            )
            .subscribe((value) => {
                this.filterFormValueChange.emit(value);
            });

        if (this.initialFilter) {
            this.filterFormValueChange.emit(this.shelterFilterForm.value);
        }
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    public onFilter(): void {
        this.shelterFilterFormForParams.setValue(this.shelterFilterForm.value);
        this.search.emit(this.shelterFilterFormForParams.value);
    }
}
