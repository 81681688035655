import { SubscriptionHandlerMixin } from '@adroit-group/ng-utils';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, takeUntil, throttleTime } from 'rxjs/operators';

@Component({
    selector: 'app-text-search',
    templateUrl: './text-search.component.html',
    styleUrls: ['./text-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextSearchComponent extends SubscriptionHandlerMixin() implements OnInit {

    public readonly searchControl = new FormControl('');

    @Input() public label = '';

    @Output() public readonly search = new EventEmitter<string>();

    public ngOnInit(): void {
        this.searchControl.valueChanges.pipe(
            takeUntil(this.onDestroy$),
            debounceTime(500),
        ).subscribe(value => this.search.emit(value));
    }
}
