import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { User } from '~interfaces';

export interface UserState {
    accessToken: string | null;
    user: User | null;
    initialUserHasBeenDetermined: boolean;
}

export function createInitialState(): UserState {
    return {
        accessToken: null,
        user: null,
        initialUserHasBeenDetermined: false
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'user' })
export class UserStore extends Store<UserState> {
    constructor() {
        super(createInitialState());
    }
}
