<mat-form-field appearance="fill">
    <mat-select [(value)]="selectedLang"
                (openedChange)="isOpen = !isOpen"
                (selectionChange)="selectLang.emit($event.value.code)">
        <mat-select-trigger>
            <div class="d-flex trigger-label-container">
                <button mat-icon-button (click)="isOpen = !isOpen" class="menu-toggle-button">
                    <mat-icon class="themed-text">
                        {{ isOpen ? 'arrow_drop_up' : 'arrow_drop_down' }}
                    </mat-icon>
                </button>

                <img [src]="selectedLang?.imageUrl" class="lang-icon-img" />

                <p style="margin-left: 10px;" class="themed-text lang-code-label">
                    {{ selectedLang?.code | uppercase }}
                </p>
            </div>
        </mat-select-trigger>

        <mat-option *ngFor="let lang of availableLangs" [value]="lang">
            <div class="option-container">
                <img [src]="lang?.imageUrl" class="lang-icon-img" />

                <p class="themed-text lang-code-label">
                    {{ lang?.code | uppercase }}
                </p>
            </div>
        </mat-option>
    </mat-select>
</mat-form-field>
