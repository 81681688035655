import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export const expandRightAnimation: AnimationTriggerMetadata = trigger(
    'expandRightAnimation',
    [
        transition(
            ':enter',
            [
                style({ width: 0, overflow: 'hidden' }),
                animate('225ms ease-in-out',
                    style({ width: '*', overflow: 'hidden' }))
            ]
        ),
        transition(
            ':leave',
            [
                style({ height: '*',  overflow: 'hidden' }),
                animate('225ms ease-in-out',
                    style({ width: 0, overflow: 'hidden' }))
            ]
        )
    ]
);
