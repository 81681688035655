import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '~environment';
import { MapLocation } from '~interfaces';
import { CountryCodeToIso } from '~enums';
import { GeolocationQueryService } from '~app/service/geolocation-query/geolocation-query.service';


@Injectable({
    providedIn: 'root',
})
export class LocationSearchService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly router: Router,
        private readonly geolocationQueryService: GeolocationQueryService,
    ) {}

    public getLocations$(searchTerm: string, location?: string): Observable<MapLocation[]> {
        const urlTree = this.router.createUrlTree(
            [
                'api.mapbox.com/geocoding/v5/mapbox.places/',
                `${searchTerm}.json`,
            ],
            {
                queryParams: {
                    country: [...Object.values(CountryCodeToIso)],
                    limit: '10',
                    access_token: environment.mapboxGlAccessToken,
                    types: ['address', 'place', 'postcode', 'poi'],
                    proximity: location ?? '19.503736,47.180086',
                },
            }
        );

        return this.httpClient
            .get<GeoJSON.FeatureCollection<GeoJSON.Geometry>>(
                'https:/' + urlTree.toString()
            )
            .pipe(
                map((geoJson) => {
                    return geoJson.features.map((feature) => {
                        const featureAsAny = feature as any;
                        const countryCodeISO = featureAsAny.context.find(
                            (context: any) => context.id.includes('country')
                        ).short_code;
                        const countryCode = Object.keys(CountryCodeToIso).find(
                            (key) =>
                                (CountryCodeToIso as any)[key].toLowerCase() ===
                                countryCodeISO
                        )!;
                        const city = featureAsAny.context.find((context: any) => context?.id?.includes('place'))?.text;
                        const postCode = featureAsAny.context.find((context: any) => context?.id?.includes('postcode'))?.text;
                        const region = featureAsAny.context.find((context: any) => context?.id?.includes('region'))?.text;
                        const street = featureAsAny?.text;
                        const ps_num = featureAsAny?.address;

                        return {
                            address: featureAsAny.place_name,
                            lon: featureAsAny.center[0],
                            lat: featureAsAny.center[1],
                            city,
                            street,
                            ps_num,
                            countryCode,
                            postCode,
                            region
                        } as MapLocation;
                    });
                })
            );
    }
}
