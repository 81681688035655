import { Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { LocationSearchService } from '~app/service/location-search/location-search.service';
import { MapLocation } from '~interfaces';


@Component({
    selector: 'app-location-search-input',
    templateUrl: './location-search-input.component.html',
    styleUrls: ['./location-search-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LocationSearchInputComponent),
            multi: true,
        },
    ],
})
export class LocationSearchInputComponent
    implements OnInit, OnDestroy, ControlValueAccessor
{
    @Input() public name: string | null = null;
    @Input() public id: string | null = null;

    @ViewChild('input') public input!: ElementRef;

    public inputFormControl!: FormControl;
    public options$!: Observable<MapLocation[]>;
    public inputChangeSubject = new Subject<string>();

    private _onChange: (newValue: any) => void = () => {};
    private _onTouched: () => void = () => {};
    private onDestroy$ = new Subject<void>();

    constructor(private locationSearchService: LocationSearchService) {}

    public ngOnInit(): void {
        this.inputFormControl = new FormControl(null);
        this._onChange = () => null;
        this._onTouched = () => null;

        this.options$ = this.inputFormControl.valueChanges.pipe(
            debounceTime(500),
            switchMap((searchTerm: string | MapLocation) => {
                if (!searchTerm) {
                    return of([]);
                }

                return this.locationSearchService.getLocations$(
                    typeof searchTerm === 'string'
                        ? searchTerm
                        : searchTerm?.address ?? ''
                );
            }),
            startWith([])
        );

        this.inputFormControl.valueChanges
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((selectedLocation) => {
                if (!selectedLocation) {
                    this._onChange(null);
                }

                if (typeof selectedLocation !== 'string') {
                    this._onChange(selectedLocation);
                }
            });
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.inputChangeSubject.complete();
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.inputFormControl.disable({
                emitEvent: false,
            });
        } else {
            this.inputFormControl.enable({
                emitEvent: true,
            });
        }
    }

    public writeValue(newValue: MapLocation | null): void {
        this.inputFormControl.setValue(newValue ?? null);
    }

    public onInput(event: any): void {
        this.inputChangeSubject.next(event.target.value);
    }

    public onBlur(): void {
        this._onTouched();
    }

    public focus(): void {
        this.input.nativeElement.focus();
    }

    public displayFn(option: MapLocation): string {
        return option?.address ?? '';
    }
}
