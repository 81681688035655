import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { AppInstallService } from '~app/service/app-install.service';
import { LanguageService } from '~app/service/language/language.service';
import { EAppTheme } from '~enums';
import { CoreQuery } from '~store/core/core.query';
import { CoreService } from '~store/core/core.service';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    @Output()
    public readonly toggleSideNav = new EventEmitter<void>();


    public readonly AppTheme = EAppTheme;

    constructor(
        public readonly languageService: LanguageService,
        public readonly appInstallService: AppInstallService,
        public readonly coreService: CoreService,
        public readonly coreQuery: CoreQuery
    ) {}

    public changeTheme(theme?: EAppTheme): void {
        if (!theme) {
            return;
        }

        const newTheme =
            theme === EAppTheme.light ? EAppTheme.dark : EAppTheme.light;
        this.coreService.changeTheme(newTheme);
    }
}
