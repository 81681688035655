import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { createSelector, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserState, UserStore } from './user.store';

@Injectable({
    providedIn: 'root',
})
export class UserQuery extends Query<UserState> {
    private selectState = (state: UserState) => state;

    private selectAccessToken = createSelector(
        this.selectState,
        (state) => state.accessToken
    );

    private selectUser = createSelector(
        this.selectState,
        (state) => state.user
    );

    private selectInitialUserHasBeenDetermined = createSelector(
        this.selectState,
        (state) => state.initialUserHasBeenDetermined
    );

    private selectIsLoggedIn = createSelector(
        this.selectUser,
        (user) => !!user
    );

    public allState$ = this.select();

    public accessToken$ = this.ngRxSelect$(this.selectAccessToken);

    public user$ = this.ngRxSelect$(this.selectUser);

    public isLoggedIn$ = this.ngRxSelect$(this.selectIsLoggedIn);

    public initialUserHasBeenDetermined$ = this.ngRxSelect$(
        this.selectInitialUserHasBeenDetermined
    );

    constructor(protected override store: UserStore) {
        super(store);
    }

    public get accessToken() {
        return this.ngRxSelect(this.selectAccessToken);
    }

    public get user() {
        return this.ngRxSelect(this.selectUser);
    }

    private ngRxSelect$<UserState, K>(
        mapFn: (state: UserState) => K
    ): Observable<K> {
        return this.allState$.pipe(select(mapFn as any));
    }

    private ngRxSelect<UserState, K>(mapFn: (state: UserState) => K): K {
        return mapFn(this.getValue() as any);
    }
}
