import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'userAvatarImage',
})
export class UserAvatarImagePipe implements PipeTransform {
    public transform(name: string): string {
        return `https://avatars.dicebear.com/api/initials/${name[0]}.svg`;
    }
}
