import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';

import { AuthInterceptor } from './auth-interceptor.service';
import { DateParserInterceptor } from './date-parser-interceptor.service';
import { MockApiInterceptor } from './mock-api-interceptor.service';

// ! Order matters - DO NOT RE-ORDER UNLESS YOU KNOW WHAT YOU ARE DOING!
export const CORE_INTERCEPTORS: Provider[] = [
    // {
    //     provide: HTTP_INTERCEPTORS,
    //     useClass: DateParserInterceptor,
    //     multi: true,
    // },
    // {
    //     provide: HTTP_INTERCEPTORS,
    //     useClass: MockApiInterceptor,
    //     multi: true,
    // },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: DateParserInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true,
    },
];
