import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '~core/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '~interfaces';
import { LoginComponent } from '~shared/component/login/login.component';
import { ReportDialogComponent } from "~core/report-dialog/report-dialog.component";
import { ReportDialogData } from "~shared/interfaces/report-dialog-data";


@Injectable({
    providedIn: 'root',
})
export class DialogService {
    /**
     * Construction.
     * @param dialog The mat dialog service.
     */
    constructor(protected readonly dialog: MatDialog) {}

    /**
     * Open the login dialog.
     */
    public openLoginDialog(): MatDialogRef<LoginComponent> {
        return this.dialog.open(LoginComponent, {disableClose: true});
    }

    public openConfirmDialog(
        confirmDialogData?: ConfirmDialogData
    ): MatDialogRef<ConfirmDialogComponent> {
        const dialogConfig = new MatDialogConfig();
        if (confirmDialogData) {
            dialogConfig.data = confirmDialogData;
        }
        return this.dialog.open(ConfirmDialogComponent, dialogConfig);
    }

    public openReportDialog(
        reportDialogData?: ReportDialogData
    ): MatDialogRef<ReportDialogComponent> {
        const dialogConfig = new MatDialogConfig();
        if (reportDialogData) {
            dialogConfig.data = reportDialogData;
            dialogConfig.maxWidth = '400px';
        }
        return this.dialog.open(ReportDialogComponent, dialogConfig);
    }

    /**
     * Close all dialog.
     */
    public closeAllDialog() {
        this.dialog.closeAll();
    }
}
