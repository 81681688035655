import { AsyncPipe } from '@angular/common';
import { Pipe } from '@angular/core';
import { isObservable, Observable, Subscribable } from 'rxjs';

@Pipe({
    name: 'safeAsync',
})
export class SafeAsyncPipe {
    constructor(private readonly asyncPipe: AsyncPipe) {}

    public transform<T>(
        value: Observable<any> | Subscribable<any> | Promise<T> | T
    ): T {
        return isObservable(value) || value instanceof Promise
            ? this.asyncPipe.transform(value)
            : (value as T);
    }
}
