import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ReportDialogData } from "~shared/interfaces/report-dialog-data";
import { INumberSelect, IReportTypeSelect, IStringSelect } from "~interfaces";
import { TranslateService } from "@ngx-translate/core";
import { ReportType } from "~enums";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SubscriptionHandlerMixin } from "@adroit-group/ng-utils";
import { GRecaptchaComponent } from "~shared/component/recaptcha/g-recaptcha.component";

export interface ControlConfig {
    label: string;
    type: string;
    icon: string;
    formControlName: string;
    options:
        | (IReportTypeSelect)[];
    isMultiSelect?: boolean;
}

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss']
})

export class ReportDialogComponent
    extends SubscriptionHandlerMixin()
    implements OnInit {
    @ViewChild('captcha') captcha?: GRecaptchaComponent;
    public shelterId!: number;
    public controlConfigTypeToken!: ControlConfig;
    public isReportFake = false;
    public reportTypeSelects: IReportTypeSelect[] = [
        { value: ReportType.FAKE_DATA, viewValue$: this.translateService.stream('report.fake') },
        { value: ReportType.UNAVAILABLE, viewValue$: this.translateService.stream('report.unavailable') },
        { value: ReportType.UNREACHABLE, viewValue$: this.translateService.stream('report.unreachable') },
    ];
    public readonly controlConfigsList = [
        {
            label: 'report.type',
            type: 'radio',
            formControlName: 'type',
            options: this.reportTypeSelects,
        }
    ];
  constructor(
      private formBuilder: FormBuilder,
      public dialogRef: MatDialogRef<ReportDialogComponent>,
      public dialog: MatDialog,
      public translateService: TranslateService,
      @Inject(MAT_DIALOG_DATA) data: ReportDialogData
  ) {
      super();
      this.shelterId = data.shelterId;
  }

    public reportFormGroup: FormGroup = this.formBuilder.group({
        type: new FormControl(''),
        reCaptcha: new FormControl('', [Validators.required])
    });

  ngOnInit(): void {
    this.continuouslySetReportType();
  }

  private continuouslySetReportType(): void {
      this.reportFormGroup.controls.type.valueChanges
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((reportType) => {
                if (reportType === ReportType.FAKE_DATA) {
                    this.appendReasonField();
                    this.isReportFake = true;
                } else {
                    this.isReportFake = false;
                    this.reportFormGroup.removeControl('text');
                }
          });
  }

  private appendReasonField(): void {
      this.reportFormGroup.registerControl('text', new FormControl('', Validators.required));
  }

}
