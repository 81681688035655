import { Provider } from '@angular/core';
import { APP_INSTALL_PROMPT_INITIALIZER } from './app-install-prompt.initializer';
import { COLOR_SCHEME_PREFERENCE_OBSERVER_INITIALIZER } from './color-scheme-preference-observer.initializer';
import { INIT_SERVICES_INITIALIZER } from './initializable.initializer';

export const APP_INITIALIZERS: Provider[] = [
    INIT_SERVICES_INITIALIZER,
    APP_INSTALL_PROMPT_INITIALIZER,
    COLOR_SCHEME_PREFERENCE_OBSERVER_INITIALIZER,
];
