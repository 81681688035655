import { Component, OnDestroy, OnInit } from "@angular/core";
import { ToggleButtonBarOption } from "../toggle-buttons-bar/toggle-button-bar-option.interface";
import { Subscription } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../../../store/user/user.service";
import { Router } from "@angular/router";
import { finalize } from "rxjs/operators";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    public loginButtonOptions: ToggleButtonBarOption[] = [
        {
            label: 'pages.register.label',
            value: 'register',
            link: '/register',
        },
        {
            label: 'pages.login.label',
            value: 'login',
            link: '/login',
        },
    ];
    isLogin: string = 'login';

    hide = true;

    public loginSubscription?: Subscription;
    public loginError: any;

    constructor(
        private formBuilder: FormBuilder,
        private readonly userService: UserService,
        private readonly router: Router,
        public dialogRef: MatDialogRef<LoginComponent>,
        public dialog: MatDialog,
    ) {}

    public loginFormGroup: FormGroup = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
    });

    public ngOnInit(): void {}

    public ngOnDestroy(): void {
        this.loginSubscription?.unsubscribe();
    }

    public logout(): void {
        this.dialogRef.close();
        this.userService.clearSession();
    }

    public onFormSubmit(): void {
        if (this.loginFormGroup.invalid) {
            this.loginFormGroup.markAllAsTouched();
            return;
        }

        if (this.loginSubscription) {
            return;
        }

        this.loginError = null;
        this.loginSubscription = this.userService
            .login({
                email: this.loginFormGroup.value.email,
                password: this.loginFormGroup.value.password,
            })
            .pipe(
                finalize(() => {
                    this.loginSubscription = undefined;
                })
            )
            .subscribe(
                () => {
                    this.dialogRef.close(true);
                },
                (error) => {
                    this.loginError = error;
                }
            );
    }

}
