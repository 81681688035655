import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export const expandAnimation: AnimationTriggerMetadata = trigger(
    'expandAnimation',
    [
        transition(
            ':enter',
            [
                style({ height: 0, overflow: 'hidden' }),
                animate('225ms ease-in-out',
                    style({ height: '*',  overflow: 'hidden' }))
            ]
        ),
        transition(
            ':leave',
            [
                style({ height: '*',  overflow: 'hidden' }),
                animate('225ms ease-in-out',
                    style({ height: 0, overflow: 'hidden' }))
            ]
        )
    ]
);
