/* eslint-disable @typescript-eslint/no-explicit-any */
import { Constructor, mixinBase, resolveMixinDependency } from '@adroit-group/ng-utils';
import { MixinType } from '@adroit-group/ng-utils/lib/types';
import { EAppTheme } from '~enums';
import { CoreQuery } from '~store/core/core.query';
import { CoreService } from '~store/core/core.service';



export function ThemeHandlerMixin<T extends Constructor<any, unknown[]>>(
    base?: T
) {
    const MediaObserver = class extends mixinBase(base) {
        public readonly coreService = resolveMixinDependency(CoreService);

        public readonly coreQuery = resolveMixinDependency(CoreQuery);

        public readonly AppTheme = EAppTheme;
    };

    return MediaObserver as MixinType<typeof MediaObserver, T>;
}
