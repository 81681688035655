<div *ngIf="!!(this.breakpointService.downSm$ | async)" class="filter-container mb-4">
    <div class="inner-container"
         [class.expanded]="isFiltersOpen">
        <div
            #filterToggleContainer
            class="w-100 d-flex justify-content-between align-items-center">
            <button
                class="d-flex justify-content-around align-items-center filter-toggle-button"
                (click)="isFiltersOpen = !isFiltersOpen"
            >
                <mat-icon> filter_list</mat-icon>

                <span class="primary-text mx-2">{{ 'filtering' | translate }}</span>

                <mat-icon class="primary-text">{{ isFiltersOpen ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
            </button>

            <button
                mat-icon-button
                (click)="showSearchBar = !showSearchBar"
                class="toggle-search-button"
                [ngClass]="{ 'alternate-bg': showSearchBar, 'themed-bg': !showSearchBar }"
            >
                <mat-icon>search</mat-icon>
            </button>
        </div>
        <app-shelter-filter
            *ngIf="isFiltersOpen"
            @expandAnimation
            (search)="onFilterFormValueChange($event); isFiltersOpen = false"
            (closeFilter)="isFiltersOpen = false"
            class="d-block"
        ></app-shelter-filter>
    </div>
</div>

<app-text-search
    *ngIf="showSearchBar && !!(this.breakpointService.downSm$ | async)"
    @expandAnimation
    [label]="'shelter-search-string' | translate"
    (search)="onSearch($event)"
></app-text-search>

<app-map
    *ngIf="mapShelters?.length && !(mapListingsLoading$ | async)"
    class="map mt-2"
    [markers]="mapShelters | invoke: getMarkersFromMapShelters"
    [userCoords]="geoLocationOfUser$ | async"
></app-map>
<div class="d-flex w-100 position-relative">
    <div *ngIf="!!(this.breakpointService.upMd$ | async)" class="h-100 top-0 mt-4"
         [ngClass]="{
             'position-absolute': !isDesktopFiltersOpen && !(revealAnimation$ | async),
             'position-sticky': isDesktopFiltersOpen || (revealAnimation$ | async)}">
        <div class="filter-container desktop me-4" [class.expanded]="isDesktopFiltersOpen">
            <div class="inner-container">
                <div
                    class="w-100 p-1 d-flex justify-content-between align-items-center">
                    <div
                        class="w-100 d-flex justify-content-between align-items-center"
                        matTooltip="{{ 'filtering' | translate }}"
                        matTooltipPosition="right"
                        [matTooltipDisabled]="isDesktopFiltersOpen">
                        <button
                            class="d-flex justify-content-around align-items-center filter-toggle-button"
                            (click)="isDesktopFiltersOpen = !isDesktopFiltersOpen"
                        >
                            <mat-icon> filter_list</mat-icon>
                        </button>
                    </div>

                </div>
                <div
                    *ngIf="isDesktopFiltersOpen"
                    class="expanded-container d-flex flex-column"
                    [@expandRevealAnimation]="!(revealAnimation$ | async)"
                    (@expandAnimation.start)="setAnimationState(true)"
                    (@expandAnimation.done)="setAnimationState(false)"
                >
                    <app-text-search
                        [label]="'shelter-search-string' | translate"
                        (search)="onSearch($event)"
                        class="mt-2"
                    ></app-text-search>
                    <app-shelter-filter
                        (search)="onFilterFormValueChange($event)"
                        (closeFilter)="onFilterFormValueChange()"
                        class="d-block"
                    ></app-shelter-filter>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="shelters; else loadingTpl">
        <div #locationContainer class="d-flex flex-column w-100">
            <div class="d-flex flex-wrap my-4" ngClass.gt-sm="justify-content-between">
                <div
                    class="d-flex mat-slide-toggle-container"
                    ngClass.lt-md="justify-content-start w-100"
                    ngClass.gt-sm="justify-content-end"
                    fxFlexOrder.gt-sm="2"
                    fxFlexORder.lt-md="1"
                >

                    <span class="order-md-1 order-1 align-self-center geo-warn">{{
                        'enable-geo-warn' | translate
                        }}</span>
                    <mat-slide-toggle
                        [checked]="!!(geoLocationOfUser$ | async)"
                        (change)="onLocationEnabledChange($event)"
                        class="order-md-2 order-2 ms-2 ms-md-2 me-md-2"
                        color="primary"
                    >
                    </mat-slide-toggle>
                </div>

                <div
                    *ngIf="shelters"
                    class="w-100 d-flex advertised-shelters-info fs-6"
                    ngClass.lt-md="justify-content-start"
                    ngClass.gt-sm="justify-content-end"
                    fxFlexOrder.gt-sm="3"
                    fxFlexORder.lt-md="2"
                >
                    <span
                    >{{ 'all-shelters-number' | translate }}&nbsp;{{
                        resultListLength
                        }}</span
                    >
                </div>

                <div
                    class="d-flex"
                    ngClass.lt-md="mt-4"
                    fxFlexOrder.gt-sm="1"
                    fxFlexORder.lt-md="3"
                >
                    <mat-icon class="me-2">home</mat-icon>
                    <span class="shelters-sub-title"
                    >{{ 'shelters' | translate }}:</span
                    >
                </div>
            </div>
            <div>
                <ng-container *ngIf="shelters?.length; else emptyTpl">
                    <ng-container *ngIf="!(loading$ | async); else loadingTpl">
                        <div class="shelter-card-container">
                            <div *ngFor="let shelter of shelters">
                                <app-shelter-card
                                    [shelter]="shelter"
                                    (favouriteClick)="addToFavourite(shelter)"
                                    class="shelter-card"
                                ></app-shelter-card>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row mt-3">
                        <mat-paginator
                            [length]="resultListLength"
                            [pageSize]="(currentPagination$ | async)!.pageSize"
                            [pageSizeOptions]="pageSizeOptions"
                            (page)="changePage($event)"
                        >
                        </mat-paginator>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>


<ng-template #loadingTpl>
    <div
        class="w-100 d-flex justify-content-center align-items-center"
        style="flex-wrap: wrap; text-align: center; height: 80vh"
    >
        <mat-spinner></mat-spinner>

        <h1 class="w-100">
            {{ 'loading-shelter-list' | translate }}
        </h1>
    </div>
</ng-template>

<ng-template #emptyTpl>
    <div
        class="w-100 d-flex justify-content-center align-items-center"
        style="flex-wrap: wrap; text-align: center"
    >
        <h1 class="w-100">
            {{ 'no-results' | translate }}
        </h1>

        <button
            *ngIf="false"
            type="button"
            (click)="reloadShelters()"
            class="btn btn-primary btn-contact btn-no-result"
        >
            {{ 'retry' | translate }}
        </button>
    </div>
</ng-template>
