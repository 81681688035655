import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Transfer } from '~models';
import { UserQuery } from '~store/user/user.query';
import { CoreQuery } from "~store/core/core.query";
import { EAppTheme } from "~enums";


@Component({
    selector: 'app-transfer-card',
    templateUrl: './transfer-card.component.html',
    styleUrls: ['./transfer-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransferCardComponent {
    @Input() public transfer!: Transfer;

    @Output() public readonly favouriteClick = new EventEmitter<void>();

    @ContentChild('footer') public customFooterTpl!: TemplateRef<any>;

    public readonly AppTheme = EAppTheme;

    constructor(public readonly userQuery: UserQuery,
                public readonly coreQuery: CoreQuery
    ) {}
}
