import { PlatformObserverService } from '@adroit-group/ng-utils';
import { ApplicationRef, Injectable, Optional } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { from, Observable, of, timer } from 'rxjs';
import { catchError, first, mapTo, switchMap, take, timeout } from 'rxjs/operators';


@Injectable({
    providedIn: 'root',
})
export class PwaService {
    constructor(
        @Optional()
        private readonly swUpdate: SwUpdate,
        private readonly appRef: ApplicationRef,
        private readonly platformObserver: PlatformObserverService
    ) {
        if (
            this.swUpdate &&
            this.swUpdate.isEnabled &&
            this.platformObserver.isPlatformBrowser
        ) {
            this.appRef.isStable
                .pipe(
                    first((isStable) => isStable),
                    switchMap(() => this.swUpdate.available)
                )
                .subscribe(() => {
                    this.swUpdate
                        .activateUpdate()
                        .then(() => this.platformObserver?.window?.document?.location?.reload());
                });
        }
    }

    public checkForUpdate(): Observable<boolean> {
        const waitFor = 1000;

        if (
            this.swUpdate &&
            this.swUpdate.isEnabled &&
            this.platformObserver.isPlatformBrowser
        ) {
            const available$ = this.swUpdate.available.pipe(
                mapTo(true),
                timeout(waitFor),
                catchError(() => of(false))
            );

            return from(
                Promise.race([
                    this.swUpdate.checkForUpdate(),
                    // ? There's a bug in local development, where the worker script doesnt get copied and sw registration fails, but is available is true.
                    // ? It makes this checkForUpdates promise hang indefinitely that's why we race it against a 5 sec timeout.
                    timer(1000).pipe(take(1)).toPromise(),
                ])
            ).pipe(switchMap(() => available$));
        }

        return timer(waitFor).pipe(mapTo(false));
    }
}
