<h2 mat-dialog-title>{{'report.title' | translate}}</h2>
<div class="mb-3">{{ 'report.description' | translate }}</div>
<div  *ngIf="isReportFake" class="warning-container mb-3">
    <p class="p-3">
        {{ 'report.warning' | translate }}
    </p>
</div>
<form
    [formGroup]="reportFormGroup">
    <div
        *ngFor="let controlConfig of controlConfigsList"
        class="d-flex flex-column"
    >
        <div [ngSwitch]="controlConfig.type" class="w-100">
            <ng-container *ngSwitchCase="'radio'">
                <ng-container
                    *ngTemplateOutlet="radioTemplate;
                         context: { $implicit: controlConfig }
                    "
                ></ng-container>
            </ng-container>
        </div>
    </div>

    <ng-template
        #radioTemplate
        [typedNgTemplate]="controlConfigTypeToken"
        let-controlConfig
    >
        <div class="d-flex flex-column justify-content-center">
            <mat-radio-group
                *ngLet="controlConfig.options | safeAsync as options"
                [formControlName]="controlConfig.formControlName"
                class="d-flex flex-column"
            >
                <mat-radio-button
                    *ngFor="let option of options; let isLast = last"
                    [value]="option.value"
                    color="primary"
                    class="report-radio-button"
                >
                    {{ option.viewValue$ | async }}
                </mat-radio-button>
            </mat-radio-group>

            <div *ngIf="isReportFake">
                <mat-form-field
                    *ngLet="reportFormGroup.controls.text as textControl"
                    class="report-text mt-3 w-100" appearance="outline">
                    <mat-label>{{ 'report.text' | translate }}</mat-label>
                    <textarea matInput formControlName="text" placeholder="{{'report.placeholder' | translate }}"></textarea>
                    <mat-error *ngIf="textControl?.touched && textControl?.errors?.required">
                        {{ 'report.text-required' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        <div class="w-100 d-flex justify-content-center mt-3">
            <app-recaptcha
                #captcha
                [parentFormGroup]="reportFormGroup"
            ></app-recaptcha>
        </div>
        </div>
    </ng-template>
</form>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">{{ 'cancel' | translate}}</button>
    <button mat-button color="warn" [disabled]="!reportFormGroup.valid" [mat-dialog-close]="reportFormGroup.value" (click)="captcha?.reset()">{{ 'report-label' | translate}}</button>
</mat-dialog-actions>
