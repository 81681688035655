/* eslint-disable @angular-eslint/no-output-native */
/* eslint-disable @angular-eslint/no-output-rename */
import {
    animate,
    animateChild,
    query,
    style,
    transition,
    trigger,
} from '@angular/animations';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
} from '@angular/core';
import { PwaService } from 'app/service/pwa.service';

@Component({
    selector: 'app-splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.scss'],
    animations: [
        trigger('fadeOut', [
            transition(':leave', [
                query(':leave', animateChild(), { optional: true }),
                animate(300, style({ opacity: 0 })),
            ]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplashScreenComponent implements OnInit {
    @Output('show')
    public readonly showEvent = new EventEmitter<boolean>();

    public show = true;

    public message = '';

    constructor(
        private readonly pwaService: PwaService,
        private readonly cdr: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        this.showEvent.emit(this.show);

        this.pwaService.checkForUpdate().subscribe((result) => {
            this.show = result;
            this.showEvent.emit(this.show);
            this.cdr.detectChanges();
        });
    }
}
