<div class="transfer-card">
    <app-transfer-card
        [transfer]="loadedTransfer!"
        *ngIf="loadedTransfer; else loadOrError"
        (favouriteClick)="addToFavourite(loadedTransfer)"
    ></app-transfer-card>
</div>

<ng-template #loadOrError>
    <div class="transfer-card">
        <mat-card class="d-flex flex-column justify-content-center align-items-center"
                  *ngIf="loadInProgress || loadError">
            <mat-spinner
                [diameter]="30"
                *ngIf="loadInProgress; else error"
            ></mat-spinner>

            <ng-template #error>
                {{ 'error-occured' | translate }}
                <button
                    class="mt-2 btn btn-primary btn-contact btn-no-result"
                    type="button"
                    (click)="attemptToLoadTransfer()"
                >
                    {{ 'retry' | translate }}
                </button>
            </ng-template>
        </mat-card>
    </div>
</ng-template>
