import { of } from "rxjs";
import { INumberSelect } from "~interfaces";

export const NUMBER_SELECT_OPTIONS: INumberSelect[] = [
    { value: 2, viewValue$: of('> 1') },
    { value: 3, viewValue$: of('> 2') },
    { value: 4, viewValue$: of('> 3') },
    { value: 5, viewValue$: of('> 4') },
    { value: 6, viewValue$: of('> 5') },
];
