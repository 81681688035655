<h2 mat-dialog-title>{{'session_expired' | translate}}</h2>
<mat-dialog-content class="mat-typography">
    <div class="mx-auto fs-6 text-dark text-center">
        {{ 'session_expired_warning' | translate }}
    </div>
    <form
        [formGroup]="loginFormGroup"
        class="form d-flex flex-column mt-34px background-input"
        (ngSubmit)="onFormSubmit()"
    >
        <mat-form-field appearance="outline" class="me-3 ms-3">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" required />
            <mat-error *ngIf="loginFormGroup.get('email')!.errors?.required">{{
                'email-is-required' | translate
                }}</mat-error>
            <mat-error *ngIf="loginFormGroup.get('email')!.errors?.email">{{
                'email-is-invalid' | translate
                }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="me-3 ms-3">
            <mat-label>{{ 'password' | translate }}</mat-label>
            <input
                matInput
                formControlName="password"
                [type]="hide ? 'password' : 'text'"
            />
            <button type="button" mat-icon-button matSuffix (click)="hide = !hide">
                <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <mat-error *ngIf="loginFormGroup.get('password')!.invalid">{{
                'password-is-required' | translate
                }}</mat-error>
        </mat-form-field>
        <mat-error *ngIf="loginError">{{
            'invalid-credentials' | translate
            }}</mat-error>

        <button
            class="login-button d-flex align-items-center justify-content-center mt-24px"
            mat-raised-button
            color="primary"
        >
            <ng-container *ngIf="!loginSubscription; else inProgress">
                {{ 'login' | translate }}
            </ng-container>
            <ng-template #inProgress>
                <mat-spinner [diameter]="30"></mat-spinner>
            </ng-template>
        </button>
    </form>
    <button
        class="login-button d-flex align-items-center justify-content-center mt-3 mb-2 w-100"
        mat-raised-button
        color="accent"
        (click)="logout()"
    >
            {{ 'back_to_homepage' | translate }}
    </button>
</mat-dialog-content>
