import { AdroitNgUtilsModule } from '@adroit-group/ng-utils';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { LocationSearchInputComponent } from './component/location-search-input/location-search-input.component';
import { MapComponent } from './component/map/map.component';
import { MarkerComponent } from './component/marker/marker.component';
import { PositionerOverlayComponent } from './component/positioner-overlay/positioner-overlay.component';
import { ShelterCardComponent } from './component/shelter-card/shelter-card.component';
import { ShelterFilterComponent } from './component/shelter-filter/shelter-filter.component';
import { TextSearchComponent } from './component/text-search/text-search.component';
import { ToggleButtonsBarComponent } from './component/toggle-buttons-bar/toggle-buttons-bar.component';
import { TransferCardComponent } from './component/transfer-card/transfer-card.component';
import { TransferFilterComponent } from './component/transfer-filter/transfer-filter.component';
import { WidthObserverDirective } from './directive/width-observer/width-observer.directive';
import { ContactLinkPipe } from './pipes/contact-link.pipe';
import { CountryTranslatePipe } from './pipes/country-translate.pipe';
import { DayjsDatePipe } from './pipes/dayjs-date.pipe';
import { LanguageTranslatePipe } from './pipes/language-translate.pipe';
import { ShelterCardLoaderComponent } from './component/shelter-card-loader/shelter-card-loader.component';
import { LoginComponent } from './component/login/login.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { TypedNgTemplateDirective } from './directive/typed-ng-template.directive';
import { FindPipe } from './pipes/find.pipe';
import { UserAvatarImagePipe } from './pipes/user-avatar-image.pipe';
import { WeekdayAndHourPickerComponent } from './component/weekday-and-hour-picker/weekday-and-hour-picker.component';
import { SafeAsyncPipe } from './pipes/safe-async.pipe';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DayIntervalNamePipe } from './pipes/day-interval-name.pipe';
import { WeekdayAndHourDisplayComponent } from './component/weekday-and-hour-display/weekday-and-hour-display.component';
import { MatDividerModule } from '@angular/material/divider';
import { TransferCardLoaderComponent } from '~shared/component/transfer-card-loader/transfer-card-loader.component';

@NgModule({
    declarations: [
        MapComponent,
        MarkerComponent,
        WidthObserverDirective,
        LocationSearchInputComponent,
        ToggleButtonsBarComponent,
        ContactLinkPipe,
        ShelterFilterComponent,
        CountryTranslatePipe,
        LanguageTranslatePipe,
        PositionerOverlayComponent,
        ShelterCardComponent,
        TextSearchComponent,
        TransferCardComponent,
        TransferFilterComponent,
        DayjsDatePipe,
        ShelterCardLoaderComponent,
        LoginComponent,
        TypedNgTemplateDirective,
        FindPipe,
        UserAvatarImagePipe,
        WeekdayAndHourPickerComponent,
        SafeAsyncPipe,
        DayIntervalNamePipe,
        WeekdayAndHourDisplayComponent,
        TransferCardLoaderComponent
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatIconModule,
        TranslateModule,
        AdroitNgUtilsModule,
        RouterModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSnackBarModule,
        MatTooltipModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        OverlayModule,
        PortalModule,
        MatDialogModule,
        MatDividerModule,
        ClipboardModule
    ],
    providers: [DatePipe, AsyncPipe],
    exports: [
        CommonModule,
        AdroitNgUtilsModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        MapComponent,
        MarkerComponent,
        WidthObserverDirective,
        LocationSearchInputComponent,
        ToggleButtonsBarComponent,
        MatInputModule,
        TranslateModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatCardModule,
        ContactLinkPipe,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatSnackBarModule,
        MatTooltipModule,
        ShelterFilterComponent,
        CountryTranslatePipe,
        LanguageTranslatePipe,
        PositionerOverlayComponent,
        ShelterCardComponent,
        TextSearchComponent,
        MatIconModule,
        RecaptchaModule,
        MatRadioModule,
        RecaptchaFormsModule,
        TypedNgTemplateDirective,
        FindPipe,
        UserAvatarImagePipe,
        TransferCardComponent,
        TransferFilterComponent,
        DayjsDatePipe,
        WeekdayAndHourPickerComponent,
        SafeAsyncPipe,
        ClipboardModule,
        DayIntervalNamePipe,
        WeekdayAndHourDisplayComponent,
        MatDividerModule
    ],
})
export class SharedModule {}
