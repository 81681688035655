import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges
} from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SheltersService } from '~app/service/shelters/shelters.service';
import { Listing, Transfer } from '~models';
import { TransfersService } from '~app/service/transfers/transfers.service';


@Component({
    selector: 'app-transfer-card-loader',
    templateUrl: './transfer-card-loader.component.html',
    styleUrls: ['./transfer-card-loader.component.scss'],
})
export class TransferCardLoaderComponent implements OnChanges, OnDestroy {
    @Input() transferId!: number;
    @Input() transfer?: Transfer;
    @Output() cardLoad = new EventEmitter<void>();

    public loadedTransfer: Transfer | null = null;
    public loadError: any | null = null;
    public loadInProgress = false;

    private loadTransferSubscription?: Subscription;

    constructor(
        private readonly transferService: TransfersService,
        private readonly cdr: ChangeDetectorRef
    ) {}

    attemptToLoadTransfer(): void {
        this.loadedTransfer = null;
        this.loadError = null;
        this.loadInProgress = true;
        this.loadTransferSubscription?.unsubscribe();

        if (this.transfer) {
            this.loadedTransfer = this.transfer;
            return;
        }

        this.loadTransferSubscription = this.transferService
            .getTransfer(this.transferId)
            .pipe(
                finalize(() => {
                    this.loadInProgress = false;
                })
            )
            .subscribe({
                next: (result) => {
                    this.loadedTransfer = result;
                    this.cdr.detectChanges();
                    this.cardLoad.emit();
                },
                error: (error) => {
                    this.loadError = error;
                },
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.transferId || changes.transfer) {
            this.attemptToLoadTransfer();
        }
    }

    ngOnDestroy(): void {
        this.loadTransferSubscription?.unsubscribe();
    }

    public addToFavourite(transfer: Transfer): void {
        transfer.favourite = +!transfer.favourite;

        const favoriteTransfers: number[] = JSON.parse(
            localStorage.getItem('favorite-transfers') ?? '[]'
        );

        favoriteTransfers.includes(transfer.id)
            ? favoriteTransfers.splice(
                favoriteTransfers.findIndex((favId) => favId === transfer.id),
                1
            )
            : favoriteTransfers.push(transfer.id);

        localStorage.setItem(
            'favorite-transfers',
            JSON.stringify(favoriteTransfers)
        );
    }
}
