import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '~environment';
import { Language } from '~interfaces';


@Injectable({
    providedIn: 'root',
})
export class SpokenLanguageService {
    private readonly languagesSubject = new ReplaySubject<Language[]>(1);

    constructor(private readonly httpClient: HttpClient) {}

    getLanguages$(): Observable<Language[]> {
        return this.languagesSubject.asObservable();
    }

    loadLanguages(): void {
        this.httpClient
            .get<Language[]>(`${environment.apiBase}/api/languages`)
            .subscribe((languages) => {
                if (
                    typeof languages === 'object' &&
                    !Array.isArray(languages)
                ) {
                    this.languagesSubject.next(Object.values(languages));
                } else {
                    this.languagesSubject.next(languages);
                }
            });
    }
}
