import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
    name: 'dayjsDate',
})
export class DayjsDatePipe implements PipeTransform {
    constructor(private readonly datePipe: DatePipe) {}

    public transform(
        value: string | number | Date | dayjs.Dayjs | null,
        format?: string,
        timezone?: string,
        locale?: string
    ): string | null {
        if (!value) {
            return '';
        }

        return this.datePipe.transform(
            dayjs(value).toDate(),
            format,
            timezone,
            locale
        );
    }
}
