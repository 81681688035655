import {
    ConnectedPosition,
    Overlay,
    OverlayConfig,
    OverlayRef,
    PositionStrategy
} from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-positioner-overlay',
    templateUrl: './positioner-overlay.component.html',
    styleUrls: ['./positioner-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PositionerOverlayComponent
    implements OnChanges, AfterViewInit, OnDestroy
{
    @Input() public connectedContainerElement?: HTMLElement;

    @Input() public position:
        | 'top-left'
        | 'top-center'
        | 'bottom-left'
        | 'bottom-center'
        | 'center' = 'center';

    @Input() public originX?: ConnectedPosition['originX'];

    @Input() public originY?: ConnectedPosition['originY'];

    @Input() public overlayX?: ConnectedPosition['overlayX'];

    @Input() public overlayY?: ConnectedPosition['overlayY'];

    @Input() public width?: number | string = 'auto';

    @Input() public height?: number | string = 'auto';

    @Input() public minWidth?: number | string;

    @Input() public minHeight?: number | string;

    @Input() public maxWidth?: number | string;

    @Input() public maxHeight?: number | string;

    @Input() public offsetX?: number;

    @Input() public offsetY?: number;

    @Input() public connectedPositions?: ConnectedPosition[];

    @Input() public withBackdrop = false;

    @Input() public withDefaultBackdropClass = false;

    @Input() public overlayConfig?: OverlayConfig;

    @Output() public backdropClick = new EventEmitter<MouseEvent>();

    @Output() public outsideClick = new EventEmitter<MouseEvent>();

    @Output() public overlayKeydown = new EventEmitter<KeyboardEvent>();

    @Output() public detachments = new EventEmitter<void>();

    @ViewChild(CdkPortal)
    private portal!: CdkPortal;

    private overlayRef!: OverlayRef;

    private onDestroy$ = new Subject<void>();

    constructor(private readonly overlay: Overlay) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.overlayConfig) {
            if (this.overlayRef) {
                this.updateOverlayConfig();
            }
        }
    }

    public updatePosition(): void {
        if (this.overlayRef) {
            this.overlayRef.updatePosition();
        }
    }

    public ngAfterViewInit(): void {
        const overlayConfig = this.getOverlayConfig();

        this.overlayRef = this.overlay.create(overlayConfig);

        this.overlayRef
            .keydownEvents()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((keydownEvent) => {
                this.overlayKeydown.emit(keydownEvent);
            });

        if (overlayConfig.hasBackdrop) {
            this.overlayRef
                .backdropClick()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((event) => {
                    this.backdropClick.emit(event);
                });
        } else {
            this.overlayRef
                .outsidePointerEvents()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((event) => {
                    this.outsideClick.emit(event);
                });
        }

        this.overlayRef
            .detachments()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
                this.detachments.emit();
            });

        this.overlayRef.attach(this.portal);
    }

    public updateOverlayConfig(): void {
        const overlayConfig = this.getOverlayConfig();

        if (overlayConfig.positionStrategy) {
            this.overlayRef.updatePositionStrategy(
                overlayConfig.positionStrategy
            );
        }

        if (overlayConfig.scrollStrategy) {
            this.overlayRef.updateScrollStrategy(overlayConfig.scrollStrategy);
        }

        this.overlayRef.updateSize({
            height: overlayConfig.height,
            width: overlayConfig.width,
            maxHeight: overlayConfig.maxHeight,
            maxWidth: overlayConfig.maxWidth,
            minHeight: overlayConfig.minHeight,
            minWidth: overlayConfig.minWidth,
        });

        this.overlayRef.updatePosition();
    }

    public ngOnDestroy(): void {
        this.overlayRef.detach();
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    private getOverlayConfig(): OverlayConfig {
        const overlaySizeConfig = this.getOverlaySizeConfig();

        const overlayConfig: OverlayConfig = this.overlayConfig ?? {
            hasBackdrop: this.withBackdrop,
            positionStrategy: this.connectedContainerElement
                ? this.getPositionStrategyForConnected()
                : this.getPositionStrategyForGlobal(),
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
            ...overlaySizeConfig,
        };

        if (!this.withDefaultBackdropClass && !this.overlayConfig) {
            overlayConfig.backdropClass = 'positioner-overlay-backdrop';
        }

        return overlayConfig;
    }

    private getOverlaySizeConfig(): Partial<OverlayConfig> {
        const overlaySizeConfig: Partial<OverlayConfig> = {
            width: this.width,
            height: this.height,
        };

        if (typeof this.minWidth !== 'undefined') {
            overlaySizeConfig.minWidth = this.minWidth;
        }

        if (typeof this.minHeight !== 'undefined') {
            overlaySizeConfig.minHeight = this.minHeight;
        }

        if (typeof this.maxWidth !== 'undefined') {
            overlaySizeConfig.maxWidth = this.maxWidth;
        }

        if (typeof this.maxHeight !== 'undefined') {
            overlaySizeConfig.maxHeight = this.maxHeight;
        }

        return overlaySizeConfig;
    }

    private getPositionStrategyForConnected(): PositionStrategy {
        let connectedPositions: ConnectedPosition[];

        if (this.connectedPositions) {
            connectedPositions = this.connectedPositions;
        } else {
            if (
                this.originX &&
                this.originY &&
                this.overlayX &&
                this.overlayY
            ) {
                connectedPositions = [
                    {
                        originX: this.originX,
                        originY: this.originY,
                        overlayX: this.overlayX,
                        overlayY: this.overlayY,
                    },
                ];
            } else {
                switch (this.position) {
                    case 'top-left':
                        connectedPositions = [
                            {
                                originX: 'start',
                                originY: 'top',
                                overlayX: 'start',
                                overlayY: 'bottom',
                            },
                        ];
                        break;
                    case 'top-center':
                        connectedPositions = [
                            {
                                originX: 'center',
                                originY: 'top',
                                overlayX: 'center',
                                overlayY: 'bottom',
                            },
                        ];
                        break;
                    case 'top-left':
                        connectedPositions = [
                            {
                                originX: 'start',
                                originY: 'top',
                                overlayX: 'start',
                                overlayY: 'bottom',
                            },
                        ];
                        break;
                    case 'bottom-left':
                        connectedPositions = [
                            {
                                originX: 'start',
                                originY: 'bottom',
                                overlayX: 'start',
                                overlayY: 'top',
                            },
                        ];
                        break;
                    case 'bottom-center':
                        connectedPositions = [
                            {
                                originX: 'center',
                                originY: 'bottom',
                                overlayX: 'center',
                                overlayY: 'top',
                            },
                        ];
                        break;
                    default:
                        connectedPositions = [
                            {
                                originX: 'center',
                                originY: 'center',
                                overlayX: 'center',
                                overlayY: 'center',
                            },
                        ];
                        break;
                }
            }

            if (typeof this.offsetX !== 'undefined') {
                connectedPositions[0].offsetX = this.offsetX;
            }

            if (typeof this.offsetY !== 'undefined') {
                connectedPositions[0].offsetY = this.offsetY;
            }
        }

        return this.overlay
            .position()
            .flexibleConnectedTo(this.connectedContainerElement!)
            .withPositions(connectedPositions);
    }

    private getPositionStrategyForGlobal(): PositionStrategy {
        if (this.position === 'top-left') {
            return this.overlay.position().global().left().top();
        } else {
            return this.overlay
                .position()
                .global()
                .centerHorizontally()
                .centerVertically();
        }
    }
}
