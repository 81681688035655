import { APP_INITIALIZER, Provider } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { EAppTheme, EColoSchemePreference } from '~enums';
import { CoreQuery } from '~store/core/core.query';
import { CoreService } from '~store/core/core.service';

import { ColorSchemePreferenceService } from '../color-scheme-preference.service';

export const COLOR_SCHEME_PREFERENCE_OBSERVER_INITIALIZER: Provider = {
    provide: APP_INITIALIZER,
    useFactory:
        (
            colorSchemePreferenceService: ColorSchemePreferenceService,
            coreService: CoreService,
            coreQuery: CoreQuery
        ) =>
        (): Subscription =>
            colorSchemePreferenceService.preference$
                .pipe(
                    filter(
                        (preference) =>
                            preference &&
                            preference !== EColoSchemePreference.Unknown
                    ),
                    map((preference) => ({
                        theme:
                            preference === EColoSchemePreference.Dark
                                ? EAppTheme.dark
                                : EAppTheme.light,
                        colorPreference: preference,
                    })),
                    tap(({ theme, colorPreference }) => {
                        const currentTheme = coreQuery.appTheme;
                        if (!currentTheme) {
                            coreService.changeTheme(theme);
                        }

                        const currentColorPReference =
                            coreQuery.colorSchemePreference;
                        if (
                            !currentColorPReference ||
                            currentColorPReference !== colorPreference
                        ) {
                            coreService.changeColorPreference(colorPreference);
                        }
                    })
                )
                .subscribe(),
    deps: [ColorSchemePreferenceService, CoreService, CoreQuery],
    multi: true,
};
