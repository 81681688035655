import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared.module';
import { MyDataFormComponent } from './my-data-form.component';
import { GRecaptchaComponent } from '../recaptcha/g-recaptcha.component';

@NgModule({
    imports: [SharedModule],
    declarations: [MyDataFormComponent, GRecaptchaComponent],
  exports: [MyDataFormComponent, GRecaptchaComponent]
})
export class MyDataFormModule {}
