import { Injectable, Renderer2 } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class RendererManagerService {
    public get renderer(): Renderer2 {
        return this._renderer;
    }

    private _renderer!: Renderer2;

    public register(renderer: Renderer2): void {
        this._renderer = renderer;
    }
}
