<div
    class="content"
    *ngLet="options | invoke: getSlidingBackgroundWidth as optionWidth"
>
    <div
        class="sliding-background"
        [style.width]="optionWidth"
        [style.transform]="
            'translateX(' +
            (options | invoke: getSelectedOptionIndex:selectedOptionValue) *
                100 +
            '%)'
        "
    ></div>
    <div
        [style.width]="optionWidth"
        class="d-flex align-items-center option"
        *ngFor="let option of options"
    >
        <a
            *ngIf="!option.link; else hasLink"
            [class.active]="option.value === selectedOptionValue"
            (click)="onOptionSelect(option.value)"
        >
            <!-- If need icon
            <mat-icon *ngIf="option.icon" [svgIcon]="option.icon"></mat-icon>
            <div class="spacer" *ngIf="option.icon && option.label$ | async"></div> -->
            <span *ngIf="option.label">
                {{ option.label | translate }}
            </span>
        </a>
        <ng-template #hasLink>
            <a
                [routerLink]="[option.link]"
                [class.active]="option.value === selectedOptionValue"
            >
                <!-- If need icon
                <mat-icon *ngIf="option.icon" [svgIcon]="option.icon"></mat-icon>
                <div class="spacer" *ngIf="option.icon && option.label$ | async"></div> -->
                <span *ngIf="option.label">
                    {{ option.label | translate }}
                </span>
            </a>
        </ng-template>
    </div>
</div>
