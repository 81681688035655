<mat-form-field appearance="fill">
    <mat-icon matPrefix>search</mat-icon>

    <input matInput type="text" [formControl]="searchControl" [attr.placeholder]="label">

    <button *ngIf="searchControl.value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="searchControl.setValue('')">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>
