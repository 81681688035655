import { MediaMatcher } from '@angular/cdk/layout';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { Observable, BehaviorSubject, fromEvent, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EColoSchemePreference } from '../shared/enums';

@Injectable({
    providedIn: 'root',
})
export class ColorSchemePreferenceService {
    public get prefersLightMode$(): Observable<boolean> {
        if (this._isLightModePreferred$) {
            return this._isLightModePreferred$.asObservable();
        }

        this._isLightModePreferred$ = new BehaviorSubject<boolean>(false);
        this._lightQueryList = this.media.matchMedia(
            '(prefers-color-scheme: light)'
        );
        this._lightQueryList.addEventListener('change', (event) =>
            this._isLightModePreferred$.next(event.matches)
        );
        return this._isLightModePreferred$.asObservable();
    }

    public get prefersDarkMode$(): Observable<boolean> {
        if (this._isDarkModePreferred$) {
            return this._isDarkModePreferred$.asObservable();
        }

        this._isDarkModePreferred$ = new BehaviorSubject<boolean>(false);
        this._darkQueryList = this.media.matchMedia(
            '(prefers-color-scheme: dark)'
        );
        this._darkQueryList.addEventListener('change', (event) =>
            this._isDarkModePreferred$.next(event.matches)
        );
        return this._isDarkModePreferred$.asObservable();
    }

    public get preference(): EColoSchemePreference {
        if (this.hasNoSupport) {
            return EColoSchemePreference.Unknown;
        }

        const isDarkMode = this.media.matchMedia(
            '(prefers-color-scheme: dark)'
        ).matches;
        if (isDarkMode) {
            return EColoSchemePreference.Dark;
        }

        const isLightMode = this.media.matchMedia(
            '(prefers-color-scheme: light)'
        ).matches;
        if (isLightMode) {
            return EColoSchemePreference.Light;
        }

        return EColoSchemePreference.Unknown;
    }

    public get preference$(): Observable<EColoSchemePreference> {
        if (this._preference$) {
            return this._preference$.asObservable();
        }

        this._preference$ = new BehaviorSubject<EColoSchemePreference>(
            this.preference
        );

        const darkQuery = this.media.matchMedia('(prefers-color-scheme: dark)');
        const prefersDark$ = fromEvent<MediaQueryListEvent>(
            darkQuery,
            'change'
        ).pipe(map((event) => event.matches));

        const lightQuery = this.media.matchMedia(
            '(prefers-color-scheme: light)'
        );
        const prefersLight$ = fromEvent<MediaQueryListEvent>(
            lightQuery,
            'change'
        ).pipe(map((event) => event.matches));

        combineLatest([prefersDark$, prefersLight$])
            .pipe(
                tap(([dark, light]) => {
                    if (dark) {
                        this._preference$.next(EColoSchemePreference.Dark);
                    }

                    if (light) {
                        this._preference$.next(EColoSchemePreference.Light);
                    }

                    this._preference$.next(EColoSchemePreference.Unknown);
                })
            )
            .subscribe();

        return this._preference$.asObservable();
    }

    public get hasNoSupport(): boolean {
        return !this.window || !('matchMedia' in this.window);
    }

    private _lightQueryList!: MediaQueryList;
    private _darkQueryList!: MediaQueryList;
    private _preference$!: BehaviorSubject<EColoSchemePreference>;

    private _isLightModePreferred$!: BehaviorSubject<boolean>;
    private _isDarkModePreferred$!: BehaviorSubject<boolean>;

    constructor(
        @Inject(WINDOW) private readonly window: Window,
        private readonly media: MediaMatcher
    ) {}
}
