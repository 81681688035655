import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { RendererManagerService } from 'app/service/renderer-manager.service';
import { EColoSchemePreference } from '~enums';

import { EAppTheme } from '~enums';
import { CoreStore } from './core.store';
import { CoreQuery } from './core.query';

@Injectable({
    providedIn: 'root',
})
export class CoreService {
    constructor(
        private readonly coreStore: CoreStore,
        private readonly coreQuery: CoreQuery,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly rendererManager: RendererManagerService
    ) {
        this.applyTheme(this.coreQuery.appTheme);
    }

    public changeTheme(theme: EAppTheme): void {
        this.coreStore.update({ theme });
        this.applyTheme(theme);
    }

    public changeColorPreference(colorPreference: EColoSchemePreference): void {
        this.coreStore.update({ colorPreference });
    }

    private async applyTheme(theme: EAppTheme): Promise<void> {
        const isDarkTheme = theme === EAppTheme.dark;
        const body = this.document && this.document.body;
        if (!body) {
            return;
        }

        const renderer = await timer(0, 100)
            .pipe(
                map(() => this.rendererManager.renderer),
                filter((renderer) => !!renderer),
                take(1)
            )
            .toPromise();

        if (isDarkTheme) {
            renderer.addClass(body, 'dark-theme');
            renderer.removeClass(body, 'light-theme');
        } else {
            renderer.addClass(body, 'light-theme');
            renderer.removeClass(body, 'dark-theme');
        }
    }
}
