import { Pipe, PipeTransform } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { EWeekday } from '~enums';

@Pipe({
    name: 'dayIntervalName',
})
export class DayIntervalNamePipe implements PipeTransform {
    private intervalSize = 4;

    constructor(private readonly translate: TranslateService) {}

    public transform({
        day,
        interval,
    }: {
        day: number;
        interval: number;
    }): Observable<string> {
        return combineLatest([
            this.translate.stream(
                `weekdays.${Object.entries(EWeekday)
                    .find(([, value]) => value === day)?.[0]
                    .toLowerCase()}`
            ),
            of(this.getIntervalLabel(interval)),
        ]).pipe(map(([dayName, intervalName]) => `${dayName} ${intervalName}`));
    }

    private getIntervalLabel(interValNum: number): string {
        const start = interValNum * this.intervalSize;
        const end = (interValNum + 1) * this.intervalSize;

        return `${start} - ${end}`;
    }
}
