import { IInitializable } from '@adroit-group/ng-utils/lib/interfaces';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { INITIALIZABLE } from 'app/shared/token/initializable';


export const INIT_SERVICES_INITIALIZER: Provider = {
    provide: APP_INITIALIZER,
    useFactory: (initializables?: IInitializable[]) => (): void =>
        (initializables ?? []).forEach((initializable) => initializable.init()),
    deps: [INITIALIZABLE],
    multi: true,
};
