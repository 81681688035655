<div class="shelter-card">
    <app-shelter-card
        [shelter]="loadedShelter!"
        *ngIf="loadedShelter; else loadOrError"
        (favouriteClick)="addToFavourite(loadedShelter)"
    ></app-shelter-card>
</div>

<ng-template #loadOrError>
    <div class="shelter-card">
    <mat-card class="d-flex flex-column justify-content-center align-items-center" *ngIf="loadInProgress || loadError">
        <mat-spinner
            [diameter]="30"
            *ngIf="loadInProgress; else error"
        ></mat-spinner>

        <ng-template #error>
            {{ 'error-occured' | translate }}
            <button
                class="mt-2 btn btn-primary btn-contact btn-no-result"
                type="button"
                (click)="attemptToLoadShelter()"
            >
                {{ 'retry' | translate }}
            </button>
        </ng-template>
    </mat-card>
    </div>
</ng-template>
