import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { Map, Marker } from 'mapbox-gl';
import { fromEvent, Subscription } from 'rxjs';
import { Coordinate } from '~interfaces';
import { MAP_TOKEN } from '~shared/token/map.token';

import { MapComponent } from '../map/map.component';



@Component({
    selector: 'app-marker',
    template: '',
    styleUrls: ['./marker.component.scss'],
})
export class MarkerComponent implements OnChanges, OnDestroy {
    @Input() public coords!: Coordinate;
    @Input() public userPin!: boolean;

    @Output() public readonly markerClick = new EventEmitter<HTMLElement>();

    private map!: Map;
    private marker!: Marker;
    private markerSubscriptions!: Subscription;

    constructor(
        @Inject(MAP_TOKEN) private readonly mapComponent: MapComponent
    ) {}

    public ngOnChanges(): void {
        if (!this.map) {
            this.map = this.mapComponent.map;
        }
        this.removeExistingMarker();
        this.placeMarker();
    }

    public ngOnDestroy(): void {
        this.removeExistingMarker();
    }

    private placeMarker(): void {
        const element = this.userPin
            ? this.getUserPinElement()
            : this.getNormalPinElement();

        this.markerSubscriptions = new Subscription();

        this.markerSubscriptions.add(
            fromEvent(element, 'touchstart').subscribe((event: Event) => {
                //@ts-ignore
                event['fromMarker'] = true;
            })
        );

        this.marker = new this.mapComponent.Marker({
            element,
            anchor: 'bottom',
            scale: 1,
        })
            .setLngLat({
                lat: this.coords.lat,
                lon: this.coords.lon,
            })
            .addTo(this.map);

        this.markerSubscriptions.add(
            fromEvent(element, 'click').subscribe((event: Event) => {
                this.markerClick.emit(element);
            })
        );
    }

    private removeExistingMarker(): void {
        this.markerSubscriptions?.unsubscribe();
        this.marker?.remove();
    }

    private getNormalPinElement(): HTMLElement {
        const div = document.createElement('div');
        div.innerHTML = `<svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M42.2957 20.0422C42.2957 34.0717 24.2577 46.097 24.2577 46.097C24.2577 46.097 6.21973 34.0717 6.21973 20.0422C6.21973 15.2582 8.12015 10.6702 11.5029 7.28741C14.8857 3.90464 19.4737 2.00421 24.2577 2.00421C29.0417 2.00421 33.6297 3.90464 37.0125 7.28741C40.3952 10.6702 42.2957 15.2582 42.2957 20.0422Z" fill="#333333"/>
    <path d="M24.2578 26.0549C27.5785 26.0549 30.2704 23.3629 30.2704 20.0422C30.2704 16.7215 27.5785 14.0295 24.2578 14.0295C20.9371 14.0295 18.2451 16.7215 18.2451 20.0422C18.2451 23.3629 20.9371 26.0549 24.2578 26.0549Z" stroke="#BDBDBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `;
        return div.firstChild as HTMLElement;
    }

    private getUserPinElement(): HTMLElement {
        const div = document.createElement('div');
        div.innerHTML = `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="40" height="50" viewBox="0 0 40 50"><polygon points="20 2.42 5.82 7.69 2.11 22.5 8.54 35.04 20 45.77 31.49 34.76 37.7 21.02 34.27 8.21 20 2.42" style="fill:#fff"/><path d="M20,22.5c3.33,0,10,1.67,10,5h0v.4a13.17,13.17,0,0,1-20,0h0v-.4c0-3.33,6.67-5,10-5ZM20,20a5,5,0,1,1,5-5A5,5,0,0,1,20,20Zm15,.5C35,11.42,28.37,5,20,5S5,11.43,5,20.5c0,5.85,4.87,13.6,15,22.85C30.13,34.1,35,26.35,35,20.5ZM20,0C30.5,0,40,8.05,40,20.5Q40,33,20,50,0,32.93,0,20.5C0,8.05,9.5,0,20,0Z" style="fill:#0167cd"/></svg>
        `;
        const svgElement = div.firstChild as HTMLElement;
        svgElement.style.zIndex = '1';
        return div.firstChild as HTMLElement;
    }
}
