<mat-card
    class="d-flex flex-column"
>
    <div class="my-2">
        <mat-card-title
            class="mb-0 d-flex align-items-end"
            [routerLink]="['/transfer']"
            [queryParams]="{
            id: transfer.id
        }">
            <span class="name">
                {{ transfer.name }}
            </span>

            <div class="badge-container">
                <div
                    class="transfer-status my-1"
                    [ngClass]="{ 'is-active': transfer.isAvailable && !!transfer.is_active }"
                >
                    <span>
                        {{
                            transfer.isAvailable && !!transfer.is_active
                                ? ('available' | translate)
                                : ('unavailable' | translate)
                        }}
                    </span>
                </div>
                <div
                    *ngIf="userQuery.user?.id === transfer.user.id"
                    class="transfer-status own"
                >
                    <span>{{ 'own' | translate }}</span>
                </div>
            </div>
        </mat-card-title>
    </div>

    <mat-card-content
        class="flex-grow-1 d-flex flex-wrap align-items-start align-content-start"
        [routerLink]="['/transfer']"
        [queryParams]="{
            id: transfer.id
        }"
    >
        <div class="w-100 d-flex align-items-center mb-2 me-3">
            <div class="text-secondary address">
                {{ transfer.country.code | countryTranslate | async }}
                {{ transfer.city }}
                {{ transfer.street }}
            </div>
        </div>

        <div class="w-100 d-flex align-items-center mb-2 me-3">
            <mat-icon
                class="me-1"
                [ngClass]="!!transfer.allow_pets ? 'icon-allow' : 'icon-deny'"
            >
                pets
            </mat-icon>
            <span [ngClass]="{ 'not-allowed': !transfer.allow_pets }">
                {{ 'is-allowed-animals' | translate }}
            </span>
        </div>

        <div class="w-100 d-flex align-items-center mb-2 me-3">
            <mat-icon
                class="me-1"
                [ngClass]="!!transfer.allow_babies ? 'icon-allow' : 'icon-deny'"
            >
                child_friendly
            </mat-icon>
            <span [ngClass]="{ 'not-allowed': !transfer.allow_babies }">
                {{ 'is-allowed-babies' | translate }}
            </span>
        </div>

        <div class="w-100 d-flex align-items-center mb-2 me-3">
            <mat-icon
                class="me-1"
                [ngClass]="
                    !!transfer.can_call_at_night ? 'icon-allow' : 'icon-deny'
                "
            >
                mode_night
            </mat-icon>

            <span [ngClass]="{ 'not-allowed': !transfer.can_call_at_night }">
                {{ 'can-call-at-night' | translate }}
            </span>
        </div>

        <div class="w-100 d-flex align-items-center mb-2 me-3">
            <mat-icon
                class="me-1"
                [ngClass]="
                    !!transfer.is_accessible ? 'icon-allow' : 'icon-deny'
                "
            >
                accessible
            </mat-icon>

            <span [ngClass]="{ 'not-allowed': !transfer.is_accessible }">
                {{ 'is-accessible' | translate }}
            </span>
        </div>

        <div class="w-100 d-flex align-items-center mb-2 me-3">
            <mat-icon class="me-1">directions_car</mat-icon>
            <span>
                {{ 'transfer-types.' + transfer.transfer_vehicle_type | translate }}
            </span>
        </div>

        <div class="w-100 d-flex align-items-center mb-2 me-3">
            <mat-icon class="me-1">family_restroom</mat-icon>
            <span>
                {{ transfer.nr_of_seats }}
                {{ 'person' | translate }}
            </span>
        </div>

        <div class="d-flex align-items-center mb-2 me-3">
            <mat-icon class="me-1">translate</mat-icon>
            <span style="word-break: break-all">
                {{ transfer.user.languages | languageTranslate | async }}
            </span>
        </div>

        <div
            class="w-100 d-flex justify-content-around align-items-center mb-2 me-3"
        >
            <div class="w-100 d-flex align-items-center">
                <mat-icon class="me-1">route</mat-icon>
                <span> {{transfer.city}} + {{ transfer.range }} km </span>
            </div>
            <div
                class="d-flex align-items-center"
                style="flex: 1 1 auto; white-space: nowrap"
                *ngIf="transfer.distance || transfer.distance === 0"
            >
                <mat-icon class="me-1">near_me</mat-icon>
                <span>{{ transfer.distance }} km</span>
            </div>
        </div>
        <div class="w-100">
            <app-weekday-and-hour-display
                [availability]="transfer.availability"
            ></app-weekday-and-hour-display>
        </div>
    </mat-card-content>

    <hr style="margin-bottom: 10px" />

    <mat-card-actions
        *ngIf="!customFooterTpl; else customFooterTpl"
        class="d-flex justify-content-between align-items-center pt-0"
        style="cursor: default"
    >
        <mat-icon
            class="star-icon"
            style="cursor: pointer"
            (click)="favouriteClick.emit()"
            [color]="(coreQuery.appTheme$ | async) === AppTheme.light ? 'accent' : 'primary'"
            >{{ transfer.favourite ? 'star' : 'star_border' }}
        </mat-icon>

        <div class="fw-bold mt-1">
            <span class="text-uppercase"> {{ 'driver' | translate }}: </span>
            <img
                [src]="transfer.user.first_name | userAvatarImage"
                class="user-avatar-image mx-2"
            />
            <span class="text-secondary">{{ transfer.user.first_name }}</span>
        </div>
    </mat-card-actions>
</mat-card>
