import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

export const expandRevealAnimation: AnimationTriggerMetadata = trigger(
    'expandRevealAnimation',
    [
        state('true', style( { height: '80vh', 'min-height': '0', overflow: 'auto'})),
        transition(
            ':enter',
            [
                style({ height: 0, width: 0, 'overflow-y': 'hidden' }),
                animate('225ms ease-in-out',
                    style({ height: '*', width: '*', 'overflow-y': 'hidden' }))
            ]
        ),
        transition(
            ':leave',
            [
                style({ height: '*', width: '*',  overflow: 'hidden' }),
                animate('225ms ease-in-out',
                    style({ height: 0, width: 0, overflow: 'hidden' }))
            ]
        )
    ]
);
